import React from 'react';
import {EMI_STARTING, PERCENT_OFF} from '../../../constants/app/footerConstants';
import { NO_COST_STARTING } from '../../../constants/app/pdpConstants';

class Promotions extends React.Component {
  render() {
    let emiItem = null;
    if (this.props.emi !== '') {
      const boldStr = <span className="bold"> ₹{parseInt(this.props.offerPrice/6)}</span>
      emiItem = (
        <span className="free-accessories">{NO_COST_STARTING} {boldStr}</span>
      );
    }

    let discountAccessoriesItem = null;
    if (this.props.discount !== '') {
      const boldStr = <span className="bold">{this.props.discount + PERCENT_OFF}</span>
      discountAccessoriesItem = (
        // <span className="free-accessories">{parseInt(this.props.discount) < 2 ? null : boldStr} {this.props.promoData !== '' || this.props.discount !== '' ? '&' : ''} {this.props.promoData !== '' ? this.props.promoData : null}</span>
        <span>{parseInt(this.props.discount) < 2 ? null : boldStr} {this.props.promoData !== '' && this.props.discount !== '' && parseInt(this.props.discount) > 2 ? '& ' : ''}</span>
      );
    }

    let promoItem = null;
    if (this.props.promoData !== '') {
      promoItem = (
        <span>{this.props.promoData}</span>
      );
    }
    
    return (
      <p className="emi-text text">
        {emiItem}
        {/* {discountAccessoriesItem} 
        {promoItem} */}
      </p>
    );
  }
}

export default Promotions;
