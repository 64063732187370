import React from "react";
import Header from "../GodrejAVSolutionsComp/Header";
import SliderNew from "../GodrejAVSolutionsComp/SliderNew";
import InfoCardsPanel from "../GodrejAVSolutionsComp/InfoCardsPanel";
import Form from "../GodrejAVSolutionsComp/Form";
import Footer from "../GodrejAVSolutionsComp/Footer";

class GodrejAVSolution extends React.Component{
// export default function () {
  render(){
    return (
      <div>
        <div className="godrej-av-solutions-page">
          <Header />
          <SliderNew />
          <InfoCardsPanel />
          <Form />
          <Footer />
        </div>
      </div>
    );
  }
}
export default GodrejAVSolution;
