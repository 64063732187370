import React from 'react';
import {imagePrefix,addToCart} from '../../../public/constants/constants';
import appCookie from '../../utils/cookie';

class kreationX3 extends React.Component {

  componentDidMount = () => {
   // alert(imagePrefix);
    const token = appCookie.get('accessToken');
    localStorage.setItem('accessToken' , token);
    localStorage.setItem('addToCartAPI' , addToCart);
  }
//   componentDidUpdate= () =>{
//    if(localStorage.getItem('addToCartFromProductConfigurator')=="Yes"){
//       alert("addToCartFromProductConfigurator in product configurator = "+localStorage.getItem('addToCartFromProductConfigurator'));
//       // localStorage.setItem('addToCartFromProductConfigurator' , "No");
//       // window.location.assign("/cart");
//    }
//   }

   render(){
      return (
         <iframe title="unity-frame"
         src={imagePrefix + "/B2C/productconfigurator/KreationX3/index.html"}
         // src="https://localhost:5002/imagestore/B2Ctemp/KreationX3/index.html" 
         style={{height: "100vh", width: "100%"}} />
      );
   }
}
export default kreationX3;
