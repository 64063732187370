import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/lib/Button'
import Row from 'react-bootstrap/lib/Row'
import Col from 'react-bootstrap/lib/Col'
import Modal from 'react-bootstrap/lib/Modal'
import WebCam from 'react-webcam';

import CameraSVG from '../SVGs/camera';
import { isMobile, isTab, createSEOPdpURL } from '../../utils/utilityManager';
import { fileToBase64 } from '../../utils/files';
import apiManager from '../../utils/apiManager';
import {
  imageSearchAPI,
  imageSearchLocalStorageKey,
  imagePrefix,
  imgSrchRsltLocalStorageKey,
} from '../../../public/constants/constants';
import storage from '../../utils/localStorage';
import '../../../public/styles/imageSearch/imageSearch.scss';
// import '../../../public/styles/vendor/_toaster.min.scss';

const userAgent = window.navigator.userAgent.toLowerCase();
const isIPad = /ipad/.test(userAgent);
const webcamDefaultImageFormat = 'image/jpeg';
const videoConstraints = {
  facingMode: { exact: (isMobile() || isTab() || isIPad) ? 'environment' : 'user' },
};

class ImageSearch extends Component {
  state = {
    showModal: false,
    fileSelected: false,
    searchString: null,
    redirectToSearch: false,
    errorMsg: null,
    searchingImage: false,
    showCamera: false,
  };

  constructor() {
    super();
    this.filePickerRef = createRef();
    this.cameraRef = createRef();
  }

  triggerISAction = event => {
    event.preventDefault();
    if (isMobile()) {
      this.setState({
        showModal: true,
      });
      return;
    }
    this.triggerFilePicker();
    
  };

  triggerFilePicker = () => {
    this.filePickerRef.current.click();
  };

  resetFilePicker = () => {
    this.filePickerRef.current.value = "";
  };

  fileChangedHandler = event => {
    const targetFile = event.target.files[0];
    if (!targetFile) return;
    const extension = targetFile.name.match(/\.([^\.]+)$/)[1];
    if (extension === 'png'
      || extension === 'jpg'
      || extension === 'jpeg'
      || extension === 'webp') {
      this.setState({
        fileSelected: true,
        errorMsg: null,
      });
    } else {
      this.setState({
        errorMsg: 'Unsupported file format',
      });
    }
  };

  callImageSearchApi = (base64String, fileType) => {
    const prefixRemovalString = `data:${fileType};base64,`;
    const chamferedBase64String = base64String.replace(prefixRemovalString, '');
    apiManager
      .post(imageSearchAPI, {
        imageBase64String: chamferedBase64String,
      })
      .then(res => {
        if (res && res.data && res.data.data) {
          storage.set(imageSearchLocalStorageKey, base64String);
          const { redirectToPdp, productDetails, productsList } = res.data.data;
          this.setState({
            errorMsg: null,
            fileSelected: false,
            searchingImage: false,
          }, () => {
            if (redirectToPdp) {
              this.props.history.push(createSEOPdpURL(
                productDetails.productName,
                productDetails.shortDescription,
                productDetails.partNumber
              ));
            } else if (productsList && productsList.length > 0) {
              storage.set(imgSrchRsltLocalStorageKey, productsList);
              this.props.history.push(`/imagesearch`);
            } else {
              this.setState({
                errorMsg: 'Sorry, couldn\'t find any match',
                fileSelected: false,
                searchingImage: false,
              });
            }
          });
        } else {
          this.setState({
            errorMsg: 'Sorry, couldn\'t find any match',
            fileSelected: false,
            searchingImage: false,
          });
        }
      })
      .catch(error => {
        this.setState({
          errorMsg: 'Sorry, error occurred while analyzing the image',
          fileSelected: false,
          searchingImage: false,
        });
      });
    this.resetFilePicker();
  };

  searchImage = () => {
    const imageFile = this.filePickerRef && this.filePickerRef.current.files[0];
    this.setState({ searchingImage: true }, () => {
      fileToBase64(imageFile)
        .then(base64String => {
          this.callImageSearchApi(base64String, imageFile.type);
        })
        .catch(error => {
          this.setState({
            errorMsg: 'Sorry, encountered problem while parsing file',
            fileSelected: false,
            searchingImage: false,
          });
        });
    });
  };

  modalToggleHandler = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
      fileSelected: false,
      showCamera: false,
    }));
  };

  toggleCamera = () => {
    this.setState(prevState => ({ showCamera: !prevState.showCamera }));
  };

  captureSnap = () => {
    const imageSnapBase64 = this.cameraRef.current.getScreenshot();
    this.setState({
      fileSelected: true,
      searchingImage: true,
      showCamera: false,
    }, () => this.callImageSearchApi(imageSnapBase64, webcamDefaultImageFormat));
  };

  componentDidUpdate() {
    const { fileSelected, redirectToSearch, searchString, searchingImage, errorMsg } = this.state;
    if (fileSelected && !searchingImage) {
      this.searchImage();
    }
    if (errorMsg) {
      // showErrorToast(errorMsg);
      alert(errorMsg)
      this.setState({ errorMsg: null });
    }
  }

  render() {
    const { showModal, searchingImage, showCamera } = this.state;
    // return null;
    return (
      <>
        <button className="image-search-trigger" onClick={this.triggerISAction}>
          <CameraSVG />
        </button>
        <input
          type="file"
          name="visualSrchImg"
          id="visualSrchImg"
          accept=".png,.jpg,.jpeg,.webp"
          ref={this.filePickerRef}
          onChange={this.fileChangedHandler}
        />
        {searchingImage && (
          <div className="lazyloading-Indicator image-search-lazy">
            <img
              id="me"
              className="loadingImg"
              alt='Lazy Loader'
              src={`${imagePrefix}/B2C/static/plpAssests/lazyloadingIndicator.svg`}
            />
          </div>
        )}
        {(showModal || showCamera) && (
          <Modal
            className="modal-image-search"
            show={showModal || showCamera}
            onHide={this.modalToggleHandler}
          >
            <Modal.Body>
              {showCamera && (
                <Button className="back" onClick={this.toggleCamera}>
                  <img className="logoImg" src={`${imagePrefix}/B2C/static/LeftArrow.svg`} />
                </Button>
              )}
              <Button className="close" onClick={this.modalToggleHandler} />
              {(showModal && !showCamera) && (
                <>
                  <Row className="content-block">
                    <Col xs={12}>
                      <div>
                        <h4 className="heading">Image Search</h4>
                      </div>
                    </Col>
                    <Col xs={12} className="content-body">
                      <Col xs={12}>
                        <Button
                          className="btn-block btn-bg"
                          onClick={this.toggleCamera}
                        >
                          Take A Snap
                        </Button>
                      </Col>
                      <Col xs={12}>
                        <Button
                          className="btn-block btn-bg"
                          onClick={this.triggerFilePicker}
                        >
                          Pick From Gallery
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                  {searchingImage && (
                    <div className="lazyloading-Indicator">
                      <img
                        id="me"
                        className="loadingImg"
                        alt='Lazy Loader'
                        src={`${imagePrefix}/B2C/static/plpAssests/lazyloadingIndicator.svg`}
                      />
                    </div>
                  )}
                </>
              )}
              {showCamera && (
                <div className="camera-container">
                  <WebCam
                    audio={false}
                    screenshotFormat={webcamDefaultImageFormat}
                    videoConstraints={videoConstraints}
                    ref={this.cameraRef}
                  />
                  <Button className="camera-capture-btn btn-bg" onClick={this.captureSnap} />
                </div>
              )}
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}

export default withRouter(ImageSearch);
