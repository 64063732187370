/*
 *
 * HeaderContainer constants
 *
 */

export const DEFAULT_ACTION = 'app/HeaderContainer/DEFAULT_ACTION';

export const MINICART_DATA = 'app/HeaderContainer/MINICART_DATA';

export const IS_OTP_ENABLED = 'app/HeaderContainer/IS_OTP_ENABLED';

export const COUPON_DATA = 'app/HeaderContainer/COUPON_DATA';
