import React  from "react";


import Button from 'react-bootstrap/lib/Button'
import Col from 'react-bootstrap/lib/Col'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import Label from 'react-bootstrap/lib/Label'
import FormControl from 'react-bootstrap/lib/FormControl'
import Modal from 'react-bootstrap/lib/Modal'


import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import closeIcon from "../SVGs/outlined_close.svg";
import {
  espotAPI,
  PinToCityAPI,
  dealerLeadsAPI,
  imagePrefix,
} from '../../../public/constants/constants';
import {
  validateEmptyObject,
  validateFullName,
  regexName,
  regexMobileNo,
  regexEmail,
} from '../../utils/validationManager';
import {
  FIELDREQ_MSG,
  NAME_VALIDATION_MSG,
  INVALID_MOBILE_NUMBER,
  INVALID_EMAIL_ADDRESS,
  INVALID_PINCODE_FORMAT,
  NOT_VALID_PINCODE,
  ATLEAST_ONE_REQUIREMENT,
} from '../../constants/app/primitivesConstants';
import apiManager from '../../utils/apiManager';
import '../../../public/styles/staticPages/dealer-modal.scss';
import remove from "lodash/remove";
const animatedComponents = makeAnimated();
const PINCODE_REGEX = /^\d{6}$/;
const Option = props => (
  <components.Option {...props}>
    <input type="checkbox" checked={props.isSelected} />
    <span />
    <label>{props.label}</label>
  </components.Option>
);



class DealerModal extends React.Component{

  constructor(){
    super();
    this.state = {
      show:true,
      name: null,
      email1: null,
      phone1: null,
      city: null,
      state: null,
      pincode: null,
      requirementsOptions: [],
      requirements: [],
      nameErrMsg: null,
      emailErrMsg: null,
      phoneErrMsg: null,
      pincodeErrMsg: null,
      successMsg: null,
      errorMsg: null,
      requirementsErrMsg: null,
      businessType: false,
      submitDisabled:false,
      businessDeal:'No',
      dealerTitle:[],
      dealerData:[],
    }
  }

    
  toggleWhatsapp = e =>{
    this.setState({businessType : e.target.checked});
    // if({contactWhatsapp }){
    //   contactMe = 'Yes';
    // }
    
  }

  fetchRequirementsOptions = () => {
    apiManager
      .get(`${espotAPI}GI_Customer_Dealer_Rqrmnts`)
      .then(response => {
        const { data } = response;
        if (data && data.data) {
          this.setState({
            requirementsOptions: data.data.requirements || [],
          });
        }
      })
      .catch(error => {});
  };

  fetchDealerData = () => {
    apiManager
      .get(`${espotAPI}GI_DEALER_FORM`)
      .then(response => {
        const { data } = response;
        if (data && data.data) {
          this.setState({
            dealerTitle: data.data.titleList || [],
            dealerData: data.data.dataList || [],
          });
        }
      })
      .catch(error => {});
  };

  componentDidMount() {
    this.fetchRequirementsOptions();
    this.fetchDealerData();
    this.setState({
      show:false,
    })
  }

  fieldChangeHandler = e => {
    const { pincode } = this.state;
    if (
      e.target.name === 'pincode' &&
      e.target.value &&
      PINCODE_REGEX.test(e.target.value) &&
      pincode !== e.target.value
    ) {
      this.fetchCityFromPin(e.target.value);
    }
    this.setState({
      [e.target.name]: e.target.value !== '' ? e.target.value : null,
    });
  };

  
  fetchCityFromPin = pincode => {
    if (!pincode) return;
    apiManager
      .get(PinToCityAPI + pincode)
      .then(response => {
        const { data } = response;
        if (data.data && data.data.city) {
          this.setState({
            pincodeErrMsg: null,
            city: data.data.city,
            state: data.data.state,
          });
        } else {
          this.setState({ pincodeErrMsg: NOT_VALID_PINCODE });
        }
      })
      .catch(error => {
        this.setState({ pincodeErrMsg: NOT_VALID_PINCODE });
      });
  };

    keyPressHandler = e => {
      if (e.key === 'Enter') 
        this.formSubmitHandler();
    };

  requirementsChangeHandler = (value, actionMeta) => {
    const { requirements } = this.state;
    const newRequirements = requirements.slice();
    if (actionMeta.action === 'select-option') {
      newRequirements.push(actionMeta.option);
    } else if (
      actionMeta.action === 'remove-value' ||
      actionMeta.action === 'pop-value'
    ) {
      remove(
        newRequirements,
        requirement => requirement.value === actionMeta.removedValue.value,
      );
    } else if (actionMeta.action === 'deselect-option') {
      remove(
        newRequirements,
        requirement => requirement.value === actionMeta.option.value,
      );
    } else if (actionMeta.action === 'clear') {
      remove(newRequirements, () => true);
    }
    this.setState({
      requirements: newRequirements,
    });
  }


  formValidationHandler = () => {
    this.setState({
      nameErrMsg: null,
      emailErrMsg: null,
      phoneErrMsg: null,
      pincodeErrMsg: null,
	  requirementsErrMsg: null,
      successMsg: null,
      errorMsg: null,
    });
    let isValid = true;
    const { name, email1, phone1, pincode, city, requirements } = this.state;
    if (!validateEmptyObject(name)) {
      this.setState({ nameErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!validateFullName(name) || !regexName.test(name)) {
      this.setState({ nameErrMsg: NAME_VALIDATION_MSG });
      isValid = false;
    }
    if (!validateEmptyObject(email1)) {
      this.setState({ emailErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!regexEmail.test(email1)) {
      this.setState({ emailErrMsg: INVALID_EMAIL_ADDRESS });
      isValid = false;
    }
    if (!validateEmptyObject(phone1)) {
      this.setState({ phoneErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!regexMobileNo.test(phone1)) {
      this.setState({ phoneErrMsg: INVALID_MOBILE_NUMBER });
      isValid = false;
    }
    if (!validateEmptyObject(pincode)) {
      this.setState({ pincodeErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!PINCODE_REGEX.test(pincode) || !validateEmptyObject(city)) {
      this.setState({ pincodeErrMsg: INVALID_PINCODE_FORMAT });
      isValid = false;
    }
    if (!requirements || requirements.length === 0) {
      this.setState({
        requirementsErrMsg: ATLEAST_ONE_REQUIREMENT,
      });
      isValid = false;
    }
    return isValid;
  };

  closeModel() {
    this.props.handleModelClose();
    this.setState({
      show:false,
    })
  }
    
    
    formSubmitHandler = () => {
      if (!this.formValidationHandler()) return;
    
      this.setState({
        submitDisabled: true,
      });
      setTimeout(() => {
        this.setState({
          submitDisabled:false,
        })
      }, 5000);
      const {
        name,
        phone1,
        email1,
        pincode,
        city,
        state,
        requirements,
        businessType,
        pageName,
      } = this.state;
      let requirementsStr = requirements.map(rqrmnt => rqrmnt.value).join('||');
      requirementsStr = requirementsStr.replace("&", "and");
      const urlSearchParams = new URLSearchParams(
        window.location.search.substring(1),
      );
      const data = {
        name,
        phone1,
        email1,
        pincode,
        city,
        state,
        businessType,
        requirements: requirementsStr,
        pageName: this.props.pageName,
        utmPlacement: urlSearchParams.get('utm_placement'),
        utmMedia: urlSearchParams.get('utm_medium'),
        utmKeyword: urlSearchParams.get('utm_keyword'),
        utmAddgroup: urlSearchParams.get('utm_adgroup'),
        utmCampaign: urlSearchParams.get('utm_campaign'),
        utmSource: urlSearchParams.get('utm_source'),
      };
      apiManager
        .post(dealerLeadsAPI, data)
        .then(response => {
          const resData = response.data.data;
          if (resData.customerQuerySaved === true) {
            this.setState({
              name: '',
              phone1: '',
              email1: '',
              pincode: '',
              city: '',
              state: '',
              requirements: [],
              successMsg:
                  'Thank You! Your nearest store representative will reach out to you shortly.',
            });
          } else {
            this.setState({
              errorMsg: 'Unable to save your data. Sorry for inconvenience.',
            });
          }
        })
        .catch(() => {
          this.setState({
            errorMsg: 'Unable to save your data. Sorry for inconvenience.',
          });
        });
    };

      toggleHandler = () => {
        this.setState({ successMsg: null });
      };

      render(){
        // console.log("hello"+this.props.show)
        const {
          name,
          email1,
          phone1,
          city,
          pincode,
          requirements,
          nameErrMsg,
          emailErrMsg,
          phoneErrMsg,
          pincodeErrMsg,
          requirementsErrMsg,
          successMsg,
          errorMsg,
          requirementsOptions,
          businessType,
          dealerTitle,
          dealerData,
        } = this.state;
        return(
          <>
            <Modal 
              show={this.props.show}
              aria-labelledby="contained-modal-title-vcenter"
              animation={false}
              onHide={this.closeModel.bind(this)}
              backdrop={false}
              className="modal-login-register get-quote-popup pr-0 "
              dialogClassName="modal-image-gallery">
              <div className="formContainer-dealer">
                <button type="button" className="btn-close" onClick={ this.closeModel.bind(this)}>
                  <img src={closeIcon} alt="close" />
                </button>
                <div className="formContainer">
                  <div className="left-content">
                    <div className="title-container"> 
                      {!!dealerTitle && dealerTitle.map((data,index) => (
                        <>
                          { data.type == 'title1' && (
                            <>
                              <h4 className="title1">{data.title1}</h4>
                            </>
                          )
                          }
                          { data.type == 'title2' && (
                            <>
                              <h2 className="title2">{data.title1}</h2>
                            </>
                          )
                          }
                          { data.type == 'subtitle1' && (
                            <>
                              <p className="subtitle1">{data.title1}</p>
                            </>
                          )
                          } 
                          { data.type == 'subtitle2' && (
                            <>
                              <p className="subtitle2">{data.title1}</p>
                            </>
                          )
                          }
                        </>
                      )) 
                            
                              
                      }
                    </div>
                    <div className="data-container">
                      { !!dealerData && dealerData.map((data,index) =>
                        <>
                          <div className="left-content-data">
                            { data.type == 'image' && (<> <img className="src-image" src={`${imagePrefix}${data.imageSrc}`}></img>
                              <p className="image-title">{data.title}</p> </>)}
                          </div>
                          <div className="right-content-data">
                            { data.type == 'data' && (<h4>{data.title}</h4>)}
                          </div>
                        </>
                      )  }
                    </div>
                  </div>
                  <div className="right-content">
                    <Col xs={12}>
                      <FormGroup>
                        <Label>Name *</Label>
                        <FormControl
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Enter Full Name"
                          value={name}
                          onChange={this.fieldChangeHandler}
                          onKeyPress={this.fieldChangeHandler}
                        />
                        <p className="error-msg">{nameErrMsg}</p>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label>Email *</Label>
                        <FormControl
                          type="text"
                          name="email1"
                          className="form-control"
                          placeholder="Enter Email"
                          value={email1}
                          onChange={this.fieldChangeHandler}
                          onKeyPress={this.fieldChangeHandler}
                        />
                        <p className="error-msg">{emailErrMsg}</p>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label>Mobile *</Label>
                        <FormControl
                          type="text"
                          name="phone1"
                          className="form-control"
                          placeholder="Enter Mobile Number"
                          value={phone1}
                          onChange={this.fieldChangeHandler}
                          onKeyPress={this.fieldChangeHandler}
                        />
                        <p className="error-msg">{phoneErrMsg}</p>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label>Pincode *</Label>
                        <FormControl
                          type="text"
                          name="pincode"
                          className="form-control"
                          placeholder="Enter Pincode"
                          value={pincode}
                          onChange={this.fieldChangeHandler}
                          onKeyPress={this.fieldChangeHandler}
                        />
                        <p className="error-msg">{pincodeErrMsg}</p>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label>City *</Label>
                        <FormControl
                          type="text"
                          disabled
                          name="city"
                          className="form-control"
                          placeholder="City"
                          value={city}
                        />
                        <p className="error-msg" />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup className="requirements-select">
                        <Label>Business Type *</Label>
                        <Select
                          components={Object.assign({}, animatedComponents, {
                            Option,
                          })}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isMulti
                          value={requirements}
                          options={requirementsOptions}
                          placeholder="Select"
                          onChange={this.requirementsChangeHandler}
                          classNamePrefix="leads-rqrmnts"
                        />
                        <p className="error-msg">{requirementsErrMsg}</p>
                      </FormGroup>
                    </Col>
                    {/* <Col xs={12} class="enquiry_campaign_whatsapp">
                                <FormGroup>
                                <label class="wa-checks"><input type="checkbox" checked={contactWhatsapp} onChange={this.toggleWhatsapp}/>
                                You may contact me via Email, Whatsapp, SMS.</label>
                                </FormGroup>
                            </Col> */}
                    <Col xs={12} class="enquiry_campaign_whatsapp">
                      <FormGroup>
                        <label className="wa-checks"><input type="checkbox" checked={businessType} onChange={this.toggleWhatsapp}/>
                                I would like to deal in Business Products as well.</label>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        {/* <h6 className="message">
                                {errorMsg && <span className="failed">{errorMsg}</span>}
                                </h6> */}
                        <Button
                          className="submit-btn btn-block btn-bg"
                          onClick={this.formSubmitHandler}
                          disabled={this.state.submitDisabled}
                            id = 'bod-b2c'
                        >
                                SUBMIT
                        </Button>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <h6 className="disclaimer">
                                By submitting this form, you agree to the{' '}
                        <Link to="/privacy-policy">privacy policy</Link> &{' '}
                        <Link to="/termsconditions">terms and conditions</Link>
                      </h6>
                    </Col>
                    {successMsg ? window.location.pathname='/thank-you' : null}
                  </div>
                </div>
              </div>
            
            </Modal>
          </>
        )
      }

}




export default DealerModal;
