import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import CrossIcon from '../../SVGs/crossIcons.svg';
import '../../../../public/styles/myAccount/manageAddress/gstinModal.scss';

class GSTINModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      modalClass: 'delete-modal',
      selectedName: null,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  onCancelModal() {
    this.props.gstinModalReturnHandler();
  }

  onSaveModal() {
    let selectedName;
    if (document.getElementById('legal-name2').checked) {
      selectedName = this.props.GSTINDataPro.legalName;
    } else {
      selectedName = this.props.GSTINDataPro.tradename;
    }
    this.props.gstinModalReturnHandler(selectedName);
  }

  render() {
    return (
      <Modal
        show={this.props.showGSTINModal}
        animation
        backdrop={false}
        style={{ background: 'rgba(0, 0, 0, 0.5)', zIndex: '2147483648' }}
        bsSize="sm"
        aria-labelledby="contained-modal-title-vcenter"
        className="gstin-confirmation-modal">
        <div className="content">
          <div className="content-heading">
            <div className="d-flex align-items-center">
              <button onClick={this.onCancelModal.bind(this)} type="button" role="presentation">
                <img className="leftArrow" src={require('../../SVGs/carousel__arrowLeft.svg')}  alt="Left"/>
              </button>
              <h3 className="delete-title">Whom the invoice needs to be billed</h3>
            </div>
            <button onClick={this.onCancelModal.bind(this)} className="cancelButton">
              <img src={CrossIcon} alt="crossImg" />
            </button>
          </div>
          <form>
            <div className="row no-gutters-lg">
              <div className="col-md-6">
                <div className="form-group  mr-lg-3">
                  <label htmlFor="legal-name">Legal Name</label>
                  <input
                    type="text"
                    className="form-control"
                    minLength="5"
                    placeholder="Legal Name"
                    value={this.props.GSTINDataPro.legalName}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="trade-name">Trade Name</label>
                  <input
                    type="text"
                    className="form-control"
                    minLength="5"
                    placeholder="Trade Name"
                    value={this.props.GSTINDataPro.tradename}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="subtitle">Please choose a name that you wish to have on your invoice</div>
                <div className="form-group form-check mt-3 mb-2">
                  <label className="form-check-label radio-label" htmlFor="legal-name2">
                    <input type="radio" className="form-check-input" id="legal-name2" name="billingName2" value="Legal Name" checked />
                    <span className="role-checkbox-label p-0">Legal Name</span>
                  </label>
                </div>
                <div className="form-group form-check">
                  <label className="form-check-label radio-label" htmlFor="trade-name2">
                    <input type="radio" className="form-check-input" id="trade-name2" name="billingName2" value="Trade Name" />
                    <span className="role-checkbox-label p-0">Trade Name</span>
                  </label>
                </div>
                <p className="text-sub-note">
                  Note: The same name will be printed on all the invoices for the orders placed in future against this GST.
                </p>
              </div>
            </div>
          </form>
          <div className="mt-2 d-flex">
            <button type="reset" className="btn-cancel" onClick={this.onCancelModal.bind(this)}>
              Cancel
            </button>
            <button type="submit" className="btn-blackbg" onClick={this.onSaveModal.bind(this)}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default GSTINModal;
