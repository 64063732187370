import React from 'react';
const favicon = require('../../../public/images/favicon.png');

class VideoModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: false,
      videoUrl: '',
    };

    this.showModal = this.showModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.onVideoClose = this.onVideoClose.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.state.showVideo) {
      this.onVideoClose();
    }
  }

  showModal(videoUrl) {
    this.setState({
      showVideo: true,
      videoUrl,
    });
  }

  onVideoClose() {
    this.setState({
      showVideo: false,
      videoUrl: undefined,
    });
  }

  handleClose() {
    this.setState({ showVideo: false });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    return (
      <>
        {this.state.showVideo ?<>
        
          <div id="myModal" className="modal-vid">
            
            
            <div className='modal-content-vid'>
              <div className="modal-header-vid">
                <button type="button" className="close-vid" data-dismiss="modal" aria-label="Close" onClick={this.onVideoClose}>
                  <span aria-hidden="true">x </span>
                </button>
              </div>
              {/* <span class="close">&times;</span> */}
              <div className="center-align-video">
                <iframe
                  className="embed-responsive-item"
                  src={`${this.state.videoUrl  }?rel=0&enablejsapi=1&modestbranding=1`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                />
              </div>
            </div>

          </div></> :null}   
        {/* {this.state.showVideo && (
          <div
            className={this.state.showVideo ? 'modal p-0 fade modal-video show' : 'modal p-0 modal-video fade'}
            id="videoPopup"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="videoPopupTitle"
            style={
              this.state.showVideo
                ? { display: 'block', paddingRight: '17px', background: 'rgba(0, 0, 0, 0.7)', zIndex: '2147483648' }
                : { display: 'none' }
            }
            ref={this.setWrapperRef}>
            
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onVideoClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body p-0">
                  <div className="embed-responsive embed-responsive-16by9">
                    {this.state.showVideo && (
                      <iframe
                        className="embed-responsive-item"
                        src={this.state.videoUrl + '?enablejsapi=1&modestbranding=1'}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen"
                        msallowfullscreen="msallowfullscreen"
                        oallowfullscreen="oallowfullscreen"
                        webkitallowfullscreen="webkitallowfullscreen"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div> 
          </div>
        )} */}
      </>
    );
  }
}
export default VideoModel;
