import React from 'react';
import connect from 'react-redux/src/connect/connect'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip  from 'react-bootstrap/lib/Tooltip';
import Button  from 'react-bootstrap/lib/Button';
import apiManager from '../../../utils/apiManager';
import { updatetWishListCount, resetRemoveFromWishlistFlag } from '../../../actions/app/actions';
import {
  getCookie,
  getCorrespondingGiftlistId,
  getOnlyWishlistUniqueIds,
} from '../../../utils/utilityManager';
import { getUpdatedWishlist } from '../../../utils/initialManager';
import {
  addToWishlist,
  removeFromWishlist,
  wishlistIdCookie,
} from '../../../../public/constants/constants';
import UserAccInfo from '../../UserAccInfo/userAccInfo';
import appCookie from '../../../utils/cookie';
import { isMobile } from '../../../utils/utilityManager';
import { WISHLIST } from '../../../constants/app/pdpConstants';
import { imagePrefix } from '../../../../public/constants/constants';

class Wishlist extends React.Component {
  constructor(props) {
    super(props);
    this.wishlistAddedImg = (
      <img
        src={imagePrefix + '/B2C/static/BLACK%20WHISLIST%20ICON.svg'}
        alt="Added"
        // onClick={this.onWishlistClick.bind(this)}
      />
    );
    this.wishListRemovedImg = (
      <img
        src={imagePrefix + '/B2C/static/GREY%20WHISLIST%20ICON.svg'}
        alt="Add to Wishlist"
        // onClick={this.onWishlistClick.bind(this)}
      />
    );
    this.state = {
      wishlistCurrentImage: this.wishListRemovedImg,
      wishlistPopup: null,
      isWelcomeBack: false,
    };
  }

  onWishlistClick(e) {
    if(e.target.nodeName==='SPAN' || e.target.nodeName==='DIV'){
      return;
    }
    const wishlistArr = getOnlyWishlistUniqueIds();
    if (getCookie('isLoggedIn') === 'true') {
      if (e.target && e.target.name === 'viewWishListButton') {
        return;
      }
      if (wishlistArr.includes(this.props.uniqueId)) {
        this.removeFromWishlistAPI();
      } else {
        this.addToWishlistAPI();
      }
    } else {
      appCookie.set('wishListUniqueId', this.props.uniqueId , 365 * 24 * 60 * 60 * 1000);
      // this.setState({ isWelcomeBack: true });
      let registerPopupEVent = new Event("loginPopupEVent",{ bubbles: true,});
      registerPopupEVent.simulated = true;
      document.dispatchEvent(registerPopupEVent);
   }
  }

  addToWishlistAPI() { 
    const data = {
      sku_id: this.props.uniqueId,
    };
    apiManager
      .post(addToWishlist, data)
      .then(response => {
        
        getUpdatedWishlist(this);
        this.setState({
          wishlistCurrentImage: this.wishlistAddedImg,
          wishlistPopup: this.wishlistPopupItem(),
        });
      })
      .catch(error => {
      });
  }

  removeFromWishlistAPI() {
    const data = {
      wishlist_id: getCookie(wishlistIdCookie),
      giftlistitem_id: getCorrespondingGiftlistId(this.props.uniqueId),
    };
    apiManager
      .post(removeFromWishlist, data)
      .then(response => {
        this.setState({ wishlistCurrentImage: this.wishListRemovedImg });
        getUpdatedWishlist(this);
        
        if (this.props.isFromWishlistPro === true) {
          this.props.resetRemoveFromWishlistFlag(true)
        }
        
      })
      .catch(error => {
      });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isWelcomeBack: false,
      wishlistCurrentImage: nextProps.isInWishlistPro
        ? this.wishlistAddedImg
        : this.wishListRemovedImg,
    });
  }

  componentDidMount() {
    this.setState({
      wishlistCurrentImage: this.props.isInWishlistPro
        ? this.wishlistAddedImg
        : this.wishListRemovedImg,
    });
    
    if (appCookie.get('wishListUniqueId') !== "" && appCookie.get('isLoggedIn') === 'true') {
      const cookieData = appCookie.get('wishListUniqueId');
      if (document.getElementById(`wishlistBtnId_${cookieData}`)) {
        appCookie.set('wishListUniqueId', '' , 365 * 24 * 60 * 60 * 1000);
        document.getElementById(`wishlistBtnId_${cookieData}`).click();
      }
    }
  }

  wishlistPopupItem() {
    setTimeout(() => {
      this.setState({
        wishlistPopup: null,
      });
    }, 3000);
    let pdpWishlist = '';
    if (this.props.isPDP) {
      pdpWishlist = 'pdpWishlist';
    }
    return (
      <div className={`addedToWishlist clearfix ${pdpWishlist}`}>
        <span className="wishlist-text">Product Added to Wishlist</span>
        <button
          onClick={() => this.redirectToWishlistPage()}
          className="view-btn"
          name="viewWishListButton"
        >
          VIEW
        </button>
      </div>
    );
  }

  redirectToWishlistPage = () => {
    this.props.history.push('/wishlist');
  };

  
  resetLoginValues() {
    this.setState({
      isWelcomeBack: false,
    });
  }

  render() {
    const wishListId = `wishlistBtnId_${this.props.uniqueId}`;
    const tooltipWishlist = <Tooltip id='compare'>
    Wishlist
  </Tooltip>
    return (
      <>
        { !this.props.isPDP &&
          this.state.wishlistPopup
        }
        <OverlayTrigger id='compare'
  overlay={tooltipWishlist} placement="top">
    <Button
            onClick={this.onWishlistClick.bind(this)}
            className="wishlistBtn"
            id={wishListId}
          >
            {this.props.isPDP && !isMobile() ? <>{WISHLIST}</>: '' }{' '} 
            {this.state.wishlistCurrentImage}
            { this.props.isPDP &&
            this.state.wishlistPopup
            }
          </Button>
  </OverlayTrigger>
          

        {/* {this.state.isWelcomeBack ? <UserAccInfo fromWishlistPro resetCallbackPro={this.resetLoginValues.bind(this)}/> : null} */}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(
  mapStateToProps,
  { updatetWishListCount, resetRemoveFromWishlistFlag},
)(Wishlist);
