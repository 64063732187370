import React from 'react';
import {
  cartGetPromoAPI,
  cartApplyPromoAPI,
  cartRemovePromoAPI,
} from '../../../public/constants/constants';
import apiManager from '../../utils/apiManager';
import ViewAllPromo from './viewAllPromo';
import { PROMO_CODE_NOT_VALID, ERR_PROMOTION_CODE_DUPLICATED } from '../../constants/app/cartConstants';

class GetCartPromo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: null,
      isLoading: false,
      viewAll: false,
      error: null,
      isApplyDisable: false,
      promolength:'',
    };
  }

  componentDidMount() {
    this.handlePromotion();
  }

  handlePromotion() {
    apiManager
      .get(cartGetPromoAPI)
      .then(response => {
        // console.log("response in cartGetPromoAPI = "+JSON.stringify(response));
        this.setState({
          promo: response.data.data,
          isLoading: false,
        },()=>{this.checkIfValidCoupon()});
        this.state.promolength=this.state.promo.length;
      })
      .catch(error => {
        console.log("error in cartGetPromoAPI");
        this.setState({
          error,
          isLoading: false,
        },()=>{this.checkIfValidCoupon()});
      });
      
  }
  async removeNoCostEMiPromoCode(promo) {
    try {
      const resp = await apiManager.post(cartRemovePromoAPI + promo)
      this.props.getCartDetails();
  }catch(error){
        
    };
  }
  checkIfValidCoupon(){
    // console.log('inside checkIfValidCoupon this.props.appliedPromoCode = '+JSON.stringify(this.props.appliedPromoCode));
    // console.log('inside checkIfValidCoupon this.state.promo = '+JSON.stringify(this.state.promo));
    let allPromocodesApplied=this.props.appliedPromoCode;
    let removablePromoCodes=[];
    let nonRemovablePromocodes=[];
    {this.props.appliedPromoCode && this.props.appliedPromoCode.map((applied, index) => {
      {this.state.promo && this.state.promo.map((promoCoupon,index1)=>{
        // console.log('inside checkIfValidCoupon map applied = '+applied);
        // console.log('inside checkIfValidCoupon map promoCoupon = '+JSON.stringify(promoCoupon));
        // console.log('inside checkIfValidCoupon map promoCoupon.promocode = '+promoCoupon.promocode);


        if(applied==promoCoupon.promocode){
          if(nonRemovablePromocodes.indexOf(applied)==-1){
            nonRemovablePromocodes.push(applied);
            }
        }

      })}

    })


    }
    // console.log('nonRemovablePromocodes = '+JSON.stringify(nonRemovablePromocodes));
    if(nonRemovablePromocodes!=[] && nonRemovablePromocodes!==null && nonRemovablePromocodes!=''){
      // console.log('nonRemovablePromocodes is not empty = '+JSON.stringify(nonRemovablePromocodes));
      if(allPromocodesApplied!=[] && allPromocodesApplied!==null && allPromocodesApplied!='' ){

       {this.props.appliedPromoCode.map((applied, index) => {
        removablePromoCodes = allPromocodesApplied.filter(function(item) {
          return item !== applied
        })

      })
      }

      }
    }

    // console.log('removablePromoCodes = '+JSON.stringify(removablePromoCodes));
    if(removablePromoCodes!=[] && removablePromoCodes!==null && removablePromoCodes!=''){
      // console.log('removablePromoCodes is not empty = '+JSON.stringify(removablePromoCodes));
      {removablePromoCodes.map((applied, index) => {
      this.removeNoCostEMiPromoCode(applied);
      })}
    }


    // if(this.props.appliedPromoCode.length>0){
    //   if(this.state.promo.length>0){
    //     for(let i=0; i<this.state.promo.length; i=+1){
    //       console.log('inside checkIfValidCoupon for loop i = '+i);
    //       console.log('inside checkIfValidCoupon for loop this.state.promo.length = '+this.state.promo.length);
    //       this.props.appliedPromoCode.forEach((item,index) => {
    //         console.log('inside checkIfValidCoupon for each loop item = '+item);
    //         console.log('inside checkIfValidCoupon for each loop this.state.promo[i].promocode = '+this.state.promo[i].promocode);
    //         // console.log('inside checkIfValidCoupon for loop i = '+i);
    //         if(item !== this.state.promo[i].promocode){
    //           this.removeNoCostEMiPromoCode(item);
    //         }
    //       })
    //     //  if(this.props.appliedPromoCode[0] !== this.state.promo[i].promocode){
    //     //   console.log("checkIfValidCoupon if 0 for if : ");
    //     //   // val = null
    //     //   }
    // }   
    // }
    // }




    // for(let i=0; i<this.state.promo.length; i=+1){
    //   if(this.props.appliedPromoCode[0] == this.state.promo[i].promocode && i<this.state.promo.length-1){
    //     this.setState({
    //       error: 'err'
    //     })
    //   }
    // }
   
  }

  async applyPromoCode(promoCode) { 
    const data = {
      orderId: this.props.orderID,
      promoCode,
    };
    try {
      this.setState({
        isApplyDisable: true,
      });
      if (this.props.appliedPromoCode[0]) {
        await apiManager.post(
          cartRemovePromoAPI + this.props.appliedPromoCode[0],
        );
      }
      const response = await apiManager.post(cartApplyPromoAPI, data);
      this.setState({
        promoCode: response.data.data,
        error: null,
        isApplyDisable: false,
      });
      this.props.getCartDetails();
    } catch (error) {
      if (error.response.data.error.error_key !== ERR_PROMOTION_CODE_DUPLICATED) {
        this.setState({
          error,
          isLoading: false,
          isApplyDisable: false,
        });
      } else {
        this.setState({
          isLoading: false,
          isApplyDisable: false,
        });
      }
    }
  }

  render() {
    const { promo, error } = this.state;
    return (
      <div className="promo">
        {!!error && <div className="promoError">{PROMO_CODE_NOT_VALID}</div>}
        {
          this.state.promolength > 1 || (this.state.promolength == 1 && this.props.appliedPromoCode.length == 0) ? 
            <ul className={((this.state.promolength == 1 && this.props.appliedPromoCode.length == 0) || (this.state.promolength == 2 && this.props.appliedPromoCode.length == 1))?'promoList promoList-with-single-coupon':'promoList'}>
              <div className='promolist-heading'><span className='promolist-heading-logo'>%</span>VIEW COUPONS</div>
              {!!promo &&
            promo.slice(0, 3).map((sellerItemData, index) => {
              if (
                this.props.appliedPromoCode &&
                this.props.appliedPromoCode.length > 0 &&
                this.props.appliedPromoCode[0] === sellerItemData.promocode){
                return null;
              }
              return (
                <li className="promoListItem" key={index}>
                  <p className="promoDesc">
                    {sellerItemData.shortDesc !== ''
                      ? sellerItemData.shortDesc
                      : sellerItemData.description}
                  </p>
                  <span className="promoCode">{sellerItemData.promocode}</span>
                  <button
                    disabled = {this.state.isApplyDisable}
                    className="applyPromo"
                    onClick={this.applyPromoCode.bind(
                      this,
                      sellerItemData.promocode,
                    )}
                  >
                    APPLY
                  </button>
                </li>
              );
            })}
            </ul>
            :
            ''
        }
        
        <ViewAllPromo
          orderID={this.props.orderID}
          getCartDetails={this.props.getCartDetails}
          promo={promo}
          appliedPromoCode={this.props.appliedPromoCode}
        />
      </div>
    );
  }
}

export default GetCartPromo;
