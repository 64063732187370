import React, { Component } from 'react';
import "./Form.scss";


import Button from 'react-bootstrap/lib/Button'
import Col from 'react-bootstrap/lib/Col'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import Label from 'react-bootstrap/lib/Label'
import FormControl from 'react-bootstrap/lib/FormControl'
import Row from 'react-bootstrap/lib/Row'


import {
  rapidRoomsLeadsAPI,PinToCityAPI,
} from '../../../public/constants/constants';
import apiManager from '../../utils/apiManager';
import {
  validateEmptyObject,
  validateFullName,
  regexName,
  regexMobileNo,
  regexEmail,
} from "../../utils/validationManager";
import {
  FIELDREQ_MSG,
  NAME_VALIDATION_MSG,
  INVALID_MOBILE_NUMBER,
  INVALID_EMAIL_ADDRESS,
  INVALID_PINCODE_FORMAT,
  NOT_VALID_PINCODE, 
} from "../../constants/app/primitivesConstants";

const PINCODE_REGEX = /^\d{6}$/;

class FormDataNew extends Component {
  constructor() {
    super();
    this.state = {
      name: null,
      email: null,
      phone1: null,
      city: null,
      state: null,
      pincode: null,
      organization: null,
      note:null,
      nameErrMsg: null,
      emailErrMsg: null,
      phoneErrMsg: null,
      pincodeErrMsg: null,
      organizationErrMsg:null,
      qualificationErrMsg: null,
      successMsg: null,
      errorMsg: null,
      submitDisabled:false,
    };
  }

    fieldChangeHandler = (e) => {
      console.log(e.target.value);
      if (
        e.target.name === 'pincode' 
        &&
        e.target.value 
        &&
        PINCODE_REGEX.test(e.target.value) 
      ) {
        this.fetchCityFromPin(e.target.value);
      }
      this.setState({
        [e.target.name]: e.target.value !== "" ? e.target.value : null,
      });
    };
  
    fetchCityFromPin = pincode => {
      if (!pincode) return;
      apiManager
        .get(PinToCityAPI + pincode)
        .then(response => {
          const { data } = response;
          if (data.data && data.data.city) {
            this.setState({
              pincodeErrMsg: null,
              city: data.data.city,
              state: data.data.state,
            });
          } else {
            this.setState({ pincodeErrMsg: NOT_VALID_PINCODE });
          }
        })
        .catch(error => {
          this.setState({ pincodeErrMsg: NOT_VALID_PINCODE });
        });
    };
  
    keyPressHandler = (e) => {
      if (e.key === "Enter") this.formSubmitHandler();
    };
  
    formValidationHandler = () => {
      this.setState({
        nameErrMsg: null,
        emailErrMsg: null,
        phoneErrMsg: null,
        pincodeErrMsg: null,
        organizationErrMsg:null,
        successMsg: null,
        qualificationErrMsg: null,
        errorMsg: null,
      });
      let isValid = true;
      const {
        name,
        email,
        phone1,
        pincode,
        city,
        organization,
        note,
      } = this.state;

      if (!validateEmptyObject(name)) {
        this.setState({ nameErrMsg: FIELDREQ_MSG });
        isValid = false;
      } else if (!validateFullName(name) || !regexName.test(name)) {
        this.setState({ nameErrMsg: NAME_VALIDATION_MSG });
        isValid = false;
      }
      if (!validateEmptyObject(organization)) {
        this.setState({ organizationErrMsg: FIELDREQ_MSG });
        isValid = false;
      } 
      if (!validateEmptyObject(email)) {
        this.setState({ emailErrMsg: FIELDREQ_MSG });
        isValid = false;
      } else if (!regexEmail.test(email)) {
        this.setState({ emailErrMsg: INVALID_EMAIL_ADDRESS });
        isValid = false;
      }
      if (!validateEmptyObject(phone1)) {
        this.setState({ phoneErrMsg: FIELDREQ_MSG });
        isValid = false;
      } else if (!regexMobileNo.test(phone1)) {
        this.setState({ phoneErrMsg: INVALID_MOBILE_NUMBER });
        isValid = false;
      }
      if (!validateEmptyObject(pincode)) {
        this.setState({ pincodeErrMsg: FIELDREQ_MSG });
        isValid = false;
      } 
      else if (!PINCODE_REGEX.test(pincode)) {
        this.setState({ pincodeErrMsg: INVALID_PINCODE_FORMAT });
        isValid = false;
      } 
      else if (!validateEmptyObject(city)) {
        this.setState({ pincodeErrMsg: NOT_VALID_PINCODE });
        isValid = false;
      }
      if (!validateEmptyObject(note)) {
        this.setState({ qualificationErrMsg: FIELDREQ_MSG });
        isValid = false;
      }
  

   
    
      return isValid;
    };

  formSubmitHandler = (e) => {
    if (!this.formValidationHandler()) return

    this.setState({
      submitDisabled: true,
    });
    setTimeout(() => {
      this.setState({
        submitDisabled:false,
      })
    }, 5000);
    const {
      name,
      phone1,
      email,
      pincode,
      city,
      state,
      organization,
      note,
      
    } = this.state;
    console.log("Submitted");
    const urlSearchParams = new URLSearchParams(
      window.location.search.substring(1),
    );
  
    const data = {
      name,
      phone1,
      email,
      pincode,
      city,
      state,
      organization,
      note,
      pageName: "rapid-rooms",
    };
    
    apiManager
      .post( rapidRoomsLeadsAPI, data)
      .then(response => {
        const resData = response.data;
        if (resData.status === 'success') {
          this.setState({
            name: "",
            phone1: "",
            email: "",
            pincode: "",
            city: "",
            state: "",
            organization: "",
            note: "",
            successMsg:
              'Thank You! Your nearest store representative will reach out to you shortly.',
          });
        } else {
          this.submittedFail();
        }
      })
      .catch(() => {
        this.submittedFail();
        
      });
  };

  submittedFail = () => {
    window.alert("Could not submit the data.");
    console.log("Could not submit the data");
  };

  
  
  render() {
    const {
      name,
      email,
      phone1,
      city,
      organization,
      pincode,
      note,
      nameErrMsg,
      emailErrMsg,
      phoneErrMsg,
      pincodeErrMsg,
      organizationErrMsg,
      qualificationErrMsg,
      successMsg,
      errorMsg,
    } = this.state;
    return (
      <>

        <div className='form-data-body'>
          <form className="form-box" method="POST"> 
            <Row>
              <Col xs={6 } className='input-block input-left'>
                <FormGroup>
                  <Label >Name *</Label>
                  <FormControl
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter Full Name"
                    value={name}
                    onChange={this.fieldChangeHandler}
                    onKeyPress={this.fieldChangeHandler}
                  />
                  <div className="err-div">
                    <p className="error-msg">{nameErrMsg}</p>
                  </div>
                </FormGroup>
              </Col>
              <Col xs={6} className='input-block input-right'>
                <FormGroup>
                  <Label>Email *</Label>
                  <FormControl
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Enter Email"
                    value={email}
                    onChange={this.fieldChangeHandler}
                    onKeyPress={this.fieldChangeHandler}
                  />
                  <div className="err-div">
                    <p className="error-msg">{emailErrMsg}</p>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className='input-block input-left'>
                <FormGroup>
                  <Label>Mobile *</Label>
                  <FormControl
                    type="text"
                    name="phone1"
                    className="form-control"
                    placeholder="Enter Mobile Number"
                    value={phone1}
                    onChange={this.fieldChangeHandler}
                    onKeyPress={this.fieldChangeHandler}
                  />
                  <div className="err-div">
                    <p className="error-msg">{phoneErrMsg}</p>
                  </div>
                </FormGroup>
              </Col>
              <Col xs={6} className='input-block input-right'>
                <FormGroup >
                  <Label>Pincode *</Label>
                  <FormControl
                    type="text"
                    name="pincode"
                    className="form-control"
                    placeholder="Enter Pincode"
                    value={pincode}
                    onChange={this.fieldChangeHandler}
                    onKeyPress={this.fieldChangeHandler}
                  />
                  <div className="err-div">
                    <p className="error-msg">{pincodeErrMsg}</p>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className='input-block input-left'>
                <FormGroup >
                  <Label >City *</Label>
                  <FormControl
                    type="text"
                    disabled
                    name="city"
                    className="form-control"
                    placeholder="City"
                    value={city}
                  />
                  <div className="err-div">
                    <p className="error-msg" />
                  </div>
                </FormGroup>
              </Col>
              <Col xs={6} className='input-block input-right'>
                <FormGroup >
                  <Label>Organization *</Label>
                  <FormControl
                    type="text"
                    name="organization"
                    className="form-control"
                    placeholder="Enter Organization Name"
                    value={organization}
                    onChange={this.fieldChangeHandler}
                    onKeyPress={this.fieldChangeHandler}
                  />
                  <div className="err-div">
                    <p className="error-msg">{organizationErrMsg}</p>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <div>
              <Col xs={12} >
                <textarea
                  name="note"
                  id="note"
                  className="form-textarea"
                  placeholder="Requirements*"
                  onChange={this.fieldChangeHandler}
                  value={note}
                  autoComplete="none"
                ></textarea>
                <div className="err-div">
                  <p className="error-msg-note">{qualificationErrMsg}</p>
                </div>
              </Col>
            </div>
            <Col xs={6} className='form-body'>
              <FormGroup >
                <h6 className="message">
                  {errorMsg && <span className="failed">{errorMsg}</span>}
                </h6>
                <Button
                  className="form-button"
                  onClick={this.formSubmitHandler}
				  disabled={this.state.submitDisabled}
                >
                  SUBMIT
                </Button>
              </FormGroup>
            </Col>
          </form>
        </div>
        {successMsg ? window.location.pathname='/thank-you' : null}
      </>
    );
  }
}
export default FormDataNew;
