import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import apiManager from "../../utils/apiManager";
import { isMobile } from "../../utils/utilityManager";
import { espotAPI, imagePrefix } from "../../../public/constants/constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../public/styles/topfurniturecategories/topFurnCat.scss";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { InView } from 'react-intersection-observer';

export default class TopFurnCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      espotName: "GI_Homepage_TopCategories",
      title: "",
      title2: "",
      catData: [],
      oldSlide: 0,
      activeSlide: 0,
      activeSlide2: 0,
      inview: false,
    };
  }

  getEspotData() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        const { data } = response || {};
        this.setState({
          title: data.data.title,
          catData: data.data.catData,
          title2: data.data.title2 || "",
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.getEspotData();
  }

  render() {
    const settings = {
      dots: false,
      lazyLoad: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3.5,
      slidesToScroll:
        this.state.activeSlide == this.state.catData.length - 2 ? 0.5 : 1,
      initialSlide: 0,
      prevArrow: false,
      nextArrow: false,
      autoplay: false,
      arrows: false,
      centerPadding: "20%",
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current }),
    };
    const settings2 = {
      dots: false,
      lazyLoad: true,
      infinite: false,
      speed: 500,
      slidesToShow: 7.2,
      slidesToScroll: this.state.activeSlide == this.state.catData.length - 1
      ? 0.5
      : 1,
      initialSlide: 0,
      //prevArrow: true,
      //nextArrow: true,
      autoplay: false,
      arrows: true,
      beforeChange: (current, next) =>
      this.setState({ oldSlide: current, activeSlide: next }),
    afterChange: current => this.setState({ activeSlide2: current }),
    };
    return (
      <InView as="div" onChange={(inView, entry) => this.setState({inview: this.state.inview == true || inView==true? true: false})} style={{minHeight : !isMobile() ? '100px' : '100px'}}>
          {this.state.inview ?<div className="topFurn-container">
         {/* <div className="blue-header">Hello! Start Exploring</div> */}
        <div className="topFurn-wrapper">
          <div className="topFurn-header-container">
            <h2>{this.state.title}</h2>
            <h4>{this.state.title2}</h4>
          </div>
          {isMobile() ? (
            <div className="topfun-mob-container">
              <div className="topfun-mob-wrapper">
                <Slider {...settings} ref={slider => (this.slider = slider)}>
                  {!!this.state.catData &&
                    this.state.catData.map((cat, key) => (
                      <div className="cat-container">
                        <Link to={cat.link}>
                          <div className="cat-wrapper">
                            <div className="cat-img">
                              <img className="HP-Top-Cat" id = {cat.name.replaceAll(' ', '_')} loading="lazy" style={{ width: "100%" }} effect="blur" src={imagePrefix + cat.img2} />
                            </div>
                            <div className="cat-details">{cat.name}</div>{" "}
                          </div>
                        </Link>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          ) : (
            <div className="topfurn-body-container">
              <div className="topfurn-body-wrapper">
                <Slider
                  {...settings2}
                  ref={slider2 => (this.slider2 = slider2)}
                >
                  {!!this.state.catData &&
                    this.state.catData.map((cat, key) => (
                      <div className="cat-container">
                        <Link to={cat.link}>
                          <div className="cat-wrapper">
                            <div className="cat-img">
                              <img className="HP-Top-Cat" id = {cat.name.replaceAll(' ', '_')} loading="lazy" style={{ width: "100%" }} effect="blur" src={imagePrefix + cat.img} />
                            </div>
                            <div className="cat-details">{cat.name}</div>{" "}
                          </div>
                        </Link>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          )}
        </div>
      </div>:<></>}
      </InView>
    );
  }
}
