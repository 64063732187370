import {
  currencyINR,
  purchaseGTagEvent,
  productDetailsGTagEvent,
  checkoutGTagEvent,
  addToCartGTagEvent,
  removeFromCartGTagEvent,
  brandParamGTag,
  affiliationParamGTag,
  productClickGTagEvent,
  impressionsGTagEvent,
  formSubmissionGTagEvent,
} from '../constants/app/gtmConstants';
import { createHash } from 'crypto';

export function triggerPlpFilterEvent(facetName,facetData){
  if(facetName!='' && facetData!={}){
    const plpFilterGtag ={
      event: 'plp_filter_click',
      PLPfilter_facet: facetName,
      PLPfilter_label: facetData.label
    }
    console.log('plpFilterGtag inside triggerPlpFilterEvent in gtm = '+JSON.stringify(plpFilterGtag));
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(plpFilterGtag);
  }
}

export function triggerOrderConfirmationGTEvent(orderData) {
  if (orderData && !window.purchaseGTag) {
    const purchaseGTag = {
      event: purchaseGTagEvent,
      ecommerce: {
        currencyCode: currencyINR,
        purchase: {
          actionField: {
            id: orderData.orderID,
            affiliation: affiliationParamGTag,
            revenue: orderData.orderSummary.netAmount,
            shipping: orderData.orderSummary.shippingCharges,
          },
          products: orderData.orderItems.map(orderItem => ({
            name: orderItem.productName,
            id: orderItem.partNumber,
            price: orderItem.offerPrice,
            brand: brandParamGTag,
            variant: orderItem.shortDescription,
            quantity: orderItem.quantity,
            category: orderItem.breadcrumbData ? orderItem.breadcrumbData : '',
          })),
          email_id: createHash('sha256').update(orderData.address.emailId).digest('hex'),
          phone_no: createHash('sha256').update(orderData.address.phoneNumber).digest('hex'),
        },
      },
    }
    window.purchaseGTag = purchaseGTag;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(purchaseGTag);
  }
}

export function unmountOrderConfirmationGTEvent() {
  if (window.purchaseGTag) {
    delete window.purchaseGTag;
  }
}


export function triggerBannerClickHomepageEvent(bannerData){
  if(bannerData!={}){
    const bannerHPGtag ={
      event: 'homepage_banner_click',
      HPbanner_id: bannerData.bannerID,
      HPbanner_url: bannerData.bannerURL

    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(bannerHPGtag);
  }
}


export function triggerProductDetailGTEvent(skuData, category) {
  var categoryName = 'furniture-' + category.replace(' & ', '-').replace(' ', '-').toLowerCase();
  if (
    skuData &&
    skuData.partNumber &&
    (!window.pdpEntries || !window.pdpEntries[skuData.partNumber])
  ) {
    const pdpGTag = {
      event: productDetailsGTagEvent,
      ecommerce: {
        currencyCode: currencyINR,
        detail: {
          actionField: {},
          products: [
            {
              name: skuData.productName,
              id: skuData.partNumber,
              price: skuData.offerPrice,
              brand: brandParamGTag,
              variant: skuData.shortDescription,
              category: categoryName,
            },
          ],
        },
      },
    };
    window.pdpEntries = window.pdpEntries || {};
    window.pdpEntries[skuData.partNumber] = true;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(pdpGTag);
  }
}

export function unmountProductDetailGTEvent() {
  if (window.pdpEntries) {
    delete window.pdpEntries;
  }
}


export function triggerAddToCartGTEvent(skuData, quantity, category) {
  var categoryName = 'furniture-' + category.replace(' & ', '-').replace(' ', '-').toLowerCase();
  if (skuData && skuData.partNumber) {
    const addToCartGTag = {
      event: addToCartGTagEvent,
      ecommerce: {
        currency: currencyINR,
        add: {
          products: [
            {
              name: skuData.productName,
              id: skuData.partNumber,
              price: skuData.offerPrice,
              brand: brandParamGTag,
              variant: skuData.shortDescription,
              quantity,
              category: categoryName,
            },
          ],
        },

      },
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(addToCartGTag);
  }
}


export function triggerAddBundleToCartGTEvent(skuData) {
  if (skuData) {
    const bundledata = {
      event: 'add_bundle_to_cart',
      AddCartSource: `bundle || ${skuData[0].skuData.productName} `,
      ecommerce: {
        currencyCode: currencyINR,
        add: {
          products: skuData.map((sku,key)=>({

            name: sku.skuData.productName,
            id: sku.skuData.partNumber,
            price: sku.skuData.offerPrice,
            brand: brandParamGTag,
            variant: sku.skuData.shortDescription,
            quantity: sku.quantity,
          })),
        },
      },
    };
    // console.log(bundledata)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(bundledata);
  }
}


export function triggerRemoveFromCartGTEvent(skuData, quantity) {
  if (skuData && skuData.partNumber) {
    const removeFromCartGTag = {
      event: removeFromCartGTagEvent,
      ecommerce: {
        currencyCode: currencyINR,
        remove: {
          products: [
            {
              name: skuData.productName,
              id: skuData.partNumber,
              price: skuData.offerPrice,
              brand: brandParamGTag,
              variant: skuData.shortDescription,
              quantity,
            },
          ],
        },
      },
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(removeFromCartGTag);
  }
}

export function triggerReviewCartGTEvent(cartData) {
  if (cartData && !window.reviewCartGTag) {
    const reviewCartGTag = {
      event: checkoutGTagEvent,
      ecommerce: {
        currencyCode: currencyINR,
        checkout: {
          actionField: {
            step: 1,
          },
          products: cartData.cartItems.map(cartItem => ({
            name: cartItem.productName,
            id: cartItem.partNumber,
            price: cartItem.offerPrice,
            brand: brandParamGTag,
            variant: cartItem.shortDescription,
            quantity: cartItem.quantity,
          })),
        },
      },
    };
    window.reviewCartGTag = reviewCartGTag;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(reviewCartGTag);
  }
}

export function unmountReviewCartGTEvent() {
  if (window.reviewCartGTag) {
    delete window.reviewCartGTag;
  }
}

export function triggerGuestLoginIdGTEvent() {
  if (!window.guestUserLoginIdGTag) {
    const guestUserLoginIdGTag = {
      event: checkoutGTagEvent,
      ecommerce: {
        currencyCode: currencyINR,
        checkout: {
          actionField: {
            step: 2,
          },
        },
      },
    };
    window.guestUserLoginIdGTag = guestUserLoginIdGTag;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(guestUserLoginIdGTag);
  }
}

export function unmountGuestLoginIdGTEvent() {
  if (window.guestUserLoginIdGTag) {
    delete window.guestUserLoginIdGTag;
  }
}

export function triggerShipBillAddrGTEvent(data) {

  const transformed = data.map(({partNumber, productName, offerPrice, quantity}) => ({item_name: productName,item_id:partNumber,price: offerPrice,quantity: quantity}));
  if (!window.shipBillAddrGTag) {
    const shipBillAddrGTag = {
      event: 'add_shipping_info',
      ecommerce: {
        currencyCode: currencyINR,
        products: transformed,
      },
    };
    window.shipBillAddrGTag = shipBillAddrGTag;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(shipBillAddrGTag);
  }
}

export function unmountShipBillAddrGTEvent() {
  if (window.shipBillAddrGTag) {
    delete window.shipBillAddrGTag;
  }
}

export function triggerPaymentOptionGTEvent() {
  if (!window.paymentOptionGTag) {
    const paymentOptionGTag = {
      event: 'add_payment_info',
      ecommerce: {
        payment_type:'',
        currencyCode: currencyINR,
        products: []
      },
    };
    window.paymentOptionGTag = paymentOptionGTag;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(paymentOptionGTag);
  }
}

export function unmountPaymentOptionGTEvent() {
  if (window.paymentOptionGTag) {
    delete window.paymentOptionGTag;
  }
}

export function triggerARButtonClicked(skuData){
  if(skuData && skuData.partNumber){
    const ARclick = {
      event: 'ar_button_pdp',
      ARName: skuData.productName
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(ARclick);
  }
}


export function triggerVisualizerButtonClicked(skuData){
  if(skuData && skuData.partNumber){
    const ARclick = {
      event: 'visualizer_button_pdp',
      VisualizerName: skuData.productName,
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(ARclick);
  }
}


export function triggerProductClickGTEvent(skuData, source, position) {
  if (skuData && skuData.partNumber) {
    const productClickGTag = {
      event: productClickGTagEvent,
      ecommerce: {
        currencyCode: currencyINR,
        click: {
          actionField: {
            list: source,
          },
          products: [
            {
              name: skuData.productName,
              id: skuData.partNumber,
              price: skuData.offerPrice,
              brand: brandParamGTag,
              variant: skuData.shortDescription,
              position,
              category:skuData.category || '',
            },
          ],
        },
      },
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(productClickGTag);
  }
}

export function triggerImpressionsGTEvent(skuList, source, pageNumber, pageSize) {
  if (
    skuList &&
    (!window.impressionsGTag || !window.impressionsGTag[`${source}${pageNumber}${pageSize}`])
  ) {
    const impressionsGTag = {
      event: impressionsGTagEvent,
      ecommerce: {
        currencyCode: currencyINR,
        impressions: skuList.map((skuData, index) => ({
          name: skuData.productName || skuData.skuList[0].productName,
          id: skuData.partNumber || skuData.skuList[0].partNumber,
          price: skuData.offerPrice || skuData.skuList[0].offerPrice,
          brand: brandParamGTag,
          variant: skuData.shortDescription || skuData.skuList[0].shortDescription,
          list: source,
          category: source,
          position: (pageNumber - 1) * pageSize + index + 1,
        })),
      },
    };
    window.impressionsGTag = window.impressionsGTag || {};
    window.impressionsGTag[`${source}${pageNumber}${pageSize}`] = true;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(impressionsGTag);
  }
}

export function unmountImpressionsGTEvent() {
  if (window.impressionsGTag) {
    delete window.impressionsGTag;
  }
}

export function triggerFormSubmissionGTEvent(formType, formPosition) {
  const formSubmsnGTag = {
    event: formSubmissionGTagEvent,
    formType,
    formPosition,
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(formSubmsnGTag);
}

export function triggerSmallBannerClickEvent(bannerData){
  if(bannerData!={}){
    const bannerHPGtag ={
      event: bannerData.eventName,
      HPbanner_id: bannerData.bannerImg,
      HPbanner_url: bannerData.bannerURL

    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(bannerHPGtag);
  }
}


export function triggerHPProductClickEvent(data){
  if(data!={}){
    const bannerHPGtag ={
      event: data.eventName,
      productName: data.productName,
      skuid: data.skuid
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(bannerHPGtag);
  }
}


export function triggerHPDealsClickEvent(data){
  if(data!={}){
    const bannerHPGtag ={
      event: 'BestDeals',
      category: data.category,
      subCategory: data.subCategory,
      HPbanner_url: data.link,
      offer: data.offer
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(bannerHPGtag);
  }
}


export function triggerHPCollectionsClickEvent(data){
  if(data!={}){
    const bannerHPGtag ={
      event: 'HP_Collections',
      category: data.category,
      productName: data.productName,
      skuid: data.skuid
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(bannerHPGtag);
  }
}


export function triggerNotificationPermission(data){
  const notifData = {
    event : 'NotificationPermission',
    permission: data
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(notifData);
}
