/**
 *
 * Wardrobe Container
 *
 */

import React from 'react';
import '../../../public/styles/static-pages/wardrobes.scss';
import '../../../public/styles/staticPages/staticPages.scss';
import ConsultationForm from '../../components/Primitives/ConsultForm';
import ContentEspot from '../../components/Primitives/staticContent';
import WardrobeBanner from '../../components/wardrobesComponent/wardrobesBanner';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb';
import Pixels from '../../components/Primitives/pixels';
import WardrobeTypes from '../../components/wardrobesComponent/wardrobeTypes';
import apiManager from '../../utils/apiManager';
import { espotAPI } from '../../../public/constants/constants';

export class WardrobeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      espotName: 'GI_MODULAR_WARDROBES_SEO_DATA',
      metaTitle:'',
      metaDescription:'',
      breadcrumbTitle:'',
      h1Title:'',
    };
  }

  getModularWardrobesSeoDetails() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        const { data } = response;
        this.setState({
          metaTitle:data.data.metaTitle,
          metaDescription:data.data.metaDescription,
          breadcrumbTitle:data.data.breadcrumbTitle,
          h1Title:data.data.h1Title,
        });
        document.title = data.data.metaTitle;
      })
      .catch(error => {
        console.log('error in fetching ModularWardrobesSeoDetails');
      });
  }

  componentDidMount() {
    this.getModularWardrobesSeoDetails();
  }

  render() {
    return (
      <>
        {/* <ContentEspot espotName = { 'GI_PIXEL_WARDROBES_BODY_START' } /> */}
        <link rel="canonical" href={window.location.href} />
      <meta name="description" content={this.state.metaDescription} />
        <section className="staticpage wardrobesContainer">
          <Pixels espotName="GI_PIXEL_WARDROBES_META" />
          <div className="slider">
            <WardrobeBanner />
          </div>
          <Breadcrumb
            {...this.props.match.params}
            staticName={this.state.breadcrumbTitle}
          />
          <WardrobeTypes h1Title={this.state.h1Title}/>
          <ContentEspot espotName="GI_DREAM_WARDROBES_STEPS" />
          <div className="formContainer">
            <ContentEspot espotName="GI_WARDROBES_FORMBACKGROUND_IMG" />
            <div id="consultForm" className="formDetails formDetails-consultForm">
			    <h2 className="title">Talk to our design expert.</h2>
              <ConsultationForm sourcePage="wardrobes" source="Wardrobes-BAC"/>
            </div>
          </div>
          <ContentEspot espotName="GI_Homepage_Our_Promises" />
        </section>
        {/* <ContentEspot espotName = { 'GI_Wardrobes_Benefit_Modular' } />
            <WardrobeConfiguration />
			<WardrobeAccessoriesConfiguration />
			<div className="formContainer">
			<ContentEspot espotName = { 'GI_WARDROBES_FORMBACKGROUND_IMG' } />
				 <div id='consultForm' className="formDetails">
					<h2 className="title">{BOOK_CONSULTATION}</h2>
					<p className="desc">{WARDROBE_CONSULT} </p>
					<ConsultationForm />
			  </div>
            </div>
            <ContentEspot espotName = { 'GI_WARDROBES_FAQ' } />
            <KitchenStore />
			<ContentEspot espotName={ 'GI_WARDROBES_9' } />
			<ContentEspot espotName={ 'GI_WARDROBES_10' } />
			<ContentEspot espotName={ 'GI_WARDROBES_11' } /> */}
        {/* <ContentEspot espotName = { 'GI_PIXEL_WARDROBES_BODY_END' } /> */}
      </>
    );
  }
}

export default WardrobeContainer;
