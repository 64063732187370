import React, { Suspense} from 'react';
import '../../../public/styles/checkout.scss';
import axios from 'axios';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { OrderSummaryComponent } from './orderSummary'
import appCookie from '../../utils/cookie';
import { isMobile } from '../../utils/utilityManager';
import MWebLogo from "../SVGs/mWebLogo";
import { WALLETS_MAPPING } from '../../constants/app/checkoutConstants';

import {
  storeId,
  accessToken,
  accessTokenCookie,
  userLoginAPI,
  userDataAPI,
  UserVerifyAPI,
  OrderSummaryAPI,
  CreateCheckSumAPI,
  host,
  secureHttp,
  port2,
  shipModeAPI,
  espotAPI,
  initiateBOPayment,
  verifyBOPayment,
  cartDetailAPI,
} from '../../../public/constants/constants';
import FailPop from './failPop';
import apiManager from '../../utils/apiManager';
import LoadingIndicator from '../../utils/loadingIndicator';
const Step1Component = React.lazy(()=> import('./step1'));
const Step2Component = React.lazy(()=> import('./step2'));
const Step3Component = React.lazy(()=> import('./step3'));
import {Helmet} from "react-helmet";
import {
  Form,
  FormGroup,
  FormControl,
} from 'react-bootstrap';

export class CheckoutComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      has_pass: false,
      same_bill: true,
      step: '',
      phone: '',
      email: '',
      logon_by: '',
      loggedIn: false,
      loginStatus: 'Login/Register',
      userType: 'Hello Guest!',
      showGift: false,
      loggedIn: false,
      addressList: null,
      orderSummaryData: '',
      step2SelectedAddress: '',
      pay: false,
      BankID: '',
      paymentMode: '',
      paymentId: '',
      failPop: false,
      redirect: false,
      shipMode: null,
      isCheckSumAPIFail: false,
      billAddId: '',
      updatedAddressID: '',
      isProcceedBtnDisabled: true,
      editAddressEnabled: false,
      step2SelectedClassName:'',
      step3SelectedClassName:'',
      step2SelectedClassName1:'',
      step3SelectedClassName1:'',
      proceedToPayment:false,
      noCostEMIDiscount:'',
      promotionCode:[""],
      NC_EmiTenures:'',
      NC_EMI_CHECKOUT_INFO: 'GI_NO_COST_EMI_CHECKOUT_PAGE',
      payWithHeading1:'',
      payWithHeading2:'',
      checkoutSection:[],
      checkoutSectionImg:'',
      ncEmiAcquirer:'',
      ncEmiItemCode:'',
      NOCostMobDiscText1:'',
      NOCostMobDiscText2:'',
      LoadingIndicator:'',
      checkoutHeading1:'',
      checkoutHeading2:'',
      checkoutHeading3:'',
      checkoutHeading4:'',
      boMinSpend:'',
      createOrderAPI:'',
      boEspot:'GI_BO_StylesheesAndOrderAPI',
      script1:'',
      script2:'',
      script3:'',
      createOrderAPI:'',
      bankOfferCouponApplied: false,
      noCostEmiCouponApplied: false, 
      boCoupon: '',
      BOMobDiscText1:'',
      BOMobDiscText2:'',
      paymentLoadingIndicator:'',
      transactionURL:'',
      responseText: '',
      GDPR: 0,
      GDPR_PD: 0,
      US_PRIVACY: '',
      GDPR_CONSENT_874: '',
  }
  }

  componentDidMount() {
    if (window.CMP) {
      const consentData = CMP.getConsentData();
      this.setState({
        GDPR: consentData.gdpr,
        GDPR_CONSENT_874: consentData.gdpr_consent,
        GDPR_PD: consentData.gdpr_pd,
        US_PRIVACY: consentData.us_privacy,
      });
    }
    const parsed = queryString.parse(location.search);
    if (parsed && parsed.status == "fail") {
      this.setState({
        failPop: true,
      })
    }
    const coke = appCookie.get('isLoggedIn');
    this.getBOEspotData();
    this.getEspotData();
    this.callOrderSummaryAPI();
    this.fetchShipModeAPI();
    if (coke == 'true') {
      this.callprofileAPI()
        .then(data => {
          this.setState({
            step: 2,
            loggedIn: true,
            logon_by: data.logonID,
            email: data.emailID,
            phone: data.mobileNo,
          })
        }).catch(error => {
          throw new Error(error);
        })
    }
    else{
      this.setState({
        step: 1,
      })
    }
  }

  componentDidUpdate(){
    let noCostEmiCouponApplied=false;
    let bankOfferCouponApplied=false;
    let boCouponMatch=false;
    this.state.promotionCode.forEach((item,index) => {
      if(item.startsWith("NC_")){      
        noCostEmiCouponApplied=true;
      }
    })
    this.state.promotionCode.forEach((item,index) => {
      if(item.startsWith("BO_")){
          bankOfferCouponApplied=true;
          if(item==this.state.boCoupon){
            boCouponMatch=true;
          }
      }
    })
    this.state.bankOfferCouponApplied=bankOfferCouponApplied;
    this.state.noCostEmiCouponApplied=noCostEmiCouponApplied;

    if(this.state.paymentMode!="NC_EMI"){
      if((this.state.paymentMode=="CREDIT_CARD" || this.state.paymentMode=="CC_EMI") && this.state.noCostEmiCouponApplied==true){
        this.state.pay= false;
      }else{
        if((this.state.paymentMode=="CREDIT_CARD" || this.state.paymentMode=="CC_EMI") && this.state.bankOfferCouponApplied==true && this.state.boCoupon==''){
          this.state.pay= false;
        }else{
          this.state.pay= true;
        }
      }
    }else if(this.state.paymentMode=="NC_EMI"){
      this.state.pay= true;
    }
  }

    getBOEspotData() {
    apiManager
      .get(espotAPI + this.state.boEspot)
      .then(response => {
        const {data} = response || {};

        this.setState({
          script1: data && data.data.script1,
          script2: data && data.data.script2,
          script3: data && data.data.script3,
          createOrderAPI: data && data.data.createOrderAPI,
        });
      })
      .catch(error => {
        console.log("no data.");

        this.setState({

        });
      });

  }

  getEspotData() {
    apiManager
      .get(espotAPI + this.state.NC_EMI_CHECKOUT_INFO)
      .then(response => {
        const {data} = response || {};

        this.setState({
          payWithHeading1: data && data.data.payWithHeading1,
          payWithHeading2: data && data.data.payWithHeading2,
          checkoutSection: data && data.data.checkoutSection,
          checkoutSectionImg: data && data.data.checkoutSectionImg,
          NOCostMobDiscText1: data && data.data.NOCostMobDiscText1,
          NOCostMobDiscText2: data && data.data.NOCostMobDiscText2,
          checkoutHeading1: data && data.data.checkoutHeading1,
          checkoutHeading2: data && data.data.checkoutHeading2,
          checkoutHeading3: data && data.data.checkoutHeading3,
          checkoutHeading4: data && data.data.checkoutHeading4,
          BOMobDiscText1: data && data.data.BOMobDiscText1,
          BOMobDiscText2: data && data.data.BOMobDiscText2,

        });
      })
      .catch(error => {
        console.log("no data.");

        this.setState({

        });
      });

  }

  callprofileAPI = () => new Promise((resolve, reject) => {
    const token = appCookie.get('accessToken')
    axios.get(userDataAPI, {
      headers: { store_id: storeId, access_token: token },
    }).then(response => {
      resolve(response.data.data);
    }).catch(error => {
      reject(error);
    })
  })

  fetchShipModeAPI() {
    axios
      .get(shipModeAPI, {
        headers: { store_id: storeId, access_token: accessToken },
      })
      .then(response => {
        this.setState({
          shipMode: response.data.data.shipModes[0].shipModeId,
        })
      })
      .catch(error => {
      });
  }

  handleHasPass = () => {
    if (this.state.has_pass == false) {
      this.setState({
        has_pass: true,
      })
    } else {
      this.setState({
        has_pass: false,
      })
    }
  }


  handleUserLogin(data) {
    this.checkUserExist(data)
      .then(this.CallLoginApi)
      .catch((err) => {
      })
  }

  checkUserExist = (data) => new Promise((resolve, reject) => {
    const obj = {
      logon_id: data.user_id,
    }
    axios.get(`${UserVerifyAPI}${data.user_id}`, {
      headers: { store_id: storeId, access_token: accessToken },
    }).then(response => {

      resolve(data);
    }).catch(error => {
      this.setState({
        message: 'user verify err',
      });
      reject(error);
    })
  })

  CallLoginApi = (data) => {
    this.setState({ message: null });
    axios
      .post(userLoginAPI, data, {
        headers: { store_id: storeId, access_token: accessToken },
      })
      .then(response => {
        window.location.reload();
        appCookie.set('isLoggedIn', true, 365 * 24 * 60 * 60 * 1000);
        document.cookie = `${accessTokenCookie}=${
          response.data.data.access_token
        };path=/;expires=''`;
		 document.cookie = `userID=${
          response.data.data.userID
        };path=/;expires=''`;
        this.setState({
          loginStatus: 'Logout',
          userType: 'Hello User!',
          show: false,
        });
        window.location.assign("/cart");
      })
      .catch(error => {
        const errorData = error.response.data;
        const errorMessage = errorData.error.error_message;
        this.setState({
          // message: `Password entered is incorrect. Please try again or uncheck the above box to proceed.`,
          message: `Password entered is incorrect. Please try again.`,
        });
      });
  }

  handleChange = () => {
    if (this.state.step == 2) {
      this.setState({
        step: 1,
      })
    } else {
      this.setState({
        step: 2,
        LoadingIndicator:false,
      })
    }
  }

  handleSameBill = () => {
    if (this.state.same_bill == false) {
      this.setState({
        same_bill: true,
      })
    } else {
      this.setState({
        same_bill: false,
      })
    }
  }

  showGiftCard = () => {

  }

  handleChangeMobile = () => {
    this.setState({
      step: 1,
    })
  }

  callOrderSummaryAPI = () => {
    const token = appCookie.get('accessToken');
    axios.get(OrderSummaryAPI, {
      headers: { store_id: storeId, access_token: token },
    }).then(response => {
      if (!response.data.data.orderSummary.netAmount) {
        this.setState({
          redirect: true,
        })
        return;
      }
      this.setState({
        orderSummaryData: response.data.data.orderSummary,
        promotionCode: response.data.data.orderSummary.promotionCode,
      })
      this.addCHECKOUTScript();
    }).catch(error => {
    })
  }

  async addCHECKOUTScript() {
    await apiManager.get(cartDetailAPI, { 'headers': { 'pincode': appCookie.get('pincode') } }).then(async (resp) => {
      let cartData = resp.data.data
      if (cartData) {
        if (cartData.cartItems) {




          // Remove existing script if it exists
          const existingScript = document.getElementById('DLFNPMMP1');
          if (existingScript) {
            existingScript.parentNode.removeChild(existingScript);
          }

          console.log('checkoutData in addCHECKOUTScript = ' + JSON.stringify(cartData));
          const ProductId = cartData.cartItems.map(cartItem => cartItem.partNumber).join('|');

          // Create and add new script
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.id = 'DLFNPMMP1';
          script.async = true;
          script.innerHTML = `
    window.data_8240 = [];
    window.data_8240.push({
    'Currency': 'INR',
    'CustomerType': '0',
    'ProductId': '${ProductId}',
    'CouponCode': '${cartData.promotionCode}',
    'gdpr': '${this.state.GDPR}',
    'gdpr_consent': '${this.state.GDPR_CONSENT_874}',
    'gdpr_pd': '${this.state.GDPR_PD}',
    'us_privacy': '${this.state.US_PRIVACY}',
    'OrderId': '${cartData.orderSummary.orderID}',
    'Amount': '${cartData.orderSummary.netAmount}',
    'PageType': 'checkout'
    });
    (function(d) {
        var s = d.createElement('script'); s.async = true;s.id='DLFNPMMP1';s.type='text/javascript';
            s.src = 'https://cdn.preciso.net/p0n6t4c/t2g?8240';
        var a = d.getElementsByTagName('script')[0]; a.parentNode.insertBefore(s, a);
        if(typeof LB8240 !=='undefined'){LB8240.init(window.data_8240[0]);}
      }(document));
    `;
          document.head.appendChild(script);

        }
      }

    }).catch((err) => {
    })

  }


  handleAddress = (address) => {
    this.setState({
      step2SelectedAddress: address,
      billAddId: address.billAddId,
    })
  }

  initialBdpayment = (data) => {
    let {billAddId} = this.state;
    if(this.state.updatedAddressID!=''){
      billAddId=this.state.updatedAddressID
    }
    const body = {
      orderId: this.state.step2SelectedAddress.orderId,
      email: this.state.logon_by.includes('@') ? this.state.logon_by : appCookie.get("registered_by") && appCookie.get("registered_by").includes('@')
      ? appCookie.get("registered_by") : null,
      mobile: !this.state.logon_by.includes('@') ? this.state.logon_by : appCookie.get("registered_by") && !appCookie.get("registered_by").includes('@')
      ? appCookie.get("registered_by") : null,
      payMethodId: this.state.paymentId,
      amount: this.state.noCostEMIDiscount==''?this.state.orderSummaryData.netAmount:this.state.orderSummaryData.netAmount-this.state.noCostEMIDiscount,
      billing_address_id: billAddId,
      // billing_address_id: this.state.step2SelectedAddress.billAddId,
      callbackUrl: `${secureHttp}://${host}:${port2}/api/v1/secure/payment/handlePayment`,
      BankID: this.state.BankID,
      paymentMode: this.state.paymentMode,
      NC_EmiTenures:this.state.NC_EmiTenures,
      ncEmiAcquirer:this.state.ncEmiAcquirer,
      ncEmiItemCode:this.state.ncEmiItemCode,
      beansRequired:(this.state.paymentMode=='NC_EMI'||this.state.paymentMode=='DEBIT_CARD_EMI') && this.state.orderSummaryData.bankOfferDiscount !='' && this.state.orderSummaryData.bankOfferDiscount !=0 && this.state.orderSummaryData.bankOfferDiscount !='0'?'Yes':'No'
    };
    let payCategoryId;
    if (this.state.paymentMode === 'DEBIT_CARD') {
      payCategoryId = 'DEBIT';
    }
    else if (this.state.paymentMode === 'CC_EMI') {
      payCategoryId = 'EMI';
    }
    else if (this.state.paymentMode === 'UPI') {
      payCategoryId = 'UPI';
    }
    else if (this.state.paymentMode === 'NC_EMI') {
      payCategoryId = 'NC_EMI';
    }
    else { // CREDIT_CARD case
      payCategoryId = 'CREDIT';
    }

    const token = appCookie.get("accessToken");
    axios
      .post(CreateCheckSumAPI, body, {
        headers: { store_id: storeId, access_token: token },
      })
      .then(response => {

        $(window).bind("pageshow", (event) => {
          if (event.originalEvent.persisted) {
            // alert("User clicked on back button!");
            window.location.reload();
          }
        });

        const res = response.data.data.response;
        if (this.state.paymentMode === "COD") {
          window.location.assign(
            `${window.location.origin}/check/payment/${
              response.data.data.orderId
            }`
          );
          // /order/confirm/:orderId
          return;
        }

        let url = `${res.transactionUrl}?msg=${
          res.msg
        }&txtPayCategory=${payCategoryId}`;
        if (this.state.paymentMode == "UPI"){
          url = `${res.transactionUrl}&msg=${res.msg}`;
        }
        if (
          this.state.paymentMode == "NET_BANKING" ||
          this.state.paymentMode == "WALLET" ||
          Object.values(WALLETS_MAPPING).includes(this.state.paymentMode)
        ) {
          url = `${res.transactionUrl}&msg=${res.msg}`;
        }
        if(this.state.paymentMode == "NC_EMI"){
          // url = `${res.transactionUrl}&msg=${res.msg}&txtPayCategory=${payCategoryId}`;
          url = `${res.transactionUrl}&msg=${res.msg}`;
          this.setState({
            transactionURL: res.transactionUrl,
            responseText:res.msg,
          });
        }

        console.log(`after initiateBDPayment url = ${url}`);

        if(this.state.paymentMode == "NC_EMI"){
          document.forms[0].submit();
        }else{
        window.location.assign(url);
        }
      })
      .catch(err => {
        if (this.state.paymentMode === "COD") {
          window.location.assign(
            `${window.location.origin}/checkout?status=fail`
          );
        }
        this.setState({
          isCheckSumAPIFail: true,
        });
      });
  }

  

  initiateBOPayment = (data) => {
         let {billAddId} = this.state;
    if(this.state.updatedAddressID!=''){
      billAddId=this.state.updatedAddressID
    }
    const body = {
      orderId: this.state.step2SelectedAddress.orderId,
      email: this.state.logon_by.includes('@') ? this.state.logon_by : appCookie.get("registered_by") && appCookie.get("registered_by").includes('@')
      ? appCookie.get("registered_by") : null,
      mobile: !this.state.logon_by.includes('@') ? this.state.logon_by : appCookie.get("registered_by") && !appCookie.get("registered_by").includes('@')
      ? appCookie.get("registered_by")  : null,
      payMethodId: this.state.paymentId,
      amount: this.state.noCostEMIDiscount==''?this.state.orderSummaryData.netAmount:this.state.orderSummaryData.netAmount-this.state.noCostEMIDiscount,
      billing_address_id: billAddId,
      callbackUrl: verifyBOPayment,
      
      BankID: this.state.BankID,
      paymentMode: this.state.paymentMode,
      NC_EmiTenures:this.state.NC_EmiTenures,
      ncEmiAcquirer:this.state.ncEmiAcquirer,
      ncEmiItemCode:this.state.ncEmiItemCode,
      user_agent:window.navigator.userAgent,
      boMinSpend:Number(this.state.boMinSpend),
      createOrderAPI:this.state.createOrderAPI,
    };
    let payCategoryId;
    if (this.state.paymentMode === 'DEBIT_CARD') {
      payCategoryId = 'DEBIT';
    }
    else if (this.state.paymentMode === 'CC_EMI') {
      payCategoryId = 'EMI';
    }
    else if (this.state.paymentMode === 'UPI') {
      payCategoryId = 'UPI';
    }
    else if (this.state.paymentMode === 'NC_EMI') {
      payCategoryId = 'NC_EMI';
    }
    else { // CREDIT_CARD case
      payCategoryId = 'CREDIT';
    }

    const token = appCookie.get("accessToken");
    console.log("initiateBOPayment body = "+JSON.stringify(body))

    let response="";
    // this.createOrderAPI(response);

    axios
      .post(initiateBOPayment, body, {
        headers: { store_id: storeId, access_token: token },
      })
      .then(response => {
        console.log("initiateBOPayment response = "+JSON.stringify(response))
        this.openPaymentSDK(response);

        const res = response.data.data.response;

      })
      .catch(err => {
        if (this.state.paymentMode === "COD") {
          window.location.assign(
            `${window.location.origin}/checkout?status=fail`
          );
        }
        this.setState({
          isCheckSumAPIFail: true,
        });
      });
  }
  

  hideSpinner() {
		this.refs.spinner.style.display = 'none';
	}

	showSpinner() {
		this.refs.spinner.style.display = 'block';
	}

  openPaymentSDK = (reqData) =>{

      var config = {
        merchantLogo: "",
        // flowConfig: flow_config,
        flowConfig: reqData.data.data.flow_config,
        flowType: "payments"
      };
      this.myRef = React.createRef();
      var xmlhttp = new XMLHttpRequest();
		var jsonObj = "";
		{
			window.loadBillDeskSdk(config);	
		};
      // https://uat.billdesk.com/jssdk/v1/dist/
  }


  enalblePay = (data) => {
    this.setState({
      // pay: true,
      BankID: data.BankID,
      paymentMode: data.paymentMode,
      paymentId: data.paymentId,
      NC_EmiTenures: data.NCEmiTenure,
      ncEmiAcquirer:data.ncEmiAcquirer,
      ncEmiItemCode:data.ncEmiItemCode,
      boMinSpend:data.boMinSpend,
      boCoupon:data.boCoupon,
    })
  }

  setIsProcceedBtnDisabled = (flag) =>{
    this.setState({
      isProcceedBtnDisabled: flag,
    })
  }

  updateNoCostEMIDiscount = (emidiscount) => {
    if(emidiscount!=null && emidiscount!="" && this.state.noCostEMIDiscount!=emidiscount){
      this.setState({
        noCostEMIDiscount: emidiscount,
      })
    }
  }

  setEditAddressEnabled = (flag) =>{
    this.setState({
      editAddressEnabled: flag,
    })
  }
  

  disablePay = () => {
    this.setState({
      pay: false,
    })
  }

  handleStep = () => {
    if (this.state.step == 3) {
      return (
        <div><Suspense fallback = {<></>}> 
          <Step3Component
            back={this.handleChange}
            backtoMobile={this.handleChangeMobile}
            step2SelectedAddress={this.state.step2SelectedAddress}
            logonBy={this.state.logon_by}
            isLoggedIn={this.state.loggedIn}
            initialBdpayment={this.initialBdpayment}
            enalblePay={this.enalblePay}
            disablePay={this.disablePay}
            netAmount={this.state.orderSummaryData.netAmount}
            orderData={this.state.orderSummaryData}
		    orderId={this.state.step2SelectedAddress.orderId}
            pay={this.state.pay}
            updateNoCostEMIDiscount={this.updateNoCostEMIDiscount}
            callOrderSummaryAPI={this.callOrderSummaryAPI}
            promotionCode={this.state.promotionCode}
            noCostEMIDiscount={this.state.noCostEMIDiscount}
            payWithHeading1={this.state.payWithHeading1}
            payWithHeading2={this.state.payWithHeading2}
            NOCostMobDiscText1={this.state.NOCostMobDiscText1}
            NOCostMobDiscText2={this.state.NOCostMobDiscText2}
            BOMobDiscText1={this.state.BOMobDiscText1}
            BOMobDiscText2={this.state.BOMobDiscText2}
            initiateBOPayment={this.initiateBOPayment}
            billAddId={this.state.billAddId}
            updatepaymentLoadingIndicator={this.updatepaymentLoadingIndicator}
          /></Suspense></div>);

    } if (this.state.step == 2) {
      return(
        <div><Suspense fallback = {<></>}> 
          <Step2Component
            proceed={this.handleProceed}
            back={this.handleChange}
            isLoggedIn={this.state.loggedIn}
            logonBy={this.state.logon_by}
            handleAddress={this.handleAddress}
            netAmount={this.state.orderSummaryData.netAmount}
            shipModePro={this.state.shipMode}
            email={this.state.email}
            phone={this.state.phone}
            step2SelectedAddress={this.state.step2SelectedAddress}
            updateAddressID={this.updateAddressID}
            isProcceedBtnDisabled={this.setIsProcceedBtnDisabled}
            editAddressEnabled={this.setEditAddressEnabled}
            proceedToPayment={this.state.proceedToPayment}
            callOrderSummaryAPI={this.callOrderSummaryAPI}
            promotionCode={this.state.promotionCode}
            setproceedToPaymentFalse={this.setproceedToPaymentFalse}
            setproceedToPaymentTrue={this.setproceedToPaymentTrue}
          /> </ Suspense> </div> );

    } 
    if (this.state.step == 1){
    return(
      <div><Suspense fallback = {<></>}> 
        <Step1Component
          proceed={this.handleProceed}
          login={this.handleUserLogin.bind(this)}
          proceedToSecond={this.proceedToSecond}
          logonBy={this.state.logon_by}
          msg={this.state.message}
          netAmount={this.state.orderSummaryData.netAmount} /> </Suspense></div>);
    }
  }

  proceedToSecond = (uid) => {
    let response="";
    this.setState({
      logon_by: uid,
      step: 2,
    })
  }

  cancelFail = () => {
    this.setState({
      failPop: false,
    })
  }

  handleProceed = () => {
    if (this.state.step == 1) {
      this.setState({
        step: 2,
        has_pass: false,
      })
    } else if (this.state.step == 2) {
      this.setState({
        step: 3,
      })
    }
  }

  updateAddressID = (updatedAddressID) => {
    this.setState({
      updatedAddressID,
    })
  }

  handleBack = () => {
    if(this.state.step == 1){
      this.setState({
        redirect: true,
      })
    }
    if (this.state.loggedIn && this.state.step == 2) {
      this.setState({
        redirect: true,
      })
    } else {
      this.setState({
        step: this.state.step - 1,
      })
    }
  }

  setproceedToPaymentFalse = ()=> {
    this.setState({
      proceedToPayment: false,
      LoadingIndicator:false,
    })
  }

  setproceedToPaymentTrue = ()=> {
    this.setState({
      LoadingIndicator:true,
    })
  }
  updatepaymentLoadingIndicator = (flag) => {
    this.setState({
      paymentLoadingIndicator:flag,
    })
  }
  setproceedToPayment = () => {
    // alert('hii');
    let desktopPlaceOrderEvent = new Event("desktopPlaceOrder",{ bubbles: true,});
    desktopPlaceOrderEvent.simulated = true;
    document.dispatchEvent(desktopPlaceOrderEvent);
    // this.setproceedToPaymentTrue();
    // this.setState({
    //   proceedToPayment: true,
    //   // LoadingIndicator:true
    // })
 }
  render() {
    if (this.state.redirect) {
      return <Redirect to='/cart' />;
    }
    if(this.state.step == 2) {
      
      this.state.step2SelectedClassName='step-with-bg-2';
      this.state.step3SelectedClassName='';
      this.state.step2SelectedClassName1='step2-text';
      this.state.step3SelectedClassName1='';
    }
    if(this.state.step == 3) {
      this.state.step2SelectedClassName='step-with-bg-2';
      this.state.step3SelectedClassName='step-with-bg-3';
      this.state.step2SelectedClassName1='step2-text';
      this.state.step3SelectedClassName1='step3-text';

    }
    return (
      <>
      <Helmet>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"></link>


  <script src="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"
    integrity="sha384-aJ21OjlMXNL5UyIl/XNwTMqvzeRMZH2w8c5cRVpzpU8Y5bApTppSuUkhZXN0VxHd" crossorigin="anonymous">
    </script>
    {/* <script type="module" src="https://uat.billdesk.com/jssdk/v1/dist/billdesksdk/billdesksdk.esm.js"></script>
      <script nomodule="" src="https://uat.billdesk.com/jssdk/v1/dist/billdesksdk.js"></script>
      <link href="https://uat.billdesk.com/jssdk/v1/dist/billdesksdk/billdesksdk.css" rel="stylesheet"></link> */}
      <script type="module" src={this.state.script1}></script>
      <script nomodule="" src={this.state.script2}></script>
      <link href={this.state.script3} rel="stylesheet"></link>
      </Helmet>
      <div className='checkout'>
        {isMobile() && <div className='mob-checkout-steps'>
          <a onClick={this.handleBack} className="backBtn">
		  <img src={require('../../../public/images/LeftArrowWhite.svg')} alt='Left'/></a>
          <a href="/" className='mob-header-logo'><MWebLogo width="24" height="24" /></a>
          <h2 className='title'> Checkout (Step {this.state.step}/3) </h2>
        </div>}
        <div className="container">
          <div className='row'>
            {/* <div className='col-md-8'>
              <h3 className='heading'>{CHECKOUT}</h3>
            </div> */}
            {this.state.failPop ? <FailPop cancelFail={this.cancelFail} /> : ''}
            <div className='col-md-4'>
              <div className='summaryHeading'>
              </div>
            </div>
          </div>

          {this.state.checkoutHeading1!='' && this.state.checkoutHeading2!='' && this.state.checkoutHeading3!='' && this.state.checkoutHeading4!=''?
          <div className='checkout-header-container'>
          <div className='checkout-heading'>
            <div className="checkout-heading-text step-with-bg step-with-bg-1">1</div><div className='checkout-heading-text step-text cart-text'><a href={this.state.checkoutHeading1.toLowerCase()}>{this.state.checkoutHeading1}</a></div>
            <div className='checkout-heading-text horizontal-line'></div>
            <div className="checkout-heading-text step-with-bg step-with-bg-1">2</div><div className='checkout-heading-text step-text step1-text'>{this.state.checkoutHeading2}</div>
            <div className='checkout-heading-text horizontal-line'></div>
            <div className={`checkout-heading-text step-with-bg ${this.state.step2SelectedClassName}`}>3</div>
            {this.state.step==1 || this.state.step==2?
            <div className={`checkout-heading-text step-text address-text  ${this.state.step2SelectedClassName1}`}>
            {this.state.checkoutHeading3}
            </div>
            :
            <div  className={`checkout-heading-text step-text address-text  ${this.state.step2SelectedClassName1}`}>
            <a href=''>
            {this.state.checkoutHeading3}
            </a>
            </div>
            }
            <div className='checkout-heading-text horizontal-line'></div>
            <div className={`checkout-heading-text step-with-bg ${this.state.step3SelectedClassName}`}>4</div><div className={`checkout-heading-text step-text ${this.state.step3SelectedClassName1}`}>{this.state.checkoutHeading4}</div>
          </div>
          </div>
          :''}
          <div className="row">
            <div className='col-md-12'>
              <div className='row includeLoadingIndicator'>
              <Form className="NC-EMI-PostForm" name='PostForm' method='POST' 
              action={this.state.transactionURL} 
              >            
              <FormGroup>
              <FormControl
                type="Submit"
                value='Pay By Card'
              />
              <FormControl
                type="hidden"
                name="msg"
                value={this.state.responseText}
              />
              </FormGroup>
              </Form>
                {this.state.LoadingIndicator==true  && this.state.step == 2 ?<LoadingIndicator />:''}
                {this.state.paymentLoadingIndicator==true  && this.state.step == 3 ?<LoadingIndicator />:''}
                {this.handleStep()}
                <OrderSummaryComponent
                  isLoggedIn={this.state.loggedIn}
                  orderData={this.state.orderSummaryData}
                  pay={this.state.pay}
                  initialBdpayment={this.initialBdpayment}
                  isCheckSumAPIFailPro={this.state.isCheckSumAPIFail}
                  checkoutStep={this.state.step}
                  noCostEMIDiscount={this.state.noCostEMIDiscount}
                  promotionCode={this.state.promotionCode}
                  checkoutSection={this.state.checkoutSection}
                  checkoutSectionImg={this.state.checkoutSectionImg}
                  currentStep={this.state.step}
                />
                
                {!isMobile() && this.state.step == 2 && this.state.loggedIn && !this.state.editAddressEnabled ?

                  <div className='col-md-4 col-sm-12 col-xs-12 orderSummary step2-extreamRight'>
                
                    <div className='extreameRightBox'>
                      {/* <div className="row"> */}
                      {/* <div className="col-md-12 form-group"> */}                      
                      <button
                        disabled={this.state.isProcceedBtnDisabled}
                        className="btn-blackbg btn-block continueMargin"
                        // onClick={this.proceedToPayment}
                        onClick={this.setproceedToPayment}
                      >
                          PLACE ORDER
                      </button>
                      {/* </div> */}
                      {/* </div> */}
                    </div>
                
                  </div>

                  :''}


              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}
