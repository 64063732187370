import React from "react";
import Slider from "react-slick";
import apiManager from "../../utils/apiManager";
import "../../../public/styles/slider.scss";

import VideoModel from "../../components/Primitives/videoModel";
import "../../../public/styles/static-pages/supportBanner.scss";

import { espotAPI, imagePrefix } from "../../../public/constants/constants";

class ServiceSupportBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      espotName: "GI_SUPPORT_BANNER",
      supportSlider: null,
      isLoading: false,
      error: null,
      title: "",
      description: "",
      type: "",
    };
    this.isScrolling = false;
    this.onVideoClick = this.onVideoClick.bind(this);
    this.onVideoClose = this.onVideoClose.bind(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);

    this.videoModel = React.createRef();
    this.supportSlider = React.createRef();
  }

  getSupportBannerData() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        const { data } = response || {};
        this.setState({
          supportSlider: data && data.data.bannerList,
          title: data && data.data.title,
          description: data && data.data.desc,
          type: data && data.data.type,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.getSupportBannerData();
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("touchstart", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("touchstart", this.handleClickOutside);
  }

  onVideoClick(url) {
    this.setState({
      showVideo: true,
      videoUrl: url,
    });
    this.videoModel.current.showModal(url);
  }

  onVideoClose() {
    this.setState({
      showVideo: false,
      videoUrl: undefined,
    });
  }

  handleClickOutside(event) {
    if (this.state.showVideo) {
      this.onVideoClose();
    }
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOnClick(e) {
    window.scrollTo(0, 0);
  }

  render() {
    const { supportSlider } = this.state;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };
    return (
      <div className="fsBanner supportBanner">
        <Slider {...settings} className="videoBanner">
          {!!supportSlider &&
            supportSlider.map((sliderData, index) => (
              <>
                {sliderData.type === "image" && (
                  <a key={index}>
                    <a
                      onClick={e => this.handleOnClick(e)}
                      onMouseDown={e => this.handleOnClick(e)}
                      href={`${sliderData.onClickUrl}`}
                    >
                      <img
                        src={`${imagePrefix}${sliderData.imageSrc}`}
                        className="img-fluid"
                        alt={sliderData.alt}
                      />
                    </a>
                  </a>
                )}
                {sliderData.type === "video" && (
                  <>
                    <div className="video-container">
                      <img
                        // src={`${sliderData.imageSrc}`}

                        src={`/imagestore${sliderData.img1530x510}`}
                        // src={`${imagePrefix}${sliderData.imageSrc}`}
                        className="image video-img suppPlayerDesktop"
                        alt={sliderData.alt}
                      />
                      <img
                        // src={`${sliderData.imageSrc}`}

                        src={`/imagestore${sliderData.img360x468}`}
                        // src={`${imagePrefix}${sliderData.imageSrc}`}
                        className="image video-img suppPlayerMobile"
                        alt={sliderData.alt}
                      />
                      <div className="overlay">
                        <div className="align-play">
                          <img
                            src={imagePrefix + sliderData.icon}
                            // src={`/imagestore${sliderData.icon}`}
                            className="media-play-video"
                            onClick={() => this.onVideoClick(sliderData.video)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
        </Slider>

        <VideoModel ref={this.videoModel} />
      </div>
    );
  }
}

export default ServiceSupportBanner;
