import React from 'react';
import '../../../public/styles/compWidget.scss';
import Row from 'react-bootstrap/lib/Row'
import Col from 'react-bootstrap/lib/Col'
import {
  imagePrefix,
} from '../../../public/constants/constants';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DelContainer from './delivery'
import { WIDTH, HEIGHT, DEPTH, DELIVERY, DIMENSIONS, SPECIFICATIONS, PAYMENT } from '../../constants/app/compareConstants'
// import productItem from '../GlobalComponents/productItem/productItem';
import { formatPrice, isMobile } from '../../utils/utilityManager';

class CompPrd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: true,
      nav1: null,
        nav2: null,
        nav3: null,
    }
  }


  
  removeSwatchIndex = (id, index) => {
    this.props.removeSwatchIndex(id, index);
  }

  componentDidMount(){
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      nav3: this.slider3,
  });
  }


  handleSwatch = (id, index, name) => {
    this.props.swatchHandle(id, index, name);
  }

  renderDims = () => {
    const dims = [];
    const width = [];
    const heights = [];
    const Depth = [];
    this.props.data.map((elem, index) => {
      width.push(
        <Col className={index == 0 ? "col-sm-6 col-md-6 attr-dims" : index == 1 ? 'col-sm-4 col-md-4 attr-desc' : "col-sm-2 col-md-2 attr-desc"}>
          {index == 0 ? <div><span className="col-md-5">{WIDTH}</span> <span className="col-md-7">{elem.width}</span></div> : <p>{elem.width}</p>}
        </Col>)
      heights.push(
        <Col className={index == 0 ? "col-sm-6 col-md-6 attr-dims" : index == 1 ? 'col-sm-4 col-md-4 attr-desc' : "col-sm-2 col-md-2 attr-desc"}>
          {index == 0 ? <div><span className="col-md-5">{HEIGHT}</span> <span className="col-md-7">{elem.height}</span></div> : <p>{elem.height}</p>}
        </Col>)
      Depth.push(
        <Col className={index == 0 ? "col-sm-6 col-md-6 attr-dims" : index == 1 ? 'col-sm-4 col-md-4 attr-desc' : "col-sm-2 col-md-2 attr-desc"}>
          {index == 0 ? <div><span className="col-md-5">{DEPTH}</span> <span className="col-md-7">{elem.depth}</span></div> : <p>{elem.depth}</p>}
        </Col>)
    })

    dims.push(<Row>{width}</Row>)
    dims.push(<Row>{heights}</Row>)
    dims.push(<Row>{Depth}</Row>)

    return dims;
  }

  renderImages = () => {
    const images = [];
    this.props.data.map(elem => {
      images.push(
        <Col xs={12} sm={4} md={4} className="comp-list-item">
          <div className="img-box">
            <img src={`${imagePrefix}${elem.dimensionThumbnail}`} alt='img' />
          </div>
        </Col>)
    })
    return images;
  }

  renderPayment = () => {
    const payments = [];
    const emis = [];
    this.props.data.map(elem => {

      payments.push(
        <Col xs={12} sm={4} md={4} className='comp-cod-option text-center'>
          <h4>{elem.emiData !== '' && elem.emiData !== null && elem.emiData !== undefined && elem.emiData !== 0 ? 'EMI available' : 'EMI not available'}</h4>
        </Col>
      )
    });
    return payments;

  }

  renderSpecs = () => {
    const specs = [];
    if (this.props.data === undefined || this.props.data === null || this.props.data === '' || this.props.data.length === 0) {
      return <></>
    }
    this.props.data[0].attributes.map((att, index) => {
      if (this.props.data.length > 1) {
        var second_att = this.props.data[1].attributes.find(s_att => s_att.uniqueID == att.uniqueID)
      }
      if (this.props.data.length > 2) {
        var third_att = this.props.data[2].attributes.find(t_att => t_att.uniqueID == att.uniqueID)
      }
      specs.push(
        <Row className='specifec-detail'>
          <Col xs={12} sm={4} md={6} className='attr-details'>
            <span className="col-sm-5 col-md-5">{att.name}</span> <span className="col-sm-7 col-md-7">{att.value}</span>
          </Col>

          <Col xs={12} sm={4} md={4} className='attr-desc'>
            {this.props.data.length > 1 ? second_att ? <p>{second_att.value}</p> : 'NA' : ''}
          </Col>
          <Col xs={12} sm={4} md={2} className='attr-desc'>
            {this.props.data.length > 2 ? third_att ? <p>{third_att.value}</p> : 'NA' : ''}
          </Col>
        </Row>
      )
    });
    return specs;
  }

  renderDelivery = () => {
    const dels = [];
    this.props.data.map(elem => { 
      dels.push(<DelContainer skuData={elem} />)
    })
    return dels;
  }

  

  render() {
    console.log(this.props)
    const settings = {
      dots: false,
      lazyLoad: true,
      infinite: false,
      speed: 500,
      slidesToShow: isMobile()? 2.1:3,
      slidesToScroll: 1,
      initialSlide: 0,
      prevArrow: false,
      nextArrow: false,
      autoplay: false,
      arrows: false,
      swipeToSlide: true,
      afterChange: (index)=> {
        this.slider1.slickGoTo(index)  
    this.slider2.slickGoTo(index) 
    this.slider3.slickGoTo(index) 
      }
    };
    console.log(this.props)
    return (
      <div className="compare-product-list">

        <Slider {...settings} asNavFor={this.state.nav2}
       ref={slider => (this.slider1 = slider)}>
          {!!this.props.data && this.props.data.map((prod,key)=>{
            return(
            <div className='comp-item-container'>
              <div className='comp-item-wrapper'>
                <img src = {imagePrefix + prod.thumbnail}></img>
                <div className='comp-item-details'>
                  <div className='prod-name'>
                    {prod.productName}
                  </div>
                  <div className='prod-shortdesc'>
                   {'('}{prod.shortDescription}{')'}
                  </div>
                  <div className='prod-offerprice'>
                  ₹{formatPrice(prod.offerPrice)}<span className='prod-actualprice'>
                   <del>₹{formatPrice(prod.actualPrice)}</del>
                   
                  </span>
                  </div>
                  {isMobile()?<></>:<div className='you-save'>you save ₹{formatPrice(parseInt(prod.actualPrice) - parseInt(prod.offerPrice))}<span className='prod-discount'>{'('+prod.discount}% OFF{')'}</span></div>}

                  {isMobile()?<></>:
                  <div className='swatch-container'>
                    {prod.swatches.map((swatch,index)=>{
                      return(<div>{swatch.colorCode!=''?<div className='swatch-color' style={{backgroundColor:`rgb${swatch.colorCode}`}}></div>:swatch.swatchImage!=''?<img className='swatch-color' src={imagePrefix+swatch.swatchImage}></img>:<></>}</div>)
                    })}
                  </div>}
                </div>
              </div>
            </div>)
          })}
        </Slider>
        <div className='comp-dimensions-container'>
          <div className='comp-dimensions-header'>{DIMENSIONS}</div>
          <div className='inner-headings'>
                  <div className='inner-dimensions-heading'>Width</div>
                  <div className='inner-dimensions-heading'>Height</div>
                  <div className='inner-dimensions-heading'>Depth</div>
                  </div>
        <Slider {...settings}  asNavFor={this.state.nav3}
       ref={slider => (this.slider2 = slider)}>
          {
              this.props.data.map((elem, index) => {
                return(
                <div className='comp-dimension-data' >
                  <div className='inner-dimensions'>{elem.width}</div>
                  <div className='inner-dimensions'>{elem.height}</div>
                  <div className='inner-dimensions'>{elem.depth}</div>
                  
                </div>
                )
              })
          }
        </Slider>
        </div>

        <div className='comp-dimensions-container'>
          <div className='comp-dimensions-header'>{SPECIFICATIONS}</div>
          <div className='inner-headings'>
                  <div className='inner-dimensions-heading'>Color</div>
                  <div className='inner-dimensions-heading'>Material</div>
                  </div>
        <Slider {...settings} asNavFor={this.state.nav1}
       ref={slider => (this.slider3 = slider)}>
          {
              this.props.data.map((elem, index) => {
                return(
                  <div className='comp-dimension-data' >
                    {
                      elem.attributes.map((attr,key)=>{
                         if(attr.name == 'Finish Color')
                         {
                          return(<div className='inner-dimensions'>{attr.value}</div>)
                         }   
                      })
                    }
                    {
                      elem.attributes.map((attr,key)=>{
                         if(attr.name == 'Primary Material')
                         {
                          return(<div className='inner-dimensions'>{attr.value}</div>)
                         }   
                      })
                    }
                </div>
                )
              })
          }
        </Slider>
        </div>
    
      </div>
    );
  }
}

export default CompPrd;
