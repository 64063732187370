import React from "react";
import RoomCards from "./RoomCards";
import  "./RoomCards.scss";
import {imagePrefix} from '../../../public/constants/constants';
const path = "/B2C/EspotImages/Images/rcb/RapidRooms/cardsimg/";


export default function RoomCardsPannel() {
  return (
    <div><h1 className="heading"> Why Microsoft Teams? </h1> 
      <div className="panel-body ">
        <div className="card1">
          <RoomCards
            title="Inclusive"
            img= {`${imagePrefix + path  }1.png`}
            description = "Foster inclusive and interactive meetings for everyone, regardless of where they are."
          />
        </div>
        <div className="card2">
          <RoomCards
            title="Easy to use for all:"
            img= {`${imagePrefix + path  }2.png`}
            description = "Deliver consistent Teams experiences designed to make collaboration easy and hassle-free"
          />
        </div>
        <div className="card-break"></div>
        <div className="card3">
          <RoomCards
            title="Flexible: "
            img= {`${imagePrefix + path  }3.png`}
            description = "Turn any shared space into a Teams room with a wide selection of devices to fit every need."
          />
        </div>
        <div className="card4">
          <RoomCards
            title="Secured & Managed: "
            img= {`${imagePrefix + path  }4.png`}
            description = "Keep devices secured, reliable and up to date with rich management capabilities and powerful analytics."
          />
        </div>
      </div>
    </div>
  );
}
