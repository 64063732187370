import React from 'react';
import { Link } from 'react-router-dom';
import apiManager from '../../../utils/apiManager';
import { getCookie } from '../../../utils/utilityManager';
import {
  navigationApi,
  userDetailAPI,
  headerStaticData,espotAPI,imagePrefix,
  storeConfiguration
} from '../../../../public/constants/constants';
import { logoutTheUser } from '../../../utils/initialManager';
import UserAccInfo from '../../../components/UserAccInfo/userAccInfo';
import '../../../../public/styles/RWDStyle/sideNavigation.scss';
import MobileDealerModal from '../../../components/StaticPages/MobileDealerModal';
import connect from 'react-redux/src/connect/connect'
import injectSaga from '../../../utils/injectSaga';
import injectReducer from '../../../utils/injectReducer';
import reducer from "../../PlpContainer/reducer";
import saga from "../../PlpContainer/saga";
import compose from 'redux/src/compose'
import * as actionCreators from "../../PlpContainer/actions";
import {createCategoryPlpURL,  getReleventReduxState } from '../../../utils/utilityManager';
import appCookie from '../../../utils/cookie';
import { NavForSEO } from '../../../components/Category/navigationConstants';

export class HeaderMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: null,
      isLoading: true,
      showNav: false,
      navigationItem: null,
      userName: 'Hello',
      logonId: null,
      showLoginPopUp: false,
      layer1Data: [],
      selectedL2: "",
      espotName: "GI_PDP_ProductConfigurator_Categories",
      displayProductConfiguratorCategoriesMobile:'',
      productConfiguratorCategoryName:'',
      productConfiguratordescription:'',
      productConfiguratorCategories:[],
      productonfiguratorImage:'',
    };
  }

  toggleDropdown(eleId, subCat, catName) {
    let val = eleId;
    if (this.state.selectedL2 === eleId) {
      val = "";
    }
    this.setState(
      {
        selectedL2: val,
      },
      () => {
        this.onCategoryClick(subCat, catName);
      }
    );
  }

  componentDidMount() {
    document.addEventListener('registerPopupEVentMobile', event=>{
      if (getCookie('isLoggedIn') === 'false' || getCookie('isLoggedIn') === '') {
        this.setState({
          showLoginPopUp: true
        },()=>{
          let registerPopupEVent = new Event("registerPopupEVent",{ bubbles: true,});
          registerPopupEVent.simulated = true;
          document.dispatchEvent(registerPopupEVent);
        })
      }
      
    })

    document.addEventListener('loginPopupEVent', event=>{
      if (getCookie('isLoggedIn') === 'false' || getCookie('isLoggedIn') === '') {
        console.log('Side Nav')
        this.setState({
          showLoginPopUp: true
        })
      }
    })

    this.getCategoryData();
    this.getHeaderLayer1();
    if (getCookie('isLoggedIn') === 'true') {
      this.getUserDetails();
    }
    this.getProductConfiguratorEspotData();
  }



  getProductConfiguratorEspotData() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        const {data} = response || {};
        this.setState({
          displayProductConfiguratorCategoriesMobile: data && data.data.displayProductConfiguratorCategoriesMobile,
          productConfiguratorCategoryName: data && data.data.productConfiguratorCategoryName,
          productConfiguratordescription: data && data.data.productConfiguratordescription,
          productConfiguratorCategories: data && data.data.productConfiguratorCategories,
          productonfiguratorImage: data && data.data.productonfiguratorImage,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }


  componentWillReceiveProps(nextProps) {
  }

  getHeaderLayer1() {
    apiManager
      .get(headerStaticData)
      .then(response => {
        const { data } = response || {};
        this.setState({
          layer1Data: data && data.data,
          // isLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  getUserDetails() {
    apiManager
      .get(userDetailAPI, {
        headers: { profile: 'summary' },
      })
      .then(response => {
        document.cookie = `name=${response.data.data.name};path=/;expires=''`;
        document.cookie = `loginID=${response.data.data.logonID};path=/;expires=''`;
        if (response.data.data.pincode && response.data.data.pincode !== '') {
          appCookie.set('pincode', response.data.data.pincode, 365 * 24 * 60 * 60 * 1000);
        }
        this.setState({
          userName: `${this.state.userName} ${(response.data.data.name !== undefined && response.data.data.name !== '') ? response.data.data.name.split(' ')[0] : ''}`,
          logonId: response.data.data.logonID,
        });

        this.showLoginStatus();
        this.props.updateUserProfile(response.data.data.name);
      })
      .catch(error => {
        // return null;
      });
  }

  getCategoryData() {
    this.setState({
      // category: navigation.data && navigation.data.categoryArray,
      isLoading: false,
    });
    apiManager
      .get(navigationApi)
      .then(response => {
        const { data } = response || {};
        this.setState({
          category: data && data.data.categoryArray,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  onMenuClick() {
    if(document.getElementById('mob-header').className == 'mobileHeader'){
      document.getElementById('mob-header').classList.add("active-nav");
    }

    this.setState({
      showNav: true,
    });
  }

  handleClose(){
    this.setState({ show: false });
  }

  onProductConfiguratorClick(){
    this.setState({
      navigationItem: (
            <div className="searchBackBtn searchBackBtn-productConfigurator">
            <div className="topMenu">
              <label
                onClick={this.onNavigationBackCick.bind(this)}
                className="usernameTxt catNavHeader catNavHeader-productConfigurator"
              >
                <img
                  className="back-nav"
                  src={require('../../../../public/images/nav_back.svg')}
                  alt='prevImg'
                />
                {this.state.productConfiguratorCategoryName}
              </label>
            </div>


            {this.state.productConfiguratorCategories.map((confData, index) => (
            <ul className='listItemsProductConfigurator'>
              {confData.mainCategoryurl!=''?
              <a class="toggleNavItems open" href={confData.mainCategoryurl}>
              <li class="navText navText-header"><b>{confData.mainCategoryText}</b> </li>
              </a>:
              <li class="navText navText-header"><b>{confData.mainCategoryText}</b> </li>
              }
              <ul>
              {confData.subcategories.map((subCat, index) => (
                <>
                <a class="toggleNavItems open" href={subCat.link}>
                  <li class="navText">{subCat.displayText}</li>
                </a>
                </>
              ))}
              </ul>
            </ul>
            ))}
            <div className="productConfiguratordescriptiondiv">
              {/* <span className="productConfiguratordescription">{this.state.productConfiguratordescription}</span> */}
              <img
                        src={imagePrefix+this.state.productonfiguratorImage}
                        alt='nxtImg'
                      />
            </div>
            </div>
            ),
    });
  }

  onCategoryClick(subCat, catName) {
    if (subCat.length !== 0) {
      this.setState({
        navigationItem: (
          <div className="searchBackBtn">
            <div className="topMenu">
              <label
                onClick={this.onNavigationBackCick.bind(this)}
                className="usernameTxt catNavHeader"
              >
                <img
                  className="back-nav"
                  src={require('../../../../public/images/nav_back.svg')}
                  alt='prevImg'
                />
                {` ${catName}`}
              </label>
            </div>
            <ul>
              {!!subCat &&
                subCat.map((subCatData, index) => {
                  let routePath;
                  const subcatName = String(
                    subCatData.categoryName
                  ).toLowerCase();
                  if (catName.toLowerCase().indexOf('rooms') > -1 || subcatName.toLowerCase() == "modular wardrobes") {
                    routePath = `/online-furniture-${
                      subCatData.categoryIdentifier.split(' ').join('').toLowerCase()
                    }`;
                  } else {
                    routePath = createCategoryPlpURL(subCatData.categoryIdentifier);
                  }

                  return (
                    <>
                      {catName.toLowerCase() == "products" ? (
                        <>
                          {!subCatData.subCategoryArray ? (
                            <>
                              <Link
                                to={{
                                  pathname: routePath,
                                  state: { categoryId: subCatData.uniqueID },
                                }}
                                className="nav-link dropdown-toggle toggleNav"
                                onClick={this.onSubCatClick.bind(this)}
                              >
                                <li className="navTxt ">
                                  {/* =l2 */}
                                  <div className="navTextl2">
                                    <b>{subCatData.categoryName}</b>
                                  </div>
                                </li>
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link
                                // to={{
                                //   pathname: routePath,
                                //   state: { categoryId: subCatData.uniqueID }
                                // }}
                                to="#"
                                className="nav-link dropdown-toggle toggleNav"
                                // onClick={this.onSubCatClick.bind(this)}
                              >
                                <li
                                  onClick={() =>
                                    this.toggleDropdown(
                                      subCatData.categoryName,
                                      subCat,
                                      catName
                                    )
                                  }
                                  className={
                                    this.state.selectedL2 ===
                                    subCatData.categoryName
                                      ? "navTxt open"
                                      : "navTxt "
                                  }
                                >
                                  {/* =l2 */}
                                  <div className="navTextl2">
                                    <b>{subCatData.categoryName}</b>
                                  </div>
                                  {!subCatData.subCategoryArray ? (
                                    <></>
                                  ) : (
                                    <div
                                      className={
                                        this.state.selectedL2 ===
                                        subCatData.categoryName
                                          ? "signArrow arrowOpen"
                                          : "signArrow arrowClosed"
                                      }
                                    />
                                  )}
                                </li>
                              </Link>
                              <ul>
                                {subCatData &&
                                  !!subCatData.subCategoryArray &&
                                  subCatData.subCategoryArray.map(
                                    (l3category, index) => (
                                    // console.log("hereee");
                                    // console.log(
                                    //   "hereeee1: " +
                                    //     JSON.stringify(l3category, null, 2)
                                    // );
                                    NavForSEO[`${l3category.categoryIdentifier}`]==undefined?
                                      
                                        <>
                                          <Link
                                            to={{
                                              pathname: (routePath = createCategoryPlpURL(
                                                l3category.categoryIdentifier
                                              )),
                                              state: {
                                                categoryId: subCatData.uniqueID,
                                              },
                                            }}
                                            class={
                                              this.state.selectedL2 ===
                                              subCatData.categoryName
                                                ? "toggleNavItems open"
                                                : "toggleNavItems"
                                            }
                                            onClick={this.onSubCatClick.bind(
                                              this
                                            )}
                                          >
                                            {/* l3 */}
                                            
                                            
                                            <li className="navTextl3">
                                              <b> {l3category.categoryName}</b>{" "}
                                            </li>
                                          </Link>
                                          <ul>
                                            {l3category &&
                                              !!l3category.subCategoryArray &&
                                              l3category.subCategoryArray.map(
                                                (l4Category, index) => (
                                                  NavForSEO[`${l4Category.categoryIdentifier}`]==undefined?
                                                  <Link
                                                    class={
                                                      this.state
                                                        .selectedL2 ===
                                                        subCatData.categoryName
                                                        ? "toggleNavItems open"
                                                        : "toggleNavItems"
                                                    }
                                                    to={{
                                                      pathname: createCategoryPlpURL(
                                                        l4Category.categoryIdentifier
                                                      ),
                                                      state: {
                                                        categoryId:
                                                            subCatData.uniqueID,
                                                      },
                                                    }}
                                                    onClick={this.onSubCatClick.bind(
                                                      this
                                                    )}
                                                  >
                                                    <li className="navTextl4">
                                                      {
                                                        l4Category.categoryName
                                                      }
                                                    </li>
                                                    {/* l4 */}
                                                  </Link>:''
                                                )
                                              )}
                                          </ul>
                                        </>
                                      :'')
                                    
                                  )}
                              </ul>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {catName.toLowerCase() == "offers" ? <>
                            {!subCatData.subCategoryArray ? (
                              <>
                                <Link
                                  to={{
                                    pathname: routePath,
                                    state: { categoryId: subCatData.uniqueID },
                                  }}
                                  className="nav-link dropdown-toggle toggleNav"
                                  onClick={this.onSubCatClick.bind(this)}
                                >
                                  <li className="navTxt ">
                                    {/* =l2 */}
                                    <div className="navTextl2">
                                      {subCatData.categoryName}
                                    </div>
                                  </li>
                                </Link>
                                {/* {index+1 == subCat.length ?
                                <a
                                  href='http://godrejinterio.rewardzpromo.com/'
                                  className="nav-link dropdown-toggle toggleNav"
                                >
                                  <li class="navTxt ">
                                    =l2
                                    <div class="navTextl2">
                                      Cashback for Customers*
                                    </div>
                                  </li>
                                </a>
                                :
                                <></>} */}
                              </>
                            ) : (
                              <>
                                <Link
                                  to={{
                                    pathname: routePath,
                                    state: { categoryId: subCatData.uniqueID },
                                  }}
                                  // to="#"
                                  className="nav-link dropdown-toggle toggleNav"
                                  onClick={this.onSubCatClick.bind(this)}
                                >
                                  <li
                                  // onClick={() =>
                                  //   this.toggleDropdown(
                                  //     subCatData.categoryName,
                                  //     subCat,
                                  //     catName
                                  //   )
                                  // }
                                    className={
                                      this.state.selectedL2 ===
                                    subCatData.categoryName
                                        ? "navTxt open"
                                        : "navTxt "
                                    }
                                  >
                                    {/* =l2 */}
                                    <div className="navTextl2">
                                      {subCatData.categoryName}
                                    </div>
                                  </li>
                                </Link>
                              </>
                            )}</>: <>
                            {!subCatData.subCategoryArray ? (
                              <>
                                <Link
                                  to={{
                                    pathname: routePath,
                                    state: { categoryId: subCatData.uniqueID },
                                  }}
                                  className="nav-link dropdown-toggle toggleNav"
                                  onClick={this.onSubCatClick.bind(this)}
                                >
                                  <li className="navTxt ">
                                    {/* =l2 */}
                                    <div className="navTextl2">
                                      {subCatData.categoryName}
                                    </div>
                                  </li>
                                </Link>
                              </>
                            ) : (
                              <>
                                <Link
                                  to={{
                                    pathname: routePath,
                                    state: { categoryId: subCatData.uniqueID },
                                  }}
                                  // to="#"
                                  className="nav-link dropdown-toggle toggleNav"
                                  onClick={this.onSubCatClick.bind(this)}
                                >
                                  <li
                                  // onClick={() =>
                                  //   this.toggleDropdown(
                                  //     subCatData.categoryName,
                                  //     subCat,
                                  //     catName
                                  //   )
                                  // }
                                    className={
                                      this.state.selectedL2 ===
                                    subCatData.categoryName
                                        ? "navTxt open"
                                        : "navTxt "
                                    }
                                  >
                                    {/* =l2 */}
                                    <div className="navTextl2">
                                      {subCatData.categoryName}
                                    </div>
                                  </li>
                                </Link>
                              </>
                            )}</>}
                         
                        </>
                      )}
                    </>
                  );
                })}
            </ul>
          </div>
        ),
      });
    }
  }

  onNavigationBackCick() {
    this.setState({
      navigationItem: null,
    });
  }

  onLinkNavigation(pageText) {
    this.props.pageNavigationRenderPro(pageText);
    this.setState({
      ishowNav: false,
    });
  }

  onSubCatClick() {
    this.props.plpReduxStateReset();
    this.onOverlayClick();
  }

  onOverlayClick() {
    this.onNavigationBackCick();
    if(document.getElementById('mob-header').className == 'mobileHeader active-nav'){
      document.getElementById('mob-header').classList.remove("active-nav");
    }
    this.setState({
      showNav: false,
    });
    // this.props.pageNavigationRenderPro('My Profile');
  }

  onSubcategoryClick() { }

  onSignOutClick() {
    logoutTheUser();
  }

  onSignInClick() {
    this.setState({
      showNav: false,
      showLoginPopUp: true,
    });
  }

  onMyAccountClick() {
    this.setState({
      navigationItem: (
        <div className="searchBackBtn">
          <div className="topMenu">
            <label
              onClick={this.onNavigationBackCick.bind(this)}
              className="usernameTxt catNavHeader"
            >
              <img
                className="back-nav"
                src={require('../../../../public/images/nav_back.svg')}
                alt='prevImg'
              />
              {` My Account`}
            </label>
          </div>
          {/* onClick={this.updatePincode.bind(this, this.props)} */}
          <ul>
            <Link
              to={{ pathname: '/myAccount', state: { from: 'myprofile' } }}
              onClick={() => this.onLinkNavigation('My Profile')}
            >
              <li className="navTxt">My Profile</li>
            </Link>
            { !this.props.isOTPEnabled && 
            <Link
              to={{ pathname: '/myAccount', state: { from: 'password' } }}
              onClick={() => this.onLinkNavigation('Change Password')}
            >
              <li onClick={this.onOverlayClick.bind(this)} className="navTxt">
                Change Password
              </li>
            </Link>
            }
            <Link
              to={{ pathname: '/myAccount', state: { from: 'serviceRequest' } }}
              onClick={() => this.onLinkNavigation('My Service Request')}
            >
              <li className="navTxt">My Service Request</li>
            </Link>
            <Link
              to={{ pathname: '/myAccount', state: { from: 'myorder' } }}
              onClick={() => this.onLinkNavigation('My Orders')}
            >
              <li className="navTxt">My Orders</li>
            </Link>
            <Link
              to={{ pathname: '/myAccount', state: { from: 'address' } }}
              onClick={() => this.onLinkNavigation('Manage Address')}
            >
              <li className="navTxt">Manage Address</li>
            </Link>
            <Link to={{ pathname: '/support' }} onClick={() => this.onLinkNavigation('Customer Care')}>
              <li className="navTxt">Customer Care</li>
            </Link>
          </ul>
        </div>
      ),
    });
  }

  dealermodal(){
    this.setState({
      show:true,
    })
  }

  resetLoginValues() {
    this.setState({
      showLoginPopUp: false,
    });
  }

  render() {
    const { category = [], showNav, layer1Data } = this.state;
    let loginLogoutItem;
    let myAccountItem = null;
    if (getCookie('isLoggedIn') === 'true') {
      loginLogoutItem = (
        <button onClick={this.onSignOutClick} className="signoutBtn">
          Sign Out
        </button>
      );
      myAccountItem = (
        <li onClick={this.onMyAccountClick.bind(this)} className="navTxt">
          My Account
          <span className="arrow">
            <img src={require('../../../../public/images/nav_next.svg')} alt='nxtImg'/>
          </span>
        </li>
      );
    } else {
      loginLogoutItem = (
        <button onClick={this.onSignInClick.bind(this)} className="loginBtn">
          Log In/ Register
        </button>
      );
    }

    let navItem;
    if (this.state.navigationItem === null) {
      navItem = (
        <>
          <div className="leftAnim">
            <div className="topMenu">
              <label className={getCookie('isLoggedIn') === 'true' ? 'usernameTxt userInfo' : 'usernameTxt'}>{getCookie('isLoggedIn') === 'true' ?this.state.userName :'Hello!'}</label>
              {loginLogoutItem}
            </div>
            <ul>
              {!!category &&
                category.map((categoryData, index) => (
                  <>
                    <li
                      onClick={categoryData.categoryIdentifier != 'sets' ? () =>
                        this.onCategoryClick(
                          categoryData.subCategoryArray,
                          categoryData.categoryName,
                        ): ()=>{window.location.href = '/furniture-sets'}
                      }
                      className="navTxt"
                    >
                      {categoryData.categoryName.toLowerCase() === 'rooms' || categoryData.categoryName.toLowerCase() === 'products' ? (`SHOP BY ${categoryData.categoryName.toUpperCase()}`):(categoryData.categoryName.toUpperCase())}
                      {categoryData.subCategoryArray.length > 0 && categoryData.categoryIdentifier != 'sets' ? (
                        <span className="arrow">
                          <img
                            src={require('../../../../public/images/nav_next.svg')}
                            alt='nxtImg'
                          />
                        </span>
                      ) : null}
                    </li>
                    {categoryData.categoryName == "Rooms" ? (
                      <>
                         <a className="action" href="/home-interiors">
                          <li className="navTxt">HOME INTERIORS</li>{" "}
                        </a> 
                        <a className="action" target="_blank" href="/kitchens">
                          <li className="navTxt">KITCHENS</li>{" "}
                        </a>
                        { this.state.displayProductConfiguratorCategoriesMobile=="Yes" ?
                      <li className="navTxt" 
                        onClick={() =>
                          this.onProductConfiguratorClick(
                          )
                        }
                      >
                        {this.state.productConfiguratorCategoryName}
                        <span className="arrow">
                          <img
                            src={require('../../../../public/images/nav_next.svg')}
                            alt='nxtImg'
                          />
                        </span>
                      </li>
                    :''}


                       
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}                
              {myAccountItem}
             { layer1Data.services &&(
                // layer1Data.services.action.includes('https')?
                <a className='action' target="_blank" href={ layer1Data.services.action}><li className="navTxt"> {layer1Data.services.title}</li></a>
                // : <></>
              )}

{ layer1Data.blogs &&(
                // layer1Data.services.action.includes('https')?
                <a className='action' target="_blank" href={ layer1Data.blogs.action}><li className="navTxt"> {layer1Data.blogs.title}</li></a>
                // : <></>
              )}

              {layer1Data.additionalheader ?
                layer1Data.additionalheader.action.includes('www')?<a className='action' target="_blank" href={ layer1Data.additionalheader.action}><li className="navTxt"> {layer1Data.additionalheader.title}</li></a>:<Link className='link' to={ layer1Data.additionalheader.action}>
                  <li>{layer1Data.additionalheader.title}</li> 
                </Link> : <></>
              }

                 { layer1Data.locate_stores &&(
                layer1Data.locate_stores.action.includes('https')?
                <a className='action' target="_blank" href={ layer1Data.locate_stores.action}><li className="navTxt"> {layer1Data.locate_stores.title.toUpperCase()}</li></a>
                : <></>
              )}

              {
                getCookie('isLoggedIn') === 'true' ? (
                  layer1Data.track_order && (
                    <Link
                      className="action"
                      onClick={this.onOverlayClick.bind(this)}
                      to={{pathname:layer1Data.track_order.my_account_action, 
                        state:{from:layer1Data.track_order.track_order_myorder}}}>
                      < li className="navTxt" >{layer1Data.track_order.title.toUpperCase()}</li>
                    </Link>                              
                  )
                ) :(
                  layer1Data.track_order && (
                    <Link
                      className="action" 
                      onClick={this.onOverlayClick.bind(this)} 
                      to={layer1Data.track_order.action}>
                      < li className="navTxt" >{layer1Data.track_order.title.toUpperCase()}</li>
                    </Link>                              
                  )
                )
              }

              {layer1Data.support && (
                <Link
                  className="action" 
                  onClick={this.onOverlayClick.bind(this)} 
                  to={layer1Data.support.action}>
                  < li className="navTxt" >{layer1Data.support.title.toUpperCase()}</li>
                </Link>                              
              )}
              {layer1Data.dealer && layer1Data.dealer.title_rwd && layer1Data.dealer.action_rwd && layer1Data.dealer.action_rwd != '' ? (
                <a className='action' href={layer1Data.dealer.action}>
                  <button className="action-dealer">
                    {layer1Data.dealer.title_rwd.toUpperCase()}</button>
                </a>
              )
                : layer1Data.dealer && layer1Data.dealer.title_rwd ?
                  (
                    <a className='action'>
                      <button className="action-dealer" onClick={this.dealermodal.bind(this)}>
                        {layer1Data.dealer.title_rwd.toUpperCase()}</button>
                    </a>
                  )
                  : ''
              }
              {
                this.state.show == true ? <MobileDealerModal show={this.state.show} handleModelClose = {this.handleClose.bind(this)}  /> :<></>
              }

            </ul>
          </div>
          <div className="personal-view d-block d-xl-none">
            {layer1Data.business && (
              <a
                onClick={() => window.location.assign(layer1Data.business.action)}
                className="stretched-link"
                href="javascript:void(0);"
                title="godrej-interio"
              >
                <img
                  src={require('../../../../public/images/data-arrow.svg')}
                  className="img-fluid"
                  alt="godrej-interio"
                />
                 <span className='business-dataText'>{layer1Data.business.title_switch}</span>
              
              </a>
            )}
          </div>
        </>
      );
    } else {
      navItem = this.state.navigationItem;
    }

    return (
      <>
        {this.state.showLoginPopUp ? <UserAccInfo fromWishlistPro resetCallbackPro={this.resetLoginValues.bind(this)} /> : null}
        <div className="sideNavigation">
          <label>
            <input type="checkbox" checked={showNav ? 'checked' : ''} />
            <div onClick={this.onMenuClick.bind(this)} className="handler">
              <img
                className="nav-bar-img"
                src={require('../../../../public/images/rwd-assets/menu.svg')}
                alt="menu"
              />
            </div>
            <div onClick={this.onOverlayClick.bind(this)} className="overlay" />
            <nav>
              <div className="topMenuOverlap" />
              {navItem}
            </nav>
          </label>
        </div>
      </>
    );
  }
}

// export default HeaderMobile;
/* ----------------------------------------   REDUX HANDLERS   -------------------------------------  */
const mapDispatchToProps = dispatch => ({
  plpReduxStateReset: () => dispatch(actionCreators.resetPLPReduxState()),
});

const mapStateToProps = state => {
  const stateObj = getReleventReduxState(state, 'header');
  const isOtpEnabled = getReleventReduxState(stateObj, 'isOtpEnabled');
  return {
    isOTPEnabled: isOtpEnabled,
  }
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'plpContainer', reducer });
const withSaga = injectSaga({ key: 'plpContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(HeaderMobile);
