import React from 'react';
const InstallLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...props}>
    <title>Install</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-12-13h55v55h-55z"/>
      <path stroke="#000" strokeWidth=".933" d="M27.454 7.712l-.692-.69-.346-.347-.691-.692-.078-1.753 3.124-3.125a5.943 5.943 0 0 0-6.228 1.381 5.941 5.941 0 0 0-1.361 6.287L8.626 21.33a5.94 5.94 0 0 0-6.286 1.36 5.942 5.942 0 0 0-1.38 6.228l3.124-3.123 1.754.079.69.69.347.347.69.69.079 1.754-3.124 3.123a5.942 5.942 0 0 0 6.229-1.38 5.94 5.94 0 0 0 1.359-6.285l12.556-12.556c2.114.789 4.587.34 6.287-1.362a5.942 5.942 0 0 0 1.38-6.227L29.208 7.79l-1.753-.078zM20.852 12.586l-8.414 8.413"/>
      <path fill="#000" d="M23.14 10.298a.631.631 0 1 1-.894.893.631.631 0 0 1 .893-.893M11.246 22.192a.631.631 0 1 1-.894.893.631.631 0 0 1 .894-.893"/>
      <path stroke="#000" strokeWidth=".933" d="M14.146 15.741l-8.73-8.73-2.07.144L.842 2.44l.824-.825.286-.286.484-.484 4.717 2.504-.144 2.068 8.73 8.73zM20.164 16.826L31.567 27.55a2.945 2.945 0 1 1-4.165 4.165L16.681 20.31M17.344 23.359l5.87-5.868M22.931 23.078l5.685 5.683"/>
    </g>
  </svg>
)

export default InstallLogo;