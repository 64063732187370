import React from "react";
import { formatPrice } from "../../../utils/utilityManager";
import { getCookie } from '../../../utils/utilityManager';
import { orderCreate,orderCreated,
  centralWarehouse,centralWarehousePast,
  localWarehouse,localWarehousePast, shipped, deliver, itemInstal,shipingInprogress,

} from "./constants";
class OrderStatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingStatusArr: [],
      isInCurrenStatus: false,
      itemStatusArray :[],
      customClassCheck: '',   

      createdOrder : true,

      transit : false,
      intransitCW : false,
      intransitLW : false,
      intransitCWLW : false,  
      
      itemTransitShip : false,
      itemShipped : false,
      
      itemDelivered : false,
      itemShipDel : false,
      itemInstalled : false,
    };
  }

  componentDidMount() {
    this.filterShippingData();
  }

  filterShippingData() {
    if (this.props.shipmentDataPro) {
      const statusArr = this.props.shipmentDataPro.statusLine.split(",");
      this.setState({
        shippingStatusArr: statusArr,
      });
    }
  }

  getStatusBarCustomClassname(item, index) {
    let customClassName = "list items5";
       
    if(index == 1 || index == 2){
      customClassName += " margin-fix";
    }

    if (this.state.shippingStatusArr.length == 3) {
      customClassName = "list items3";
    }else if(this.state.shippingStatusArr.length < 5) {
      customClassName = "list items4";
    }

    if(this.state.transit == false && index >1){
      customClassName +=" disable-dot";
    }else if(this.state.transit == true && index >2 && this.state.itemTransitShip == false){
      customClassName +=" disable-dot";
    }else if(this.state.transit == true && index >3 && this.state.itemTransitShip == true && this.state.itemShipDel == false){
      customClassName +=" disable-dot";
    }else if(this.state.transit == true && index >4 && this.state.itemTransitShip == true && this.state.itemShipDel == true && this.state.itemDelivered == false){
      customClassName +=" disable-dot";
    }

    if (this.state.shippingStatusArr.length  === 2) {
      customClassName = "list items6";
    }
    
    if (index === 0) {
      customClassName += " first";
    }

    if (index + 1 === this.state.shippingStatusArr.length) {
      {getCookie('isLoggedIn') === 'true' ? customClassName += " last last-loggedIn":
        customClassName += " last";}
    }
    let x;
    if(this.props.shipmentDataPro.status === 'Material Intransit To Central Warehouse' 
      || this.props.shipmentDataPro.status === 'Material Intransit To Local Warehouse'){
      x = 'Intransit';
    }else{
      x=this.props.shipmentDataPro.status;
    }
    if (item === x) {
      this.state.isInCurrenStatus = true;
      customClassName += " active"; // Green -- Current status
      // customClassName += " visited"; // Green -- Current status
    } else if (this.state.isInCurrenStatus) {
      customClassName += ""; // Grey -- Status not reached here
    } else {
      {getCookie('isLoggedIn') === 'true' ? customClassName += " visited-loggedIn visited":
        customClassName += " visited";} // Previous status crossed
    }
    this.state.customClassCheck = customClassName; 

    return customClassName;
    // +customClassNameDisable;
  }

  getStatusBarDate(item) {
    let statusDate;
    if (item === "Created") {
      statusDate = this.props.shipmentDataPro.createdDate;
    }else if (item === "Packed") {
      statusDate = this.props.shipmentDataPro.packedDate;
    } else if (item === "Shipped" || item === "Shipping") {
      statusDate = this.props.shipmentDataPro.shippedDate;
    } else if (item === "Delivered" || item === "Delivery") {
      statusDate = this.props.shipmentDataPro.deliveryDate;
    } else if (item === "Installed" || item === "Installation") {
      statusDate = this.props.shipmentDataPro.installationCompleteDate;
    }
    // ----------for rso condition start
    else if (item === "Return Initiated") {
      statusDate = this.props.shipmentDataPro.returnedOn;
    } else if (item === "Item Picked Up") {
      statusDate = this.props.shipmentDataPro.itemPickedDate;
    } else if (item === "Refund Initiated") {
      statusDate = this.props.shipmentDataPro.refundInitiatedDate;
    } else if (item === "Refund Processed") {
      statusDate = this.props.shipmentDataPro.refundProcessedDate;
    }
    // ----------for Service Request condition start
    else if (item === "Request Created") {
      statusDate = this.props.shipmentDataPro.serviceRequestCreatedDate;
    } else if (item === "Service Booked") {
      statusDate = this.props.shipmentDataPro.serviceBookedDate;
    } else if (item === "Serviced") {
      statusDate = this.props.shipmentDataPro.servicedDate;
    }
    // ----------for Cancelation
    else if (item === 'Ordered') {
      statusDate = this.props.shipmentDataPro.createdDate;
    } else if (item === 'Cancelled') {
      statusDate = this.props.shipmentDataPro.statusDate;
    }
    return statusDate ? statusDate.split(",")[1] : null;
  }

  renderTechnicianDetailsForServiceRequest() {
    return (
      <div className="contactDetails">
        {/* <p className="heading">Contact Details</p> */}
        {/* <p className="mobiletext">
          {this.props.shipmentDataPro.technicianName}
        </p> */}
        <p className="mobiletext">
          Technician Assigned
          {/* {this.props.shipmentDataPro.technicianAssignedDate} */}
        </p>
      </div>
    );
  }

  renderRefundDetails() {
    return (
      <div className="refundDetails">
        <p className="mobiletext">
          {`Amount: ₹${this.props.prodctDataPro &&
            this.props.prodctDataPro.cancelRefundAmount !== null &&
            formatPrice(this.props.prodctDataPro.cancelRefundAmount)}`}
        </p>
        <span className="spanTxt">
          Please check order details for more information
        </span>
      </div>
    );
  }

  // className={
  //   this.state.wishlistData.length === 0
  //     ? 'container wishlist product-listing pt-4 pt-lg-0 mb-0 product-details'
  //     : 'wishlist product-listing pt-4 pt-lg-0 mb-0 product-details'
  // }
  render() {
    let refundTimeline = '';
    let cancelled = '';
    const statusBarItem = this.state.shippingStatusArr.map((item, index) => {
      if (item === "Refund Details") {
        refundTimeline = " trackorder-wrap-3";
      }
      if (this.state.shippingStatusArr.length  === 2) {
        cancelled = " trackorder-wrap-2";
      }
      // Check if new code and delete - It is not in use
      const arrSS = [];      
      arrSS.push(this.props.shipmentDataPro.status);
      // Check if new code and delete - It is not in use
      
      let dotSpacer ='';
      if(getCookie('isLoggedIn') === 'true'){
        dotSpacer ='dot-spacer-loggedIn'
      }else{
        dotSpacer ='dot-spacer'
      }

      const trans ="";
      let ship ="";
      let del ="";
      let instal ="";
      
      if(this.props.shipmentDataPro.status =='Created'){
        ship ="disable-ship";
        del="disable-del";
        instal="disable-installation";
      }
      if(this.props.shipmentDataPro.status =='Material Intransit To Central Warehouse' || this.props.shipmentDataPro.status =='Material Intransit To Central Warehouse'){
        del="disable-del";
        instal="disable-installation";
      }
      if(this.props.shipmentDataPro.status =='Shipped'){        
        this.state.itemTransitShip = true;
        this.state.itemShipped = true;        
        this.state.transit= true;
      }
      if(this.props.shipmentDataPro.status =='Delivered'){    
        this.state.itemTransitShip = true;
        this.state.itemShipDel = true;    
        this.state.itemDelivered = true;
        instal="disable-installation";
        this.state.transit= true;
      }
      if(this.props.shipmentDataPro.status =='Installed'){    
        this.state.itemTransitShip = true;
        this.state.itemShipDel = true;    
        this.state.itemDelivered = true;
        this.state.itemInstalled = true;
        this.state.transit= true;
      }
      
      if(!!(this.props.prodctDataPro.centralWHDate) && this.props.prodctDataPro.branchWHDate === ''){
        this.state.intransitCW = true; 
        this.state.transit= true;
      }else if(!!(this.props.prodctDataPro.branchWHDate) && this.props.prodctDataPro.centralWHDate === ''){
        this.state.intransitLW = true;
        this.state.transit= true;          
      }else if(!!(this.props.prodctDataPro.centralWHDate) && !!(this.props.prodctDataPro.centralWHDate)){
        this.state.intransitCWLW = true;
        this.state.transit= true;
      }
      return (
        
        <li className={this.getStatusBarCustomClassname(item, index)}>
          {this.state.customClassCheck.endsWith('visited') ? 
            <>
              {getCookie('isLoggedIn') === 'true' ? 
                <div className="check-main-visited check-main-visited-loggedIn"><div className="check-track"></div></div>
                :<div className="check-main-visited"> <div className="check-track"></div></div>}
            </>:null
          }
          {this.state.customClassCheck.endsWith('active') ? 
            <>
              {getCookie('isLoggedIn') === 'true' ? <div className="check-main-active check-main-active-loggedIn"><div className="check-track"></div></div>
                :<div className="check-main-active"><div className="check-track"></div></div>  }
            </>:null
          }
          
          {getCookie('isLoggedIn') === 'true' ? 
            item ==='Created' ? <div className="status-header-loggedIn">Order Confirmed</div>
              :item ==='Shipped' ? <div className="status-header-loggedIn">Out for delivery</div>
                :item ==='Delivered' ? <div className={`status-header-loggedIn ${del}`}>Delivered</div>
                  :item ==='Installed' ? <div className="status-header-loggedIn">Installed</div>
                    :item ==='Intransit' ? <div className="status-header-loggedIn">In transit</div>
                      :<div className="status-header-loggedIn">{item}</div>:

            item ==='Created' ? <div className="status-header ">Order Confirmed</div>
              :item ==='Intransit' ? <div className="status-header ">In transit</div>
                :item ==='Shipped' ? <div className="status-header ">Out for delivery</div>
                  :item ==='Delivered' ? <div className="status-header ">Delivered</div>
                    :item ==='Installed' ? <div className="status-header ">Installed</div>
          
                      :<div className="status-header">{item}</div>
          }

          {/* Created */}
          {this.state.createdOrder == true && item=='Created' && this.state.intransitLW == false && this.state.intransitCW == false && this.state.intransitCWLW == false ? 
            <>
              {getCookie('isLoggedIn') === 'true' ? 
                <div className="inTransitBar">
                  <ul className="bar bar-loggedIn">         
                    <li className="black">
                      <h1 className={`status status-loggedIn ${dotSpacer}`}>{orderCreate}</h1> 
                      <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.shipmentDataPro.createdDate}</div>
                    </li>
                  </ul>
                </div>
                :<div className="inTransitBar">
                  <ul className="bar">                
                    <li className="black">
                      <h1 className={`status ${dotSpacer}`}>{orderCreate}</h1> 
                      <div className={`deliveryDate ${dotSpacer}`}>{this.props.shipmentDataPro.createdDate}</div>
                    </li>
                  </ul>
                </div>}              
            </>
            :null}
          {this.state.createdOrder == true && item=='Created' && (this.state.intransitLW == true || this.state.intransitCW == true || this.state.intransitCWLW == true) ? 
            <>
              {getCookie('isLoggedIn') === 'true' ?
                <div className="inTransitBar">
                  <ul className="bar bar-loggedIn">                
                    <li className="black">
                      <h1 className={`status status-loggedIn black ${dotSpacer}`}>{orderCreated}</h1> 
                      <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.shipmentDataPro.createdDate}</div>
                    </li>
                  </ul>
                </div>:
                <div className="inTransitBar">
                  <ul className="bar">                
                    <li className="black">
                      <h1 className={`status black ${dotSpacer}`}>{orderCreated}</h1> 
                      <div className={`deliveryDate ${dotSpacer}`}>{this.props.shipmentDataPro.createdDate}</div>
                    </li>
                  </ul>
                </div>}
            </>
            :null}
          {/* Intransit */}    
          {/* Central WH */}
          {this.state.intransitCW == true && this.state.intransitLW == false && item=='Intransit' && this.state.itemTransitShip==false ?
            <>
              {getCookie('isLoggedIn') === 'true' ?
                <div className="inTransitBar">
                  <ul className="bar bar-loggedIn">
                    <li className="black">
                      <h1 className={`status status-loggedIn black ${dotSpacer}`}>{centralWarehouse}</h1>
                      <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.prodctDataPro.centralWHDate}</div>                
                    </li>
                  </ul>
                </div>
                :
                <div className="inTransitBar">
                  <ul className="bar">
                    <li className="black">
                      <h1 className={`status black ${dotSpacer}`}>{centralWarehouse}</h1>
                      <div className={`deliveryDate ${dotSpacer}`}>{this.props.prodctDataPro.centralWHDate}</div>                
                    </li>
                  </ul>
                </div>
              }
            </>
            :null}

          {this.state.intransitCW == true && this.state.intransitLW == false && item=='Intransit' && this.state.itemTransitShip==true ?
            <>
              {getCookie('isLoggedIn') === 'true' ?
                <div className="inTransitBar">
                  <ul className="bar bar-loggedIn">
                    <li className="black">
                      <h1 className={`status status-loggedIn black ${dotSpacer}`}>{centralWarehousePast}</h1>
                      <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.prodctDataPro.centralWHDate}</div>
                    </li>
                  </ul>
                </div>
                :
                <div className="inTransitBar">
                  <ul className="bar">
                    <li className="black">
                      <h1 className={`status black ${dotSpacer}`}>{centralWarehousePast}</h1>
                      <div className={`deliveryDate ${dotSpacer}`}>{this.props.prodctDataPro.centralWHDate}</div>
                    </li>
                  </ul>
                </div>
              }
            </>
            :null}

          {/* Local WH */}
          {this.state.intransitLW == true && this.state.intransitCW == false && item=='Intransit' && this.state.itemTransitShip==false ? 
            <>
              {getCookie('isLoggedIn') === 'true' ?
                <div className="inTransitBar">
                  <ul className="bar bar-loggedIn">
                    <li className="black">
                      <h1 className={`status status-loggedIn black ${ dotSpacer}`}>{localWarehouse}</h1>
                      <div className={`deliveryDate deliveryDate-loggedIn ${ dotSpacer}`}>{this.props.prodctDataPro.branchWHDate}</div>
                    </li>
                  </ul>
                </div>
                :
                <div className="inTransitBar">
                  <ul className="bar">
                    <li className="black">
                      <h1 className={`status black ${ dotSpacer}`}>{localWarehouse}</h1>
                      <div className={`deliveryDate ${ dotSpacer}`}>{this.props.prodctDataPro.branchWHDate}</div>
                    </li>
                  </ul>
                </div>
              }
            </>
            :null}

          {this.state.intransitLW == true && this.state.intransitCW == false && item=='Intransit' && this.state.itemTransitShip==true ?
            <>
              {getCookie('isLoggedIn') === 'true' ?
                <div className="inTransitBar">
                  <ul className="bar bar-loggedIn">
                    <li className="black">
                      <h1 className={`status status-loggedIn black ${dotSpacer}`}>{localWarehousePast}</h1> 
                      <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.prodctDataPro.branchWHDate}</div>
                    </li>
                  </ul>
                </div>
                :
                <div className="inTransitBar">
                  <ul className="bar">
                    <li className="black">
                      <h1 className={`status black ${dotSpacer}`}>{localWarehousePast}</h1> 
                      <div className={`deliveryDate ${dotSpacer}`}>{this.props.prodctDataPro.branchWHDate}</div>
                    </li>
                  </ul>
                </div>
              }
            </>
            :null}
          
          {/* Local and Central WH */}
          {this.state.intransitLW == false && this.state.intransitCW == false && this.state.intransitCWLW == true && item=='Intransit' && this.state.itemTransitShip==false ? 
            <>              
              {getCookie('isLoggedIn') === 'true' ?
                <div className="inTransitBar">
                  <ul className="bar bar-loggedIn">
                    {/* {getCookie('isLoggedIn') === 'true' ? <div className="bar-vertical-line bar-vertical-line-loggedIn"></div> :<div className="bar-vertical-line"></div>} */}
                    {/* {getCookie('isLoggedIn') === 'true' ?  :<div className="bar-vertical-line"></div>} */}
                    <div className="bar-vertical-line bar-vertical-line-loggedIn intransit-cw-lw"></div>
                    <li className="black">
                      <h1 className={`status status-loggedIn black ${dotSpacer}`}>{centralWarehouse}</h1>
                      <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.prodctDataPro.centralWHDate}</div>
                    </li>
                    <li className="second black">
                      <h1 className={`status status-loggedIn black ${dotSpacer}`}>{localWarehouse}</h1>
                      <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.prodctDataPro.branchWHDate}</div>
                    </li>
                  </ul>
                </div>
                :<div className="inTransitBar">
                  <ul className="bar">
                    {/* {getCookie('isLoggedIn') === 'true' ? <div className="bar-vertical-line bar-vertical-line-loggedIn"></div> :<div className="bar-vertical-line"></div>} */}
                    {/* {getCookie('isLoggedIn') === 'true' ? <div className="bar-vertical-line bar-vertical-line-loggedIn"></div> :} */}
                    <div className="bar-vertical-line"></div>
                    <li className="black">
                      <h1 className={`status black ${dotSpacer}`}>{centralWarehouse}</h1>
                      <div className={`deliveryDate ${dotSpacer}`}>{this.props.prodctDataPro.centralWHDate}</div>
                    </li>
                    <li className="second black">
                      <h1 className={`status black ${dotSpacer}`}>{localWarehouse}</h1>
                      <div className={`deliveryDate ${dotSpacer}`}>{this.props.prodctDataPro.branchWHDate}</div>
                    </li>
                  </ul>
                </div>}
            </> 
            :this.state.intransitLW == false && this.state.intransitCW == false && this.state.intransitCWLW == true && item=='Intransit' && this.state.itemTransitShip==true?
              <>              
                {getCookie('isLoggedIn') === 'true' ?
                  <div className="inTransitBar">
                    <ul className="bar bar-loggedIn">
                      {/* <div className="bar-vertical-line"></div> */}
																					
                      {getCookie('isLoggedIn') === 'true' ? <div className="bar-vertical-line bar-vertical-line-loggedIn"></div> :<div className="bar-vertical-line"></div>}
                      <li className="black">
                        <h1 className={`status status-loggedIn black ${dotSpacer}`}>{centralWarehousePast}</h1>
                        <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.prodctDataPro.centralWHDate}</div>
                      </li>
                      <li className="second black second-loggedIn">
                        <h1 className={`status status-loggedIn black ${dotSpacer}`}>{localWarehousePast}</h1>
                        <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.prodctDataPro.branchWHDate}</div>
                      </li>
                    </ul>
                  </div>
                  :<div className="inTransitBar">
                    <ul className="bar">
                      {/* <div className="bar-vertical-line"></div> */}
                      {getCookie('isLoggedIn') === 'true' ? <div className="bar-vertical-line bar-vertical-line-loggedIn"></div> :<div className="bar-vertical-line"></div>}
                      <li className="black">
                        <h1 className={`status black ${dotSpacer}`}>{centralWarehousePast}</h1>
                        <div className={`deliveryDate ${dotSpacer}`}>{this.props.prodctDataPro.centralWHDate}</div>
                      </li>
                      <li className="second black">
                        <h1 className={`status black ${dotSpacer}`}>{localWarehousePast}</h1>
                        <div className={`deliveryDate ${dotSpacer}`}>{this.props.prodctDataPro.branchWHDate}</div>
                      </li>
                    </ul>
                  </div>}
              </>
              :null}

          {/* Shipped */}
          {this.state.itemShipped == true && this.state.itemDelivered == false && item=='Shipped' ?
            <>
              {getCookie('isLoggedIn') === 'true' ?
                <div className="bar-mobile inTransitBar">
                  <ul className="bar-dot bar bar-loggedIn">
                    <li className="blue-dot black">
                      <h1 className={`status status-loggedIn black ${dotSpacer}`}>{shipingInprogress}</h1>
                      <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.shipmentDataPro.shippedDate}</div>
                    </li>
                  </ul>
                </div> 
                :
                <div className="bar-mobile inTransitBar">
                  <ul className="bar-dot bar">
                    <li className="blue-dot black">
                      <h1 className={`status black ${dotSpacer}`}>{shipingInprogress}</h1>
                      <div className={`deliveryDate ${dotSpacer}`}>{this.props.shipmentDataPro.shippedDate}</div>
                    </li>
                  </ul>
                </div>
              }
            </>
            : this.state.itemShipDel == true && this.state.itemDelivered == true && item=='Shipped' ?
              <>
                {getCookie('isLoggedIn') === 'true' ?
                  <div className="inTransitBar">
                    <ul className="bar bar-loggedIn">                
                      <li className="black">
                        <h1 className={`status status-loggedIn black ${dotSpacer}`}>{shipped}</h1> 
                        <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.shipmentDataPro.shippedDate}</div>
                      </li>
                    </ul>
                  </div>
                  :<div className="inTransitBar">
                    <ul className="bar">                
                      <li className="black">
                        <h1 className={`status black ${dotSpacer}`}>{shipped}</h1> 
                        <div className={`deliveryDate ${dotSpacer}`}>{this.props.shipmentDataPro.shippedDate}</div>
                      </li>
                    </ul>
                  </div>}
              </>
              :null}

          {this.state.itemDelivered == true && item=='Delivered'?
            <>
              {getCookie('isLoggedIn') === 'true' ?
                <div className="bar-mobile bar-delivered inTransitBar">
                  <ul className="bar-dot bar bar-loggedIn">
                    <div className="bar-vertical-line-delivered bar-vertical-line-delivered-loggedIn"></div>
                    <li className="blue-dot black">                    
                      <h1 className={`status status-loggedIn black ${dotSpacer}`}>{deliver}</h1> 
                      <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.shipmentDataPro.deliveryDate}</div>
                    </li>
                  </ul>
                </div>
                :<div className="bar-mobile bar-delivered inTransitBar">
                  <ul className="bar-dot bar">
                    <div className="bar-vertical-line-delivered"></div>
                    <li className="blue-dot black">                    
                      <h1 className={`status black ${dotSpacer}`}>{deliver}</h1> 
                      <div className={`deliveryDate ${dotSpacer}`}>{this.props.shipmentDataPro.deliveryDate}</div>
                    </li>
                  </ul>
                </div>}
            </>
            :null}
          
          {this.state.itemInstalled == true && item=='Installed' ?
            <>
              {getCookie('isLoggedIn') === 'true' ?
                <div className="bar-mobile inTransitBar">
                  <ul className="bar-dot bar bar-loggedIn">
                    <div className="bar-vertical-line-installed bar-vertical-line-installed-loggedIn"></div>
                    <li className="blue-dot black">
                      <h1 className={`status status-loggedIn black ${dotSpacer}`}>{itemInstal}</h1>
                      <h1 className={`status status-loggedIn black status-line ${dotSpacer}`}>Thank You!</h1>
                      <div className={`deliveryDate deliveryDate-loggedIn ${dotSpacer}`}>{this.props.shipmentDataPro.installationCompleteDateTrackBar}</div>
                    </li>
                  </ul>
                </div>
                :<div className="bar-mobile inTransitBar">
                  <ul className="bar-dot bar">               
                    {getCookie('isLoggedIn') === 'true' ? <div className="bar-vertical-line-installed bar-vertical-line-installed-loggedIn"></div>
                      :<div className="bar-vertical-line-installed"></div>}                
                    <li className="blue-dot black">
                      <h1 className={`status black ${dotSpacer}`}>{itemInstal}</h1>
                      <h1 className={`status black status-line ${dotSpacer}`}>Thank You!</h1>
                      <div className={`deliveryDate ${dotSpacer}`}>{this.props.shipmentDataPro.installationCompleteDateTrackBar}</div>
                    </li>
                  </ul>
                </div>}
            </>
            :null}
                  
          {/* <div className="deliveryDate">{this.getStatusBarDate(item)}</div> */}
          {item === "Refund Details" ? this.renderRefundDetails() : null}
          {item === "Service Booked" &&
          this.props.shipmentDataPro.displayTechnicianDetails === "Y"
            ? this.renderTechnicianDetailsForServiceRequest()
            : null}
          {(item === "Delivered" || item === "Undelivered") &&
            this.props.shipmentDataPro.returnShipmentOrders &&
            this.props.shipmentDataPro.returnShipmentOrders.length > 0 && (
            <div
              className="returnDetails"
              onClick={() => {
                this.props.onRSODetail(
                  this.props.shipmentDataPro.returnShipmentOrders
                );
              }}
            >
                CHECK RETURN DETAILS
            </div>
          )}
        </li>
      );
    });

    return (
      <><p className="rwd-solid"></p>
        <div className={this.state.shippingStatusArr.length  === 2 ? `${this.props.customClassPro + refundTimeline  } trackorder-wrap-2` : this.props.customClassPro + refundTimeline }>
          <ul className="track-bar">
            {(this.state.isInCurrenStatus = false)}
            {statusBarItem}

		  {/* <li class="list visited first"><div className="status">Ordered</div><div className="deliveryDate">21 June 2018</div></li>
          <li class="list previous visited  "><div className="status">Packed</div><div className="deliveryDate">21 June 2018</div></li>
          <li class="list active"> <div className="status"> Shipping</div><div className="deliveryDate">21 June 2018</div></li>
          <li class="list "> <div className="status">Delivery</div><div className="deliveryDate">21 June 2018</div></li>
          <li class="list last"><div className="status">Installation</div><div className="deliveryDate">21 June 2018</div></li> */}
          </ul>
        </div> </>
    );
  }
}

export default OrderStatusBar;
