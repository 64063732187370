import React from 'react';
// Redux Imports
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import reducer from '../../containers/PlpContainer/reducer';
import saga from '../../containers/PlpContainer/saga';
import * as actionCreators from '../../containers/PlpContainer/actions';
import { createCategoryPlpURL,createTopCategoryPlpURL, getReleventReduxState, formateSearchKeyword } from '../../utils/utilityManager';
import { SUGGESTIONS } from '../../constants/app/primitivesConstants';
import SearchLogo from '../SVGs/search';

import apiManager from '../../utils/apiManager';
import {
  autoSuggestAPI,
} from '../../../public/constants/constants';
import '../../../public/styles/headerContainer/search.scss';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: [],
      categorySearchData: [],
      searchValue: null,
	  searchResultClick: false,
    };
    this.handleClick = this.handleChange.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.showButton = this.showButton.bind(this);
    this.searchResultClick = false;
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleChange = (event) => {
	  this.setWrapperRef = this.setWrapperRef.bind(this);
    const searchText = event.target.value;
    if (event.target.value === '') {
      const crossbtn = document.getElementById('clearField');
      if (crossbtn) crossbtn.style.display = 'none';
    }
    this.setState({
      searchData: [],
      searchValue: null,
    });

    if (searchText.length > 1) {
      if (searchText) {
        apiManager
          .get(autoSuggestAPI + searchText)
          .then((response) => {
            document.addEventListener(navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0 ?'touchstart' : 'click', this.handleOutsideClick, false);
            searchStr =
              this.setState({
                searchData: response.data.data.suggestionView[0].entry ? response.data.data.suggestionView[0].entry : [],				
                categorySearchData: response.data.data.categorySuggestionView ? response.data.data.categorySuggestionView : [],
              });
          })
          .catch(error => {
          });
      } else {
        document.removeEventListener(navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0 ?'touchstart' : 'click', this.handleOutsideClick, false);
      }
    }
  };

  handleOutsideClick(e) {
    if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) { // Safari browser
      document.getElementById("searchInput").blur();
    }
    	
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.target.className != 'iconSearch active') {
      this.onSearchClick();
      this.setState({
        searchData: [],
      });
      return;
    }
    if (event.target.className === 'iconSearch active') {
      if (document.getElementById('searchInput').value.trim() !== '') {
        const text = document.getElementById('searchInput').value;
        if (text !== '') {
          this.props.history.push({ pathname: `${prefixRoute}/search`, search: `keyword=${text}` });
          this.props.plpReduxStateReset();

          this.setState({
            searchData: [],
            searchResultClick: true,
          });
          this.searchResultClick = true;
          setTimeout(() => {
            this.onSearchClick();
          }, 200);
        }
      }
    }
	
	
	
	
  }


  onSearchClick() {
    this.setState({
      isSearchActive: !this.state.isSearchActive,
      showlogin: false,
    });
    this.props.onSearchClick();
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
    document.addEventListener('touchstart', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
    document.removeEventListener('touchstart', this.handleOutsideClick);
  }

  componentDidMount() {
    const wage = document.getElementById('searchInput');
    wage.addEventListener('keydown', e => {
      if (e.key === 'Enter') {
        this.onSearchResultClick(e);
      }
    });
  }
 

  onSearchResultClick(e) {
    const text = formateSearchKeyword(e.target.value.trim(), true);
    if (text !== '') {
      this.props.history.push({ pathname: '/search', search: `keyword=${text}` });
      this.props.plpReduxStateReset();
      
      this.setState({
        searchData: [],
      });
      this.searchResultClick = true;
    }

  }

  onLinkNavigation = (e) => {
    // if (e.target.getAttribute('name') !== '') {
    // 	document.getElementById("searchInput").value = e.target.getAttribute('name');
    // }
    this.props.plpReduxStateReset();
    this.setState({
      searchData: [],
    });
    this.onSearchClick();
    window.scrollTo(0, 0);
  }

  clearFields(e) {
    document.getElementById("searchInput").value = '';
    const crossbtn = document.getElementById('clearField');
    crossbtn.style.display = 'none'
    document.getElementById("searchInput").focus();
    this.setState({
      searchData: [],
    });
  }

  showButton(e) {
    if (this.searchResultClick === false) {
      const crossbtn = document.getElementById('clearField');
      crossbtn.style.display = 'block'
    } else {
      this.searchResultClick = false;
    }
  }

  renderCategorySuggestions() {
    
    if (this.state.categorySearchData.length !== 0) {
      const catSuggestionItem = this.state.categorySearchData.map((item, index) => {
        const searchItem = document.getElementById("searchInput").value;
        if(searchItem.includes('\\')){
          return 
        }
        let categoryRoutePath = createCategoryPlpURL(item.categoryIdentifier);
        if(item.categoryName === item.parentRoom)
        {
          categoryRoutePath = createTopCategoryPlpURL(item.categoryIdentifier);
        }
        let searchStr = item.categoryName;
        if(searchStr !== item.parentRoom){
          searchStr += ` in ${item.parentRoom}`;
        } 
        const filterStr = searchStr.replace(new RegExp(searchItem, 'gi'), str => str.bold());
        if (index < 4) {
          return (
            <li className="list" key={index}>
              <Link name={searchStr} className="link" onClick={this.onLinkNavigation} to={categoryRoutePath} dangerouslySetInnerHTML={{ __html: filterStr}}>
              </Link>
            </li>
          );
        }
      })

      return catSuggestionItem;
    }
    
    
    
  }

  render() {
    const {searchData} = this.state;
    return (
      <div className="search-section d-block" ref={this.setWrapperRef}>
        <div className="d-flex search-spacing searchBar">
          <SearchLogo isActive={this.props.isSearchActive}  />
          <input
            className="form-control search-input"
            // className='searchInput'
            id="searchInput"
            autoFocus
            onPaste={this.showButton}
            onKeyPress={this.showButton}
            onChange={this.handleChange}
            // onClick={this.handleChange}
            type="text"
            autoComplete="off"
            placeholder="What are you looking for ?"
            value={this.state.searchValue !== null ? this.state.searchValue : undefined}
          />
          {/* <ImageSearch /> */}
          {/* <div id="search-cancel" className="d-none search-close d-block">
            <i class="icon icon22 icon-close-grey c-pointer search-icon-close" />
          </div> */}

          {searchData.length > 0 &&
          <div id='autoSuggestDiv' ref={node => { this.node = node; }}>
            <ul className='auto-search'>
              <li className='list'><a className='link' href='#'><strong>{SUGGESTIONS}</strong></a></li>
              {searchData.map((item, index) => {
                const searchItem = document.getElementById("searchInput").value;
                let categoryRoutePath;
                const searchStr = item.term;
                if (index < 6) {
                  return (
                    <li onClick={() => this.state.searchValue = searchStr} className="list" key={index}>
                      <Link name={searchStr} className="link" onClick={this.onLinkNavigation} to={{ pathname: '/search', search: `keyword=${searchStr}` }} >
                        <strong >{searchStr.substr(0, searchItem.length)}</strong>{searchStr.substr(searchItem.length).replace(' ', '')}
                      </Link>
                    </li>
                  );
                }
              })
              }
              {this.renderCategorySuggestions()}
            </ul>
          </div>
          }
        </div>
      </div>
    );
  }
}

// export default withRouter(SearchBar);
// export default SearchBar;

/* ----------------------------------------   REDUX HANDLERS   -------------------------------------  */
const mapDispatchToProps = dispatch => ({
  plpReduxStateReset: () => dispatch(actionCreators.resetPLPReduxState()),
});

const mapStateToProps = state => {
  const stateObj = getReleventReduxState(state, 'plpContainer');
  return {

  }
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'plpContainer', reducer });
const withSaga = injectSaga({ key: 'plpContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withRouter,
)(SearchBar);
