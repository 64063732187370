import React from "react";
import "./Footer.scss";

export default function Footer() {
  return (
    <div className="footer-body">
      <h4 className="footer-title">Stay in touch</h4>
      <div className="footer-links-content">
        <a className="footer-links" href="mail to: avsolns@godrej.com">
          avsolns@godrej.com
        </a>{" "}
        |{" "}
        <a className="footer-links" href="https://www.godrejinterio.com/">
        www.godrejinterio.com
        </a>
      </div>
      <div className="footer-social">
        <table><tr><td className='icons'>
          <a
            href="https://www.facebook.com/GodrejAVSolutions"
            target="_blank"
            rel="noreferrer"
          >
            <img src="https://www.godrejinterio.com/imagestore/email_img/abandonCart/facebook.png" alt="facebook"></img>
          </a>
        </td>
        <td className='icons'>
          <a
            href="https://www.linkedin.com/company/4287397/admin/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="https://www.godrejinterio.com/imagestore/email_img/abandonCart/linkedin.png" alt="linkedin"></img>
          </a>
        </td>
        </tr>
        </table>
      </div>
  
    </div>
  
  );
}
