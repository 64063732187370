import React from "react";

import {imagePrefix} from '../../../public/constants/constants';

class FormImg extends React.Component {
// export default function FormImg() {
  constructor(props) {
    super(props);
    this.state = {
      
    }
    
  }

  render() {
    return (
      <div>
        <div className="mob-border">
          <p></p>
        </div>
        <div className="mob-border-content">
          <h1>
            {this.props.text1} <br /> {this.props.text2}
          </h1>
          <h5>
            {this.props.text3} <br /> {this.props.text4} <br /> {this.props.text5}
          </h5>
        </div>
      
        <img src={imagePrefix+this.props.imagePath1} alt="FranchiseBanner"></img>
        <img className="mob-img" src={imagePrefix+this.props.imagePath2} alt="FranchiseBanner"></img>
      
      </div>
    );
  }
}

export default FormImg;
