import React from "react";

export default function ProductDetails(props) {
  return (
    <div className="product-cell">
      <h2>{props.title}</h2>

      <div>
        <ul className="product-info"> 
          {props.list.map((list) => {
            if (Object.prototype.toString.call(list).indexOf("Array") > -1) {
              return (
                <ul className="inner-list">
                  {list.map((list) => <li>{list}</li>)}
                </ul>
              );
            } 
            return <li>{list}</li>;
            
          })}
        </ul>
      </div>
    </div>
  );
}
