import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import apiManager from "../../utils/apiManager";
import {
  productBestSellerTitleCharLimit,
  mproductBestSellerTitleCharLimit,
  mproductRecentlyViewedTitleCharLimit,
  compareAPI,
} from "../../../public/constants/constants";
import { isMobile, trimTheSentence } from "../../utils/utilityManager";
import {
  bestSellerIndiaCircusAPI,
  bestSellerAPI,
  imagePrefix,
} from "../../../public/constants/constants";
import {
  is,
  formatPrice,
  createSEOPdpURL,
} from "../../utils/utilityManager";
import "../../../public/styles/bestSeller/bestSeller.scss";
import "../../../public/styles/slickCustom.scss";
import appCookie from "../../utils/cookie";
import { triggerHPProductClickEvent } from "../../utils/gtm";
import { TAX_DISCLAIMER } from "../../constants/app/pdpConstants";
import "react-lazy-load-image-component/src/effects/blur.css";
import { InView } from 'react-intersection-observer';

class BestSeller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bestSellerData: {},
      title: null,
      maintitle:null,
      productList: null,
      isLoading: true,
      errors: null,
      panel: "",
      inview:false,
    };
  }

  // getRecentData(data) {
  //   const cookieIdArray = new Array();
  //   data.map(dataId => {
  //     cookieIdArray.push(dataId.uniqueID);
  //   });
  //   apiManager
  //     .get(`${compareAPI}?ids=${cookieIdArray.toString()}`)
  //     .then(response => {
  //       const { data } = response || {};
  //       const productList = data && data.data;
  //       const prdArray = new Array();
  //       if (Array.isArray(productList)) {
  //         productList.map(product => {
  //           product.sKUs.map(sku => {
  //             if (sku.uniqueID === product.uniqueId) {
  //               prdArray.push(sku);
  //             }
  //           });
  //         });
  //       }
  //       if (prdArray.length > 0) {
  //         this.setState({
  //           isLoading: false,
  //           title: "Recently Viewed",
  //           productList: prdArray,
  //         });
  //       }
  //     })
  //     .catch(error => {
  //       this.setState({
  //         error,
  //         isLoading: false,
  //       });
  //     });
  // }

  getBestSellerData() {
    const sellerAPI = this.props.espotName=='bestseller_indiacircur' ? bestSellerIndiaCircusAPI : bestSellerAPI
    apiManager
      .get(sellerAPI)
      .then(response => {
        const { data } = response || {};
        const bsData = data && data.data;
        const title = data && data.data.title;
        const maintitle = data && data.data.maintitle;
        const productList = data && data.data.productList;

        this.setState({
          bestSellerData: (is(bsData, "Object") && bsData) || [],
          isLoading: false,
          title,
          maintitle,
          productList,
        }, ()=>{
          this.render()
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    if (
      appCookie.get("recentProduct") &&
      JSON.parse(appCookie.get("recentProduct").length > 0) &&
      this.props.panelName == "recentlyViewed"
    ) {
      // this.getRecentData(JSON.parse(appCookie.get("recentProduct")));
      this.setState({
        isLoading: false,
        title: "Recently Viewed",
        panel: "recentlyViewed",
        productList: JSON.parse(appCookie.get("recentProduct")).reverse(),
      });
    } else if (this.props.panelName == "bestSeller") {
      this.getBestSellerData();
      this.setState({
        panel: "bestSeller",

      });
    } else {
      this.setState({
        panel: "",
      });
    }
  }

  handleTitle = title => {
    <title>{title}</title>;
  };

  productClickHandler = (skuData, index) => {
    triggerHPProductClickEvent({
      eventName: this.props.panelName, productName: skuData.productName, skuid: skuData.partNumber
    }
      
    );
  };

  render() {
    const {productList} = this.state;
    const {title,maintitle} = this.state;
    const settings = {
      dots: 
        !!(appCookie.get("recentProduct") &&
        JSON.parse(appCookie.get("recentProduct").length > 0) &&
        this.props.panelName == "recentlyViewed"),
      dots:true,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: false,
      speed: 500,
      lazyLoad: true,
      slidesToShow:
        appCookie.get("recentProduct") &&
        JSON.parse(appCookie.get("recentProduct").length > 0) &&
        this.props.panelName == "recentlyViewed"
          ? 7
          : 5,
      slidesToScroll: 1,
      focus: true,
      nextArrow: false,
      prevArrow: false,
      arrows: false,
      prevArrow: <button className="slide-arrow prev-arrow" />,
      nextArrow: <button className="slide-arrow next-arrow" />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            arrows:true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow:
              appCookie.get("recentProduct") &&
              JSON.parse(appCookie.get("recentProduct").length > 0) &&
              this.props.panelName == "recentlyViewed"
                ? 3
                : 2,
            slidesToScroll: 2,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow:
              appCookie.get("recentProduct") &&
              JSON.parse(appCookie.get("recentProduct").length > 0) &&
              this.props.panelName == "recentlyViewed"
                ? 3
                : 2,
            slidesToScroll: 2,
            dots: true,
            arrows: true,
            prevArrow: <button className="slide-arrow prev-arrow" />,
            nextArrow: <button className="slide-arrow next-arrow" />,
          },
        },
      ],
    };
    if (productList != null && productList.length <= 0) {
      return null;
    }
    return (
      <>
        {this.state.panel == "" ? (
          <></>
        ) : (
          <InView as="div" onChange={(inView, entry) => this.setState({inview: this.state.inview == true || inView==true? true: false})} style={{minHeight : !isMobile() ? '100px' : '50px'}}>
            {this.state.inview ?
          <div className={this.props.panelName != 'bestSeller' ? "":"bestseller-container"}>
            <div
             // onLoad={()=>{if(this.props.panelName=='bestSeller'){this.getBestSellerData()}}}
              className={
                appCookie.get("recentProduct") &&
              JSON.parse(appCookie.get("recentProduct").length > 0) &&
              this.props.panelName == "recentlyViewed"
                  ? "bestSeller recentlyViewed"
                  : this.state.bestSellerData == []
                    ? "bestSeller "
                    : "bestSeller bestSeller-actual"
              }
            >
              {this.props.espotName=='bestseller_indiacircur' ?<span className="title-decor">{maintitle}</span> :''}
              <span className="title">{title}
                {/* {this.props.panelName == 'bestSeller'?<h4>Take a sneek peak into the start of the art and best in class of our products</h4>:<></>} */}
              </span>
           
              <Slider {...settings}>
                {is(productList, "Array") &&
                productList.map((sellerItemData, index) => {
                  const productname = String(
                    sellerItemData.productName
                  ).toLowerCase();
                  const routePath = createSEOPdpURL(
                    sellerItemData.productName,
                    sellerItemData.shortDescription,
                    sellerItemData.partNumber
                  );
                   let a2image='';
                  let a1image='';
                    sellerItemData.attachments && sellerItemData.attachments.map((data,index)=>{
                    if (data.imagePath.includes("_A2_"))
                    {
                      a2image = data.imagePath;
                    }
                    if (data.imagePath.includes("_A1_"))
                    {
                      a1image = data.imagePath;
                    }
                  })
                  return (
                    <figure key={index} className="bsSlides">
                      <Link
                        to={routePath}
                        onClick={this.productClickHandler.bind(
                          this,
                          sellerItemData,
                          index
                        )}
                      >
                        <img
                          loading="lazy"
                          alt={sellerItemData.productName}
                          effect="blur"
                          className="subCatImg"
                          onClick={this.handleTitle(
                            `${sellerItemData.pageTitle}`
                          )}
                           src={
                            this.props.panelName == "recentlyViewed" ? 
                            `${imagePrefix}${sellerItemData.thumbnail}`
                            : a2image!=='' ?
                            imagePrefix + a2image
                            : a1image !=='' ?
                           imagePrefix + a1image
                            :  imagePrefix + sellerItemData.thumbnail
                          }
                        />
                      </Link>
                      {this.props.panelName != "bestSeller" ? (
                        <figcaption className="bsDetails">
                          <Link
                            to={routePath}
                            onClick={this.productClickHandler.bind(
                              this,
                              sellerItemData,
                              index
                            )}
                          >
                            <h2 className="prodtitle">
                              {!isMobile() ? sellerItemData.productName &&
                              sellerItemData.productName.length >
                                productBestSellerTitleCharLimit
                                ? trimTheSentence(
                                  sellerItemData.productName,
                                  productBestSellerTitleCharLimit
                                )
                                : sellerItemData.productName :
                                sellerItemData.productName &&
                                sellerItemData.productName.length >
                                mproductRecentlyViewedTitleCharLimit
                                  ? trimTheSentence(
                                    sellerItemData.productName,
                                    mproductRecentlyViewedTitleCharLimit
                                  )
                                  : sellerItemData.productName  }
                            </h2>
                            
                          </Link>
                          {appCookie.get("recentProduct") &&
                          JSON.parse(
                            appCookie.get("recentProduct").length > 0
                          ) &&
                          this.props.panelName == "recentlyViewed" ? (
                              <></>
                            ) : (
                              <>
                                <h2 className="peiceDeatils">
                                  {sellerItemData.actualPrice <=
                                sellerItemData.offerPrice ? (
                                      <span className="discPrice">
                                        {sellerItemData.actualPrice !== ""
                                          ? `₹${formatPrice(
                                            sellerItemData.actualPrice
                                          )}`
                                          : null}
                                      </span>
                                    ) : (
                                      <>
                                        <span className="discPrice">
                                          {sellerItemData.offerPrice !== ""
                                            ? `₹${formatPrice(
                                              sellerItemData.offerPrice
                                            )}`
                                            : null}
                                        </span>
                                        <span className="actualPriceLine">
                                          <span className="actualPrice">
                                            <del>
                                              {sellerItemData.actualPrice !== ""
                                                ? `₹${formatPrice(
                                                  sellerItemData.actualPrice
                                                )}`
                                                : null}
                                            </del>
                                          </span>
                                        </span>
                                      </>
                                    )}
                                    <span className="discount">
                                      {sellerItemData.discount !== ""
                                          ? 
                                          `${sellerItemData.discount}%`
                                          : null}
                                    </span>
                                </h2>
                                {/* <p
                                  className="tax-disclaimer"
                                  style={{ marginBottom: "0px" }}
                                >
                                  {TAX_DISCLAIMER}
                                </p> */}
                                {/* {sellerItemData.emiData !== "" ? (
                                  <p className="emi" style={{ marginTop: "-1%" }}>
                                  EMI Starting From
                                    <span className="emiPrice">
                                    ₹{formatPrice(sellerItemData.emiData)}
                                    </span>
                                  </p>
                                ) : null} */}

                                {/* <p
                                  className="emiOffer"
                                  style={{ marginTop: "-1%" }}
                                >
                                  {sellerItemData.discount !== "" ? (
                                    <span className="emiOfferDisc">
                                      {parseInt(sellerItemData.discount) < 2
                                        ? null
                                        : `${sellerItemData.discount}% Off`}{" "}
                                    </span>
                                  ) : null}
                                  {parseInt(sellerItemData.discount) < 2
                                    ? null
                                    : sellerItemData.discount &&
                                    sellerItemData.discount !== "" &&
                                    sellerItemData.promotionData &&
                                    sellerItemData.promotionData !== ""
                                      ? "& "
                                      : ""}
                                  {sellerItemData.promotionData !== ""
                                    ? sellerItemData.promotionData
                                    : null}
                                </p> */}
                              </>
                            )}
                        </figcaption>
                      ) : (
                        <figcaption className="bsDetails">
                          <Link
                            to={routePath}
                            onClick={this.productClickHandler.bind(
                              this,
                              sellerItemData,
                              index
                            )}
                          >
                            <h2 className="prodtitle">
                              {!isMobile() ? sellerItemData.productName &&
                        sellerItemData.productName.length >
                          productBestSellerTitleCharLimit
                                ? trimTheSentence(
                                  sellerItemData.productName,
                                  productBestSellerTitleCharLimit
                                )
                                : sellerItemData.productName : 
                                sellerItemData.productName &&
                                sellerItemData.productName.length >
                                  mproductBestSellerTitleCharLimit
                                        ? trimTheSentence(
                                          sellerItemData.productName,
                                          mproductBestSellerTitleCharLimit
                                        )
                                        : sellerItemData.productName }
                              <p>{sellerItemData.shortDescription}</p>
                            </h2>
                      
                          </Link>
                          <h2 className="peiceDeatils">
                            {sellerItemData.actualPrice <=
                      sellerItemData.offerPrice ? (
                                <span className="discPrice">
                                  {sellerItemData.actualPrice !== ""
                                    ? `₹${formatPrice(sellerItemData.actualPrice)}`
                                    : null}
                                </span>
                              ) : (
                                <>
                                  <span className="discPrice">
                                    {sellerItemData.offerPrice !== ""
                                      ? `₹${formatPrice(sellerItemData.offerPrice)}`
                                      : null}
                                  </span>
                                    <span className="actualPrice">
                                      <del>
                                        {sellerItemData.actualPrice !== ""
                                          ? `₹${formatPrice(sellerItemData.actualPrice)}`
                                          : null}
                                      </del>
                                    </span>
                                </>
                              )}
                              <span className="discount">
                                {sellerItemData.discount !== ""
                                  ? 
                                  `${sellerItemData.discount}%`
                                  : null}
                              </span>
                          </h2>
                          {/* <p className="tax-disclaimer" style={{marginBottom:'0px'}}>{TAX_DISCLAIMER}</p> */}
                          {/* {sellerItemData.emiData !== "" ? (
                            <p className="emi" style={{marginTop:'-1%'}}>
                        EMI Starting From
                              <span className="emiPrice">
                          ₹{formatPrice(sellerItemData.emiData)}
                              </span>
                            </p>
                          ) : null} */}

                          {/* <p className="emiOffer" style={{marginTop:'-1%'}}>
                            {sellerItemData.discount !== "" ? (
                              <span className="emiOfferDisc">
                                {parseInt(sellerItemData.discount) < 2
                                  ? null
                                  : `${sellerItemData.discount}% Off`}{" "}
                              </span>
                            ) : null}
                            {parseInt(sellerItemData.discount) < 2
                              ? null
                              : sellerItemData.discount &&
                          sellerItemData.discount !== "" &&
                          sellerItemData.promotionData &&
                          sellerItemData.promotionData !== ""
                                ? "& "
                                : ""}
                            {sellerItemData.promotionData !== ""
                              ? sellerItemData.promotionData
                              : null}
                          </p> */}
                        </figcaption>
                      )}
                    </figure>
                  );
                })}
              </Slider>
            </div></div> :<></>}
         </InView>
        )}
      </>
    );
  }
}

export default BestSeller;
