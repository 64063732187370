import React from 'react';
import { host } from '../../../public/constants/constants';
import JsonLd from './JsonLd';

class BreadCrumbSchema extends React.Component {

  componentDidMount() {
    if (this.props.breadcrumbs && this.props.breadcrumbs.length > 0) {
      const data = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",

      }
      const entity = []
      this.props.breadcrumbs.map((qa, key) => {
        const entityJson = {
          "@type": "ListItem",
          "position": `${key + 1}`,
          "name": `${qa.name}`,
          "item": `${window.location.protocol}//${host}${qa.url}`,
        }
        entity.push(entityJson)
      })
      data.itemListElement = entity;

      // Create a script element
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify(data);

      // Append the script to the end of the body
      document.body.appendChild(script);

    }

  }
  render() {

    return (
      <>
        {/* <JsonLd data={data} /> */}
      </>
    )
  }

}

export default BreadCrumbSchema;
