import React from 'react';
import {OUT_OF_STOCK} from '../../constants/app/primitivesConstants';

const OutOfStockIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 49" {...props}>
    <title>Out Of Stock</title>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillOpacity=".8" fillRule="nonzero" stroke="#EE4060" d="M.5 1h179v47H.5z"/>
      <text fill="#000" fontFamily="CeraGIMedium,sans-serif" fontSize="16" fontWeight="400" transform="translate(0 .5)">
        <tspan x="43.5" y="30">{OUT_OF_STOCK}</tspan>
      </text>
    </g>
  </svg>

);

export default OutOfStockIcon;
