export const currencyINR = 'INR';
export const purchaseGTagEvent = 'purchase';
export const productDetailsGTagEvent = 'detail';
export const checkoutGTagEvent = 'checkout';
export const addToCartGTagEvent = 'add';
export const removeFromCartGTagEvent = 'remove';
export const productClickGTagEvent = 'click';
export const impressionsGTagEvent = 'impressions';
export const brandParamGTag = 'Godrej Interio';
export const affiliationParamGTag = 'Online Store';
export const formSubmissionGTagEvent = 'formSubmission';

export const BOOK_A_CONSULTATION_FORM_TYPE = 'Book A Consultation';
export const FORM_POSITION = {
  wardrobes: 'Modular Wardrobes',
  kitchen: 'Kitchen',
  default: 'Kitchen',
};
export const REQUEST_CALLBACK_FORM_TYPE = 'Request A Callback';
export const REQUEST_CALLBACK_FORM_POSITION = {
  homekitchen: 'Home-Furniture',
  homekitchennorth: 'Home-Furiniture-North',
  homekitchennortheast: 'Home-Furiniture-Northeast',
  homekitchennorthwest: 'Home-Furiniture-Northwest',
  homekitchensouthtk: 'Home-Furiniture-Southtk',
  homekitchensouthatk: 'Home-Furiniture-Southatk',
  homekitcheneast: 'Home-Furiniture-East',
  homekitchenwest: 'Home-Furiniture-West',
  homekitchencentral: 'Home-Furniture-Central',
  modularkitchen: 'Modular-Kitchen',
  modularkitchennorth: 'Modular-Kitchen-North',
  modularkitchensouth: 'Modular-Kitchen-South',
  modularkitcheneast: 'Modular-Kitchen-East',
  modularkitchenwest: 'Modular-Kitchen-West',
  default: 'Home-Furniture',
};
