import React from "react";

import Button from 'react-bootstrap/lib/Button'
import Col from 'react-bootstrap/lib/Col'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import Label from 'react-bootstrap/lib/Label'
import FormControl from 'react-bootstrap/lib/FormControl'
import Modal from 'react-bootstrap/lib/Modal'

import { storeId, imagePrefix } from "../../../public/constants/constants";
import apiManager from "../../utils/apiManager";
import { isMobile } from "../../utils/utilityManager";
import {
  espotAPI,
  orderFeedbackAPI,
  GI_ORDER_FEEDBACK,
} from "../../../public/constants/constants";
// import { validateFullName, regexName } from "../../utils/validationManager";

export default class FeedbackForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      modalClass: "delete-modal succes-feedback",
      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
      q1Error: null,
      q2Error: null,
      q3Error: null,
      q4Error: null,
      q5Error: null,
      r1: null,
      r2: null,
      r3: null,
      r4: null,
      r5: null,
      star: 1,
      errorMsg: "",
      formData: null,
      ques1: "",
      ques2: "",
      ques3: "",
      ques4: "",
      ques5: "",
      resp1: "",
      resp2: "",
      resp3: "",
      resp4: "",
      resp5: "",
      submitDisabled: false,
      leftDeskImg: "",
      leftMobImg: "",
      title1: "",
      title2: "",
      imgURL: "",
    };
  }

  callFeedbackLeft = () => {
    const APIType = "GI_OrderFeedback_LeftPanel";
    const feedbackAPI = espotAPI + APIType;
    apiManager
      .get(feedbackAPI)
      .then(response => {
        this.setState({
          title1: response.data.data.title1,
          title2: response.data.data.title2,
          leftDeskImg: response.data.data.deskImg,
          leftMobImg: response.data.data.mobImg,
          imgURL: response.data.data.imgURL,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  callFeedbackForm() {
    const APIType = GI_ORDER_FEEDBACK;
    const feedbackAPI = espotAPI + APIType;
    apiManager
      .get(feedbackAPI)
      .then(response => {
        this.setState(
          {
            formData: response.data,
            ques1: response.data.data[0],
            ques2: response.data.data[1],
            ques3: response.data.data[2],
            ques4: response.data.data[3],
            ques5: response.data.data[4],
            resp1: response.data.data[0].responses,
            resp2: response.data.data[1].responses,
            resp3: response.data.data[2].responses,
            resp4: response.data.data[3].responses,
            resp5: response.data.data[4].responses,
          },
          () => {
            this.render();
          }
        );
      })
      .catch(error => {});
  }

  componentDidMount() {
    this.callFeedbackLeft();
    this.callFeedbackForm();
  }

  fieldChangeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value !== "" ? e.target.value : null,
    });
  };

  formSubmitHandler = () => {
    // if (this.formValidationHandler() == true) {

    this.setState({
      submitDisabled: true,
    });
    setTimeout(() => {
      this.setState({
        submitDisabled: false,
      });
    }, 5000);
    const { q1, q2, q3, q4, q5, r1, r2, r3, r4, r5, star } = this.state;
    const orderId = this.props.orderData.orderID;
    const data = {
      q1,
      q2,
      q3,
      q4,
      q5,
      r1,
      r2,
      r3,
      r4,
      r5,
      star,
      storeId,
      orderId,
    };
    apiManager
      .post(orderFeedbackAPI, data)
      .then(response => {
        const resData = response.data.data;
        if (resData.customerFeedbackSaved === true) {
          this.setState({
            r1: "",
            r2: "",
            r3: "",
            r4: "",
            r5: "",
            requirements: [],
            successMsg: "Thank You!",
          });
          this.props.disableFeedback();
        } else {
          this.setState({
            errorMsg: "Unable to save your data. Sorry for inconvenience.",
          });
        }
      })
      .catch(() => {
        this.setState({
          errorMsg: "Unable to save your data. Sorry for inconvenience.",
        });
      });
    // }
  };

  handleStarChange = star => {
    this.setState({
      star,
    });
  };

  handlechangeq1 = e => {
    if (e.target.name == this.state.r1) {
      this.setState({
        r1: null,
      });
    }
    this.setState({
      r1: e.target.name,
    });
  };

  handlechangeq2 = e => {
    if (e.target.name == this.state.r2) {
      this.setState({
        r2: null,
      });
    }
    this.setState({
      r2: e.target.name,
    });
  };

  handlechangeq3 = e => {
    if (e.target.name == this.state.r3) {
      this.setState({
        r3: null,
      });
    }
    this.setState({
      r3: e.target.name,
    });
  };

  handlechangeq4 = e => {
    if (e.target.name == this.state.r4) {
      this.setState({
        r4: null,
      });
    }
    this.setState({
      r4: e.target.name,
    });
  };

  handlechangeq5 = e => {
    if (e.target.name == this.state.r5) {
      this.setState({
        r5: null,
      });
    }
    this.setState({
      r5: e.target.name,
    });
  };

  onImgClick() {
    if (this.state.imgURL == "") {
      
    } else {
      window.open(this.state.imgURL);
    }
  }

  render() {
    const { q1, q2, q3, q4, q5 } = this.state;
    console.log(
      this.state.title1 +
        this.state.title2 +
        this.state.leftDeskImg +
        this.state.leftMobImg +
        this.state.imgURL
    );
    return (
      <Modal show={this.state.modal} className={this.state.modalClass}>
        <Modal.Body>
          <div className="succesModal">
            <div
              onClick={this.onImgClick.bind(this)}
              className="feedback-left"
              style={
                isMobile()
                  ? {
                    backgroundImage: `url(${imagePrefix +
                        this.state.leftMobImg}`,
                  }
                  : {
                    backgroundImage: `url(${imagePrefix +
                        this.state.leftDeskImg})`,
                  }
              }
            >
              <div>
                <h1>{this.state.title1}</h1>
              </div>
              <div className="feedback-left-desc">{this.state.title2}</div>
            </div>
            <div className="feedback-right">
              <h4>
                <b>Enjoying Godrej Interio?</b>
              </h4>
              <div className="feedback-form">
                <div className="feedback-box">
                  <Col xs={12}>
                    <FormGroup>
                      <div className="feedback-label">
                        {/* <Label>How did you discover our website?</Label> */}
                        <Label>{this.state.ques1.question1}</Label>
                      </div>
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp1.r1}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp1.r1}
                        label="No"
                        value="false"
                        checked={this.state.r1 == this.state.resp1.r1}
                        onChange={this.handlechangeq1}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp1.r2}</Label>
                      </div>

                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp1.r2}
                        label="No"
                        value="false"
                        checked={this.state.r1 == this.state.resp1.r2}
                        onChange={this.handlechangeq1}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp1.r3}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp1.r3}
                        label="No"
                        value="false"
                        checked={this.state.r1 == this.state.resp1.r3}
                        onChange={this.handlechangeq1}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp1.r4}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp1.r4}
                        label="No"
                        value="false"
                        checked={this.state.r1 == this.state.resp1.r4}
                        onChange={this.handlechangeq1}
                      />
                      <FormControl
                        type="text"
                        name="q1"
                        className="form-control"
                        placeholder="Type in your Feedback"
                        value={q1}
                        onChange={this.fieldChangeHandler}
                        onKeyPress={this.fieldChangeHandler}
                      />
                      <p className="error-msg">{this.state.q1Error}</p>
                    </FormGroup>
                  </Col>
                </div>
                <div className="feedback-box">
                  <Col xs={12}>
                    <FormGroup>
                      <div className="feedback-label">
                        <Label>{this.state.ques2.question2}</Label>
                      </div>
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp2.r1}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp2.r1}
                        checked={this.state.r2 == this.state.resp2.r1}
                        onChange={this.handlechangeq2}
                        label="No"
                        value="false"
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp2.r2}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp2.r2}
                        checked={this.state.r2 == this.state.resp2.r2}
                        onChange={this.handlechangeq2}
                        label="No"
                        value="false"
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp2.r3}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp2.r3}
                        checked={this.state.r2 == this.state.resp2.r3}
                        onChange={this.handlechangeq2}
                        label="No"
                        value="false"
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp2.r4}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp2.r4}
                        checked={this.state.r2 == this.state.resp2.r4}
                        onChange={this.handlechangeq2}
                        label="No"
                        value="false"
                        // onChange={handleChange}
                      />
                      <FormControl
                        type="text"
                        name="q2"
                        className="form-control"
                        placeholder="Type in your Feedback"
                        value={q2}
                        onChange={this.fieldChangeHandler}
                        onKeyPress={this.fieldChangeHandler}
                      />
                      <p className="error-msg">{this.state.q2Error}</p>
                    </FormGroup>
                  </Col>
                </div>
                <div className="feedback-box">
                  <Col xs={12}>
                    <FormGroup>
                      <div className="feedback-label">
                        <Label>{this.state.ques3.question3}</Label>
                      </div>
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp3.r1}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp3.r1}
                        label="No"
                        value="false"
                        checked={this.state.r3 == this.state.resp3.r1}
                        onChange={this.handlechangeq3}
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp3.r2}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp3.r2}
                        label="No"
                        value="false"
                        checked={this.state.r3 == this.state.resp3.r2}
                        onChange={this.handlechangeq3}
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp3.r3}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp3.r3}
                        label="No"
                        checked={this.state.r3 == this.state.resp3.r3}
                        onChange={this.handlechangeq3}
                        value="false"
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp3.r4}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp3.r4}
                        label="No"
                        value="false"
                        checked={this.state.r3 == this.state.resp3.r4}
                        onChange={this.handlechangeq3}
                        // onChange={handleChange}
                      />
                      <FormControl
                        type="text"
                        name="q3"
                        className="form-control"
                        placeholder="Type in your Feedback"
                        value={q3}
                        onChange={this.fieldChangeHandler}
                        onKeyPress={this.fieldChangeHandler}
                      />
                      <p className="error-msg">{this.state.q3Error}</p>
                    </FormGroup>
                  </Col>
                </div>
                <div className="feedback-box">
                  <Col xs={12}>
                    <FormGroup>
                      <div className="feedback-label">
                        <Label>{this.state.ques4.question4}</Label>
                      </div>
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp4.r1}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp4.r1}
                        label="No"
                        value="false"
                        checked={this.state.r4 == this.state.resp4.r1}
                        onChange={this.handlechangeq4}
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp4.r2}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp4.r2}
                        label="No"
                        value="false"
                        checked={this.state.r4 == this.state.resp4.r2}
                        onChange={this.handlechangeq4}
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp4.r3}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp4.r3}
                        label="No"
                        checked={this.state.r4 == this.state.resp4.r3}
                        onChange={this.handlechangeq4}
                        value="false"
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp4.r4}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp4.r4}
                        label="No"
                        checked={this.state.r4 == this.state.resp4.r4}
                        onChange={this.handlechangeq4}
                        value="false"
                        // onChange={handleChange}
                      />
                      <FormControl
                        type="text"
                        name="q4"
                        className="form-control"
                        placeholder="Type in your Feedback"
                        value={q4}
                        onChange={this.fieldChangeHandler}
                        onKeyPress={this.fieldChangeHandler}
                      />

                      <p className="error-msg">{this.state.q4Error}</p>
                    </FormGroup>
                  </Col>
                </div>
                <div className="feedback-box">
                  <Col xs={12}>
                    <FormGroup>
                      <div className="feedback-label">
                        <Label>{this.state.ques5.question5}</Label>
                      </div>
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp5.r1}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp5.r1}
                        placeholder="No"
                        value="false"
                        checked={this.state.r5 == this.state.resp5.r1}
                        onChange={this.handlechangeq5}
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp5.r2}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp5.r2}
                        label="No"
                        value="false"
                        checked={this.state.r5 == this.state.resp5.r2}
                        onChange={this.handlechangeq5}
                        // onChange={handleChange}
                      />
                      <div className="feedback-radiolabel">
                        <Label>{this.state.resp5.r3}</Label>
                      </div>
                      <FormControl
                        className="feedback-radio"
                        type="radio"
                        name={this.state.resp5.r3}
                        label="No"
                        value="false"
                        checked={this.state.r5 == this.state.resp5.r3}
                        onChange={this.handlechangeq5}
                        // onChange={handleChange}
                      />
                      <FormControl
                        type="text"
                        name="q5"
                        className="form-control"
                        placeholder="Type in your Feedback"
                        value={q5}
                        onChange={this.fieldChangeHandler}
                        onKeyPress={this.fieldChangeHandler}
                      />

                      <p className="error-msg">{this.state.q5Error}</p>
                    </FormGroup>
                  </Col>
                </div>
                <div className="feedback-box">
                  <Col xs={12}>
                    <FormGroup>
                      <div className="feedback-label">
                        <Label>Rate your overall experience</Label>
                      </div>
                      <div className="star-rating">
                        <label
                          onClick={() => {
                            this.handleStarChange(1);
                          }}
                        >
                          <img
                            src={
                              this.state.star >= 1
                                ? `${imagePrefix}/B2C/static/ResponsiveStarIcon.png`
                                : `${imagePrefix}/B2C/static/ResponsiveStarIconLine.png`
                            }
                          />
                        </label>
                        <label
                          onClick={() => {
                            this.handleStarChange(2);
                          }}
                        >
                          <img
                            src={
                              this.state.star >= 2
                                ? `${imagePrefix}/B2C/static/ResponsiveStarIcon.png`
                                : `${imagePrefix}/B2C/static/ResponsiveStarIconLine.png`
                            }
                          />
                        </label>
                        <label
                          onClick={() => {
                            this.handleStarChange(3);
                          }}
                        >
                          <img
                            src={
                              this.state.star >= 3
                                ? `${imagePrefix}/B2C/static/ResponsiveStarIcon.png`
                                : `${imagePrefix}/B2C/static/ResponsiveStarIconLine.png`
                            }
                          />
                        </label>
                        <label
                          onClick={() => {
                            this.handleStarChange(4);
                          }}
                        >
                          <img
                            src={
                              this.state.star >= 4
                                ? `${imagePrefix}/B2C/static/ResponsiveStarIcon.png`
                                : `${imagePrefix}/B2C/static/ResponsiveStarIconLine.png`
                            }
                          />
                        </label>
                        <label
                          onClick={() => {
                            this.handleStarChange(5);
                          }}
                        >
                          <img
                            src={
                              this.state.star >= 5
                                ? `${imagePrefix}/B2C/static/ResponsiveStarIcon.png`
                                : `${imagePrefix}/B2C/static/ResponsiveStarIconLine.png`
                            }
                          />
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                </div>

                <div className="feedback-buttons">
                  <FormGroup className="button-left">
                    <Button
                      className="submit-btn btn-block btn-bg"
                      onClick={() => {
                        this.props.disableFeedback();
                      }}
                    >
                      Cancel
                    </Button>
                  </FormGroup>
                  <FormGroup className="button-right">
                    <Button
                      className="submit-btn btn-block btn-bg"
                      onClick={this.formSubmitHandler}
                      disabled={this.state.submitDisabled}
                    >
                      Submit Feedback
                    </Button>
                  </FormGroup>
                </div>
                <div className="error-msg-div">
                  <h6 className="message">
                    {this.state.errorMsg && (
                      <span className="failed">{this.state.errorMsg}</span>
                    )}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
