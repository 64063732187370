import React from "react";
import "./RoomCards.scss";
export default function RoomCards(props){
  return(
    <React.Fragment className="column">
  
      <div className="card-body ">
       
       
        <div className="card-overflow">
          <img alt="" className="card-img" src={props.img}></img>
        </div>
        <h2 className="card-title">{props.title} </h2>
        <div className="card-text">
          {props.description}
        </div>  
      </div>
      
    </React.Fragment>
      
    
  );
}