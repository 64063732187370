/* eslint-disable */
import React, { useState } from "react";
import "./Form.scss";
import axios from "axios";
import { useForm } from "react-hook-form";



import {
  avSolutionsLeadsAPI,
} from '../../../public/constants/constants';
// from '/../../public/constants/constants';
import apiManager from '../../utils/apiManager';
export default function FormData() {
  const [form, setForm] = useState({
    name: "",
    pnumber: "",
    email: "",
    company: "",
    city: "",
    state: "",
    note: "",
    nameErrMsg: "",
	submitDisabled: false
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let name, value;
  const handleInputs = (e) => {
    console.log(e);
    const target = e.target;
    name = target.name;
    value = target.value;

    setForm({ ...form, [name]: value });
  };

  const formSubmitHandler = (e) => {
  // if (!this.formValidationHandler()) return;

  setForm({
	submitDisabled: true
  });
  setTimeout(()=> {
	setForm({
		submitDisabled: false
	});
  }, 5000);
  const {
    name,
    pnumber,
    email,
    company,
    city,
    state,
    note,
    pageName,
  } = setForm;
  // const requirementsStr = requirements.map(rqrmnt => rqrmnt.value).join('||');
  const urlSearchParams = new URLSearchParams(
    window.location.search.substring(1),
  );

  const data = {
    name:form.name,
    pnumber:form.pnumber,
    email:form.email,
    company:form.company,
    city:form.city,
    state:form.state,
    note:form.note,
    pageName: "av-solutions",
    
  };
  apiManager
    .post(avSolutionsLeadsAPI, data)
    .then(response => {
      const resData = response.data;
      if (resData.status === 'success') {
        submittedSuccess();
        setForm({ ...form, name: '', pnumber: '', email: '', company: '', city: '', state: '', note: '', nameErrMsg: '' });
      } else {
		  submittedFail();
      }
    })
    .catch(() => {
		submittedFail();
      
    });
};

  const submittedSuccess = () => {
    window.alert("Data Submitted Successfully.");
    console.log("Data Submitted Successfully");
  };

  const submittedFail = () => {
    window.alert("Could not submit the data.");
    console.log("Could not submit the data");
  };

  

  return (
    <div className="form-data-body">
      <form onSubmit={handleSubmit(formSubmitHandler)} method="POST" autoComplete="none">
        <div className="input-block input-left">
        <p class="Form-Title">Name *</p>
          <input
            {...register("name", {
              required: {
                value: true,
                message: "⚠ This field is required",
                // this.useState.nameErrMsg: "This field is required",
              },
              pattern: {
                value: /^[A-Za-z ]+$/i,
                message: "Enter a valid Name",
              },
            })}
            name="name"
            id="name"
            className="form-input"
            type="text"
            value={form.name}
            onChange={handleInputs}
            placeholder="Please enter you name"
            autoComplete="none"
          ></input>
          {/* <p className="form-warning">{nameErrMsg}</p> */}
          <p className="form-warning"> {errors.name && errors.name.message}</p>

        </div>

        <div className="input-block input-right">
        <p class="Form-Title">Mobile No. *</p>
          <input
            {...register("pnumber", {
              required: {
                value: true,
                message: "⚠ This field is required",
              },
              maxLength: {
                value: 10,
                message: "Enter a valid phone number",
              },
              pattern: {
                value:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                message: "Enter a valid phone number",
              },
            })}
            name="pnumber"
            id="pnumber"
            className="form-input"
            type="tel"
            value={form.pnumber}
            onChange={handleInputs}
            placeholder="Please enter your phone number"
            autoComplete="none"
          ></input>
         
            <p className="form-warning">{errors.pnumber && errors.pnumber.message}</p>
          
        </div>
        <div className="input-block input-left">
        <p class="Form-Title">Email *</p>
          <input
            {...register("email", {
              required: {
                value: true,
                message: "⚠ This field is required",
              },
              pattern: {
                value: /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
                message: "Enter a valid Email ID",
              },
            })}
            name="email"
            id="email"
            className="form-input"
            type="email"
            value={form.email}
            onChange={handleInputs}
            placeholder="Please enter your email"
            autoComplete="none"
          ></input>
          
            <p className="form-warning">{errors.email && errors.email.message}</p>
          
        </div>

        <div className="input-block input-right">
        <p class="Form-Title">Company *</p>
          <input
            {...register("company", {
              required: { value: true, message: "⚠ This field is required" },
              pattern: {
                value: /^[A-Za-z]+$/i,
                message: "Enter a valid name",
              },
            })}
            name="company"
            id="company"
            className="form-input"
            type="text"
            value={form.company}
            onChange={handleInputs}
            placeholder="Please enter your company"
            autoComplete="none"
          ></input>
          
            <p className="form-warning">{errors.company && errors.company.message}</p>
          
        </div>

        <div className="input-block input-left">
        <p class="Form-Title">City *</p>
        <input
            {...register("city", {
              required: {
                value: true,
                message: "⚠ This field is required",
              },
              pattern: {
                value: /^[A-Za-z]+$/i,
                message: "Enter a valid City",
              },
            })}
            name="city"
            id="city"
            className="form-input"
            type="text"
            value={form.city}
            onChange={handleInputs}
            placeholder="Please Enter Your City*"
            autoComplete="none"
          ></input>
        
  {/* this.renderCities(); */}
          <p className="form-warning">{errors.city && errors.city.message}</p>
        </div>

        <div className="input-block input-right">
        <p class="Form-Title">State *</p>
          <input
            {...register("state", {
              required: {
                value: true,
                message: "⚠ This field is required",
              },
              pattern: {
                value: /^[A-Za-z]+$/i,
                message: "Enter a valid State",
              },
            })}
            name="state"
            id="state"
            className="form-input"
            type="text"
            value={form.state}
            onChange={handleInputs}
            placeholder="Please Enter Your State*"
            autoComplete="none"
          ></input>
          
            <p className="form-warning">{errors.state && errors.state.message}</p>
          
        </div>

        <div>
          <textarea
            {...register("note", {
              required: { value: true, message: "⚠ This field is required" },
            })}
            name="note"
            id="note"
            className="form-textarea"
            placeholder="Start a conversation with us*"
            onChange={handleInputs}
            value={form.note}
            autoComplete="none"
          ></textarea>
          <p className="form-warning form-warning-textarea">{errors.note && errors.note.message}</p>
        </div>
        <button value="Submit"  className="form-button" disabled={form.submitDisabled} >
          Submit
        </button>
      </form>
    </div>
  );
}






