import React from 'react';
import Slider from 'react-slick';
import tinycolor from 'tinycolor2';
import apiManager from '../../utils/apiManager';
// import BannerData from '../../data/BannerData.json';
import '../../../public/styles/slider.scss';
import { withRouter } from 'react-router-dom';
import {
  heroSliderAPI,
} from '../../../public/constants/constants';
import {createSEOPdpURL, formatPrice} from "../../utils/utilityManager";
import VideoModel from "./videoModel";
import {imagePrefix} from '../../../public/constants/constants';
import { triggerBannerClickHomepageEvent } from '../../utils/gtm';
// import { isMobile } from '../../utils/utilityManager';
class FullBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heroSlider: null,
      isLoading: false,
      error: null,
      recent: null,
      bannercolor: null,
      isMobile: window.innerWidth <= 767,
    };
    this.isScrolling=false;
    this.onVideoClick = this.onVideoClick.bind(this);
    this.onVideoClose = this.onVideoClose.bind(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);

    this.videoModel = React.createRef();
    this.heroSlider = React.createRef();
    // this.addDefaultSrc = this.addDefaultSrc.bind(this);
  }

  getSliderData() {
    apiManager
      .get(heroSliderAPI)
      .then(response => {
        const { data } = response || {}; 
        const recentData = [];
        // if (
        //   appCookie.get("recentProduct") &&
        //   JSON.parse(appCookie.get("recentProduct").length > 0)
        // ) {
        //   recentData = JSON.parse(appCookie.get("recentProduct"));
        // }    
        this.setState({
          heroSlider: data && data.data.bannerList,
          isLoading: false,
          recent: recentData[recentData.length-1],
          bannercolor: data && data.data.bgcolor,
          imgbgcolor: data && data.data.imgbgcolor,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.getSliderData();
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  onVideoClick(url) {
    this.setState({
      showVideo: true,
      videoUrl: url,
    });
    this.videoModel.current.showModal(url);
  }

  onVideoClose() {
    this.setState({
      showVideo: false,
      videoUrl: undefined,
    });
  }

  handleClickOutside(event) {
    if (this.state.showVideo) {
      this.onVideoClose();
    }
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOnClick(e) {
    window.scrollTo(0, 0);
  }
  //  addDefaultSrc(ev){  
  //   ev.target.src = `https://www.godrejinterio.com/imagestore/B2C/${this.state.recent.partNumber}/${this.state.recent.partNumber}_A1_803x602.jpg`
  // }

  handleOnBannerClick(path, onNewPage, alt, index) {
    const bannerData = {
      bannerURL: path,
      bannerID: alt + '_' + index
    }
    triggerBannerClickHomepageEvent(bannerData)
    const target = onNewPage && onNewPage === 'true' ? '_blank' : '_self';
    window.scrollTo(0, 0);
    if (!this.isScrolling && path) {
      if (
        path.search('http') !== -1 ||
        path.search('#') !== -1 ||
        target === '_blank'
      ) {
        window.open(path, target);
      } else {
        this.props.history.push({ pathname: path });
      }
    }
  }

  render() {
    const { imgbgcolor,bannercolor, recent, heroSlider } = this.state;
    let font=null
    if(tinycolor(this.state.bannercolor).isLight()){
      font = '#000'
    } 
    else{
      font = '#fff'
    }
    const settings = {
      dots: true,
      infinite: true,
      speed: 300,
      autoplay: true,
      autoplaySpeed: window.location.pathname == '/' ? 6000 : 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'ondemand',
    };
    // var imgsrc;
    return (
      <div className="fsBanner fsBanner-home">
        <Slider
          {...settings}
          afterChange={() => {this.isScrolling=false}}
          swipeEvent={() => {
            this.isScrolling = true;
            setTimeout(() => {this.isScrolling = false}, 1000);
          }}
        >
          {recent?
            <div className="RecentBanner">
              <div id="leftrecentbanner" style={{backgroundColor:bannercolor}}>
                <div className="centeralignrecent" style={{color:font}}>
                  <h2 className="recenttitle">Still thinking about it?</h2>
                  <h4 className="recentprice">GET!<br/><p className="bannerspace"> {recent.productName} at <b>₹{formatPrice(recent.offerPrice)}*</b></p></h4>
                  <a href={createSEOPdpURL(recent.productName,recent.shortDescription,recent.partNumber)}> 
                    <button className="btnshop">SHOP NOW</button>
                  </a>
                </div>
              </div>
              <div id="rightrecentbanner">
                <a href={createSEOPdpURL(recent.productName,recent.shortDescription,recent.partNumber)}> 
                  <img className="bannerimage" style={{background:imgbgcolor}}src={`https://www.godrejinterio.com/imagestore/B2C/${ this.state.recent.partNumber }/${ this.state.recent.partNumber }_01_803x602.png`} alt={recent.productName}/>
                </a>
              </div>
            </div>:null}
          {!!heroSlider &&
            heroSlider.map((sliderData, index) => (<>
              {/* {isMobile() ? imgsrc=sliderData.imageSrcRWD:imgsrc=sliderData.imageSrc} */}
              {sliderData.type === 'image' && (
                <a
				 rel="preload"
                  onClick={e => this.handleOnBannerClick(sliderData.onClickUrl, sliderData.onNewPage, sliderData.alt, index)}
                  onMouseDown={e => this.handleOnClick(e)}
                  key={index}
                >
                 
                  {/* {index===0?<div className="CJE">
                <LazyLoadImage effect="blur" src={this.state.isMobile ? sliderData.imageSrcRWD:sliderData.imageSrc} alt={sliderData.alt} /></div>
                :<div className="CJE"><LazyLoadImage
                  src={this.state.isMobile ? sliderData.imageSrcRWD:sliderData.imageSrc} 
                  effect= "blur"
                  alt={sliderData.alt}
                /></div>} */}
                  <div className="CJE">
                    <img
                      src={
                        this.state.isMobile ? sliderData.imageSrcRWD:sliderData.imageSrc
                      }
                      alt={sliderData.alt}
                      key={sliderData.alt.toLowerCase()}
                    />
                  </div>
                </a>)}
              {sliderData.type === 'video' && (
                <>
                  <div className="video-container">
                    <img src={`${imagePrefix}${this.state.isMobile ? sliderData.imageSrcRWD:sliderData.imageSrc}`} className="image video-img" alt={sliderData.alt} />
                    <div className="overlay">
                      <div className="align-play">
                        <img src={imagePrefix + sliderData.icon} className="media-play-video" onClick={() => this.onVideoClick(sliderData.video)} />
                      </div>
                    </div>
                  </div>
                </>)}
            </>
            ))}
        </Slider>
        <VideoModel ref={this.videoModel} />
      </div>
    );
  }
}

export default withRouter(FullBanner);
