import React from 'react';
import { formatPrice } from '../../../utils/utilityManager';


class Price extends React.Component {
  render() {
    let offerPrice = parseFloat(this.props.offerPrice);
    const actualPrice = parseFloat(this.props.actualPrice);
    let crossPriceItem;

    if (offerPrice < actualPrice) {
      crossPriceItem = <span className="priceno-discount">₹{formatPrice(actualPrice)}</span>;
    }
    if (!isNaN(offerPrice)) {
      offerPrice = `₹${formatPrice(offerPrice)}`
    }
    else {
      offerPrice = null
    }

    return (
      <>
        <span className="discount-price">{offerPrice}</span> {crossPriceItem}
      </>
    );
  }
}

export default Price;
