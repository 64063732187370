import React from 'react';
import '../../../public/styles/thankyou/thankyou.scss';


class ThankYou extends React.Component {
  constructor(props) {
    super();
    super(props);
    this.state = {
      buttonText: "",
      path: "",
    };
  }

  getURLData() {
    if (window.location.pathname == "/thank-you-homeinteriors") {
      this.setState({
        buttonText: "CONTINUE EXPLORING",
        path: "/home-interiors",
      });
    } else {
      this.setState({
        buttonText: "CONTINUE SHOPPING",
        path: "/",
      });
    }
  }

  componentDidMount() {
    this.getURLData();
  }

  render() {
    return (
      <div className="thankPage">
        <img className="successImg" src="https://www.godrejinterio.com/imagestore/thankyou.png"/>
        <p className="thankTitle"><b>Thank You</b></p>
        <p className="thankInfo">Your request has been submitted.</p>
        <p className="thankInfo">Your nearest store representative will reach out to you shortly.</p>
        <a className="btn shopBtn" href={this.state.path}>
          {this.state.buttonText}
        </a>
      </div>
    );
  }
}

export default ThankYou;
