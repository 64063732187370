import React from 'react';
import { connect } from 'react-redux';
import apiManager from '../../../utils/apiManager';
import { changePasswordAPI, setPassword,espotAPI } from '../../../../public/constants/constants';
import { PASSWORD,CANCEL,SIX_CHAR_PASS,REQUIRED_FIELD,ENTER_PASSWORD,INVALID_PASSWORD, PASSWORD_SHOUBLE_BE} from '../../../constants/app/myAccountConstants';

import '../../../../public/styles/myAccount/changePassword.scss';
import { regexPw, validateEmptyObject } from '../../../utils/validationManager';
import Input from '../../Primitives/input';
import { isMobile } from '../../../utils/utilityManager';
import { resetRWDHeaderFlag } from '../../../actions/app/actions';

class ChangePassword extends React.Component {
  state = {
    errorMessage: null,
    inputType: `${PASSWORD}`,
    inputTypeNew:  `${PASSWORD}`,
    isShowPass: false,
    isShowPassNew: false,
    inputTextCurrent: '',
    inputTextNew: '',
    errorCurrent: false,
    errorNew: false,
    newPasswordPasteTxt: null,
    currentPasswordPasteTxt: null,
    isSaveBtnActive: false,
    incorrectCurrentPasswordError:'',
    previouslyUsedPasswordError:'',
    samePasswordTextsError:'',
  };

  componentDidMount() {
    this.getEspotData();
  }

  getEspotData() {
    apiManager
      .get(espotAPI + 'GI_MyAccount_Data')
      .then(response => {
        const {data} = response || {};
        this.setState({
          incorrectCurrentPasswordError: data && data.data.incorrectCurrentPasswordError,
          previouslyUsedPasswordError: data && data.data.previouslyUsedPasswordError,
          samePasswordTextsError: data && data.data.samePasswordTextsError,
        });
      })
      .catch(error => {
        console.log("no data.");
      });
  }

  componentWillReceiveProps(nextProps) {

  }

  showHidePass() {
    if (this.state.isShowPass) {
      this.setState({
        isShowPass: false,
        inputType: `${PASSWORD}`,
      });
    } else {
      this.setState({
        isShowPass: true,
        inputType: 'text',
      });
    }
  }

  showHidePassNew() {
    if (this.state.isShowPassNew) {
      this.setState({
        isShowPassNew: false,
        inputTypeNew: `${PASSWORD}`,
      });
    } else {
      this.setState({
        isShowPassNew: true,
        inputTypeNew: 'text',
      });
    }
  }

  saveBtnPressed() {
    if (!this.state.isSaveBtnActive) {
      return
    }

    if (this.props.changePasswordTagPro === 0) {
      if (!validateEmptyObject(this.state.inputTextCurrent)) {
        this.setState({
          errorCurrent: true,
          errorMessage: this.state.inputTextCurrent === '' ? `${REQUIRED_FIELD}` : `${ENTER_PASSWORD}`,
        });
        return;
      }

      if (!regexPw.test(this.state.inputTextCurrent)) {
        let errorMsg;
        if (this.state.inputTextCurrent.length > 25) {
          errorMsg =
          `${INVALID_PASSWORD}`;
        } else {
          // errorMsg =
          //   `${PASSWORD_SHOUBLE_BE}`;
          errorMsg =
            `${SIX_CHAR_PASS}`;
        }

        this.setState({
          errorCurrent: true,
          errorMessage: errorMsg,
        });
        return;
      }
    }


    if (!validateEmptyObject(this.state.inputTextNew)) {
      this.setState({
        errorNew: true,
        errorMessage: this.state.inputTextNew === '' ? `${REQUIRED_FIELD}` : `${ENTER_PASSWORD}`,
      });
      return;
    }

    if (!regexPw.test(this.state.inputTextNew)) {
      let errorMsg;
      if (this.state.inputTextNew.length > 25) {
        errorMsg =
        `${INVALID_PASSWORD}`;
      } else {
        errorMsg =
          `${SIX_CHAR_PASS}`;
      }

      this.setState({
        errorNew: true,
        errorMessage: errorMsg,
      });
      return;
    }

    if (this.props.changePasswordTagPro === 0) {
      this.changePassword();
    }
    else {
      this.setNewPassword();
    }

  }

  changePassword() {
    if(this.state.inputTextCurrent==this.state.inputTextNew){
      this.setState({
        errorCurrent: false,
        errorNew: true,
        errorMessage:this.state.samePasswordTextsError,
      });
    }else{
    const data = {
      current_password: this.state.inputTextCurrent,
      new_password: this.state.inputTextNew,
    };
    apiManager
      .put(changePasswordAPI, data)
      .then(response => {
        if(response.data.data.message=='Incorrect Current Password.'){
          console.log('Incorrect Current Password got');
          this.setState({
            errorCurrent: true,
            errorNew: false,
            errorMessage:this.state.incorrectCurrentPasswordError,
          });
        }else if(response.data.data.message=='The password has been used previously.'){
          this.setState({
            errorCurrent: false,
            errorNew: true,
            errorMessage:this.state.previouslyUsedPasswordError,
          });          
        }else{
          this.setState({
            errorCurrent: false,
            errorNew: false,
            inputTextNew: '',
            inputTextCurrent: '',
          });
        }
        
        if(response.data.data.message!='Incorrect Current Password.' && response.data.data.message!='The password has been used previously.'){
        alert(response.data.data.message);
        this.enableDisableSaveBtn();
        }
      })
      .catch(error => {
        const errorData = error.response.data;
        const errorMessage = errorData.error.error_message;
        const erroCode = error.response.status;
        if(erroCode==400)
        {
          this.setState({
            errorNew: true,
            errorMessage,
          });
        }
        else
          alert(errorMessage);
       
      });
    }
  }

  setNewPassword() {
    const data = {
      new_password: this.state.inputTextNew,
    };
    apiManager
      .post(setPassword, data)
      .then(response => {
        this.setState({
          errorCurrent: false,
          errorNew: false,
          inputTextNew: '',
          inputTextCurrent: '',
        });
        alert(response.data.data.message);
        this.enableDisableSaveBtn();
      })
      .catch(error => {
    
      });
  }

  handleInputChange(value) {
    if (value.target.id === 'new') {
      this.state.inputTextNew = this.state.newPasswordPasteTxt !== null ? this.state.inputTextNew : value.target.value;
      this.setState({
        errorCurrent: false,
        errorNew: false,
        newPasswordPasteTxt: null,
      });
    } else {
      // this.state.inputTextCurrent = value.target.value;
      this.state.inputTextCurrent = this.state.currentPasswordPasteTxt !== null ? this.state.inputTextCurrent : value.target.value;
      this.setState({
        errorCurrent: false,
        errorNew: false,
        currentPasswordPasteTxt: null,
      });
    }

    this.enableDisableSaveBtn();
  }

  enableDisableSaveBtn() {
    let isBtnValidate = true;
  
    if (this.state.inputTextCurrent !== '' && this.state.inputTextNew !== '') {
      isBtnValidate = true;
    }
    else {
      isBtnValidate = false;
    }

    if (this.props.changePasswordTagPro === 1) {
      if (this.state.inputTextNew !== '') {
        isBtnValidate = true;
      }
      else {
        isBtnValidate = false;
      }
    }

    this.setState({
      isSaveBtnActive: isBtnValidate,
    })

  }

  onPasteText(value) {

   
    if (value.target.id === 'new') {
      this.setState({
        errorCurrent: false,
        errorNew: false,
        newPasswordPasteTxt: value.clipboardData.getData('text'),
      });
    }
    else  if (value.target.id === 'current') {
      this.setState({
        errorCurrent: false,
        errorNew: false,
        currentPasswordPasteTxt: value.clipboardData.getData('text'),
      });
    }
  }

  onRWDCancelBtnClick() {
    this.props.resetRWDHeaderFlag(true);
  }

  onKeyPress=(event)=>
  {
    if(event.key === 'Enter'){
      this.saveBtnPressed();
    }
  }

  render() {
    let errorItemCurrent = null;
    if (this.state.errorCurrent) {
      errorItemCurrent = (
        <div className="error-msg">{this.state.errorMessage}</div>
      );
    }

    let errorItemNew = null;
    if (this.state.errorNew) {
      errorItemNew = <div className="error-msg">{this.state.errorMessage}</div>;
    }

    let oldPasswordItem;
    if (this.props.changePasswordTagPro === 0) {
      oldPasswordItem = (
        <div className="form-div clearfix">
          <div className="currentPassword">
            <Input
              className="form-control newinputmargin"
              inputType={this.state.inputType}
              title="Current Password"
              onKeyPress={this.onKeyPress}
              name="text"
              id="current"
              placeholder="Enter Current Password"
              value={this.state.inputTextCurrent}
              handleChange={this.handleInputChange.bind(this)}
              onPaste={this.onPasteText.bind(this)}
            />
            {this.state.inputTextCurrent !== '' ? <span onClick={this.showHidePass.bind(this)} className="valiationPosition-NewPassword" >
              {<img src={require('../../SVGs/eye.svg')}  alt='Show Password'/>}
            </span> : null}

          </div>
          {errorItemCurrent}
        </div>
      )
    }

    return (
      <div className="form-BgContainer changePasswordContainer">

        {oldPasswordItem}

        <div className="form-div clearfix">
          <div className="form-group">
            <Input
              className="form-control"
              inputType={this.state.inputTypeNew}
              title="New Password"
              name="email"
              id="new"
              onKeyPress={this.onKeyPress}
              placeholder="Enter New Password"
              value={this.state.inputTextNew}
              handleChange={this.handleInputChange.bind(this)}
              onPaste={this.onPasteText.bind(this)}
            />
            {this.state.inputTextNew !== '' ? <span onClick={this.showHidePassNew.bind(this)} className="valiationPosition-NewPassword2" >
              {<img src={require('../../SVGs/eye.svg')}  alt='Show Password' />}
            </span> : null}
          </div>
          {errorItemNew}
        </div>
        {isMobile() && <button onClick={this.onRWDCancelBtnClick.bind(this)} className='btn-cancel btn'>{CANCEL}</button>}
        <button onClick={this.saveBtnPressed.bind(this)} className={this.state.isSaveBtnActive ? "btn-applyActive btn" : "btn-apply btn"}>
          SAVE
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  
}

export default connect(
  mapStateToProps,
  { resetRWDHeaderFlag },
)(ChangePassword);
