import React from 'react';
import Modal from 'react-bootstrap/lib/Modal'
import Button from 'react-bootstrap/lib/Button'

import '../../../public/styles/registerComponent/registerComponent.scss';
import apiManager from '../../utils/apiManager';
import { accessTokenCookie } from '../../../public/constants/constants';
import Register from './register';
import RegisterWithEmailMobile from './registerWithEmailMobile';
import GenerateOtp from './generateOtp';
import {
  registerWithEmail,
  generateOtp,
  resendOtp,
  otpConfirmed,
} from './constants';
import appCookie from '../../utils/cookie';
import WelcomeBack from '../WelcomeBack/index';
import { MAXOTP_ATTAMPTS_MSG } from '../../constants/app/primitivesConstants';

class RegisterModalData extends React.Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      data: null,
      modalClass: 'modal-wrapperjoinus',
    };
  }

  /* Handle Modal Close */
  handleClose(isClose = false) {
    if (this.state.data === null) {
      this.closePopup();
    } else if (this.state.data.props.registrationType === 'generateOtp' && isClose !== true) {
      this.handleComponent('registerWithMobileNum', this.state.data.props.userdata);
    } else {
      this.closePopup();
    }
  }

  /* close pop up */
  closePopup() {
    this.props.resetCallbackPro();
    this.setState({
      show: false,
      data: null,
      modalClass: 'modal-wrapperjoinus',
    });
  }

  /* Handle Modal Show */
  handleShow() {
    this.setState({ show: true });
  }

  handleLoginComponent() {
    this.props.callbackRegisterPro();
    this.setState({
      show: false,
      data: null,
      modalClass: 'modal-wrapperjoinus',
    });
    // let registerPopupEVent = new Event("loginPopupEVent",{ bubbles: true,});
    // registerPopupEVent.simulated = true;
    // document.dispatchEvent(registerPopupEVent);
    // this.handleClose(true);
    // return <WelcomeBack />;
  }

  /* Handle Components */
  handleComponent(type, data = null) {
    let renderComponent = null;
    this.setState({
      modalClass: 'modalJoinus',
    });

    if (type !== generateOtp) {
      renderComponent = (
        <RegisterWithEmailMobile
          componentData={this.handleComponent.bind(this)}
          registrationType={type}
          loginComponentData={this.handleLoginComponent.bind(this)}
          handleApi={this.handleComponetData.bind(this)}
          userdata={data}
        />
      );
      this.setState({
        modalClass: 'registerEmailMobile',
      });
    } else if (type === generateOtp) {
      renderComponent = (
        <GenerateOtp
          componentData={this.handleComponent.bind(this)}
          userdata={data}
          registrationType={type}
          handleApi={this.handleComponetData.bind(this)}
        />
      );
      this.setState({
        modalClass: 'modal-wrapperotp',
      });
    } else {
      renderComponent = (
        <Register
          couponData = {this.props.couponData}
          componentData={this.handleComponent.bind(this)}
          loginComponentData={this.handleLoginComponent.bind(this)}
        />
      );
    }

    this.setState({ data: renderComponent });
  }

  /* Handle Components API Data */
  handleComponetData(api, data, type, callbackFunc) {
    apiManager
      .post(api, data)
      .then(response => {
        if (type === registerWithEmail || type === otpConfirmed) {

          appCookie.set('isLoggedIn', true, 365 * 24 * 60 * 60 * 1000);
          // appCookie.set(
          //   `${accessTokenCookie}=${
          //     response.data.data.access_token
          //   };path=/;expires=''`,
          // );
		      // appCookie.set(
          //   `userID=${
          //     response.data.data.userID
          //   };path=/;expires=''`,
          // );
          appCookie.set(accessTokenCookie, response.data.data.access_token, 365 * 24 * 60 * 60 * 1000);
          appCookie.set('userID', response.data.data.userID, 365 * 24 * 60 * 60 * 1000);
          this.handleClose(true);
          window.location.reload();
        } else {
          if (type === resendOtp && response.data.data.otpCount > 3) {
            callbackFunc(MAXOTP_ATTAMPTS_MSG);
            return;
          } 
          if (type !== resendOtp) {
            this.handleComponent(generateOtp, data);
          }
        }
      })
      .catch(error => {
        const errorData = error.response.data;
        const errorMessage = errorData.error.error_message;
        callbackFunc(errorMessage);
      });
      
  }

  componentDidMount() {
    this.setState({ show: true });
  }

  render() {
    let data = null;
    if (this.state.data === null && this.state.show === true) {
      data = (
        <Register
        couponData = {this.props.couponData}
          componentData={this.handleComponent.bind(this)}
          loginComponentData={this.handleLoginComponent.bind(this)}
        />
      );
    } else {
      data = this.state.data;
    }

    return (
      <>
        {/* <Button className="registerNow" onClick={this.handleShow}>
          Register
        </Button> */}
        <Modal
          className={`modal_register ${this.state.modalClass}`}
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Body className={this.state.modalClass}>
            <div className="modal-wrapper">
              <Button className="close" onClick={this.handleClose} />
              {data}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default RegisterModalData;
