import React from 'react';
import '../../../public/styles/thankyou/thankyou.scss';
import './index';
import {
  homeFurnitureBtnClickTrackerTrigger,
  modularKitchenSubmitTrackerTrigger,
  
} from '../../utils/campaigns';

class thank extends React.Component {
	 constructor(props) {
    super(props);
    this.state = {
      fbPixelTriggered: false,
    };
  }

  render() {
    fbq('init', '971812680435038');
    fbq('track', 'PageView');
    const url = window.location.pathname;
    window.location.pathname == '/thank-you-north' || window.location.pathname == '/thank-you-southtk' || window.location.pathname == '/thank-you-northeast'
        || window.location.pathname == '/thank-you-northwest' || window.location.pathname == '/thank-you-east' || window.location.pathname == '/thank-you-southatk' 
        || window.location.pathname == '/thank-you-west'
        ||window.location.pathname == '/thank-you-central' || window.location.pathname == '/thank-you-furniture'
      ? 
      homeFurnitureBtnClickTrackerTrigger() :  modularKitchenSubmitTrackerTrigger()

    window.location.pathname == '/thank-you-north' || window.location.pathname == '/thank-you-southtk' || window.location.pathname == '/thank-you-northeast'
        || window.location.pathname == '/thank-you-northwest' || window.location.pathname == '/thank-you-east' || window.location.pathname == '/thank-you-southatk' 
        || window.location.pathname == '/thank-you-west'
        ||window.location.pathname == '/thank-you-central' || window.location.pathname == '/thank-you-furniture'
      ? fbq('track', 'furniture thank you page') : fbq('track', 'kitchen thank you page')
    return (
      <div className="thankPage">
        <img className="successImg" src="https://www.godrejinterio.com/imagestore/thankyou.png"/>
        <p className="thankTitle"><b>Thank You</b></p>
        <p className="thankInfo">Your request has been submitted.</p>
        <p className="thankInfo">Our representative will reach out to you shortly.</p>
        <a className="btn shopBtn" href='/'>CONTINUE SHOPPING</a>
      </div>
    );
  }
}

export default thank;