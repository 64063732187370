import React from 'react';

const UserLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 17 24">
    <titlle>User Logo</titlle>
    <g fill="#000" fillRule="evenodd">
      <path fillRule="nonzero" d="M8 8.853a4.439 4.439 0 0 1-4.427-4.426A4.439 4.439 0 0 1 8 0a4.439 4.439 0 0 1 4.427 4.427A4.439 4.439 0 0 1 8 8.853zm0-7.609a3.179 3.179 0 0 0-3.182 3.183A3.179 3.179 0 0 0 8 7.609a3.179 3.179 0 0 0 3.182-3.182A3.179 3.179 0 0 0 8 1.244z"/>
      <path d="M15.929 17h-1.245c0-3.076-2.986-5.582-6.666-5.582S1.333 13.924 1.333 17H.09c0-3.769 3.555-6.827 7.929-6.827 4.373 0 7.91 3.058 7.91 6.827z"/>
    </g>
  </svg>
)

export default UserLogo;