export const  orderCreate= 'Your order is created';
export const  orderCreated= 'Your order was created';
export const centralWarehouse= 'The product is on the way to our Central Warehouse';
export const centralWarehousePast= 'The product was received at our Central Warehouse';
export const localWarehouse= 'The product is on the way to our Local Store/Branch';
export const localWarehousePast= 'The product was received at our Local Store/Branch';

export const shipingInprogress= 'The product is out for delivery and on the way to you!';
export const shipped= 'The product was out for delivery and on the way to you!';

export const deliverInProg= 'Your product has reached to your doorstep';
export const deliver= 'The product was delivered!';
export const itemInstal= 'Your product was installed.';
export const thankYou = 'Thank You!';
// export const deliveryInProgress= 'Your product has reached to your doorstep';
// export const delivered= 'The product was delivered!';