import React from "react";
import {
    imagePrefix} from '../../../public/constants/constants';


class Sitemap extends React.Component {



    render(){
        return(
            <>
              <iframe 
                                    style={{height: "190vh", width: "100%"}}
                                    src= {imagePrefix+"/B2C/Sitemap/sitemap.html"}
                                />
            </>
        )
    }
}





export default Sitemap;
