/*
 *
 * HeaderContainer reducer
 *
 */

import { fromJS } from 'immutable';
import { COUPON_DATA, DEFAULT_ACTION, IS_OTP_ENABLED, MINICART_DATA } from '../../constants/headerContainer/constants';

export const initialState = fromJS({
  minicartData:null,
  isOtpEnabled:false,
  couponData: null
});

function headerContainerReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case MINICART_DATA:
      return state.set('minicartData', action.data);
    case IS_OTP_ENABLED:
      return state.set('isOtpEnabled',action.data)
    case COUPON_DATA:
      return state.set('couponData',action.data)
    default:
      return state;
  }
}

export default headerContainerReducer;
