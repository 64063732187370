import React from 'react';
import Slider from 'react-slick';
import apiManager from '../../utils/apiManager';
import '../../../public/styles/slider.scss';
import  '../../../public/styles/static-pages/kitchenRCB.scss'

import {espotAPI,imagePrefix} from '../../../public/constants/constants';

class FurnitureRCBHappyCustomerSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      espotName: 'GI_FURNITURE_RCB_HAPPYCUSTOMER_KITCHEN',
      hallSlider: null,
      isLoading: false,
      error: null,
      title: '',
      description:'',
      
    };
  }

  getkitchensHallData() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        const {data} = response || {}
        this.setState({
          hallSlider: data && data.data.bannerList,
          title: data && data.data.title,
          description:data && data.data.desc,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.getkitchensHallData();
  }

  render() {
    const { hallSlider, title, description } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
	    autoplay: false,// To be made as true later
      autoplaySpeed: 2000,
      slidesToShow: 2,
      slidesToScroll:2,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
          },
        },
      ],
    };
    return (
      hallSlider && 
      <div className="furniture-happy">
        {/* <div class="kitchen-modular"> */}
        <div className="hallOfFame">
          <div className="hallOfFameHeadingDiv">
            <h2 className="title">{title}</h2>
            {/* <p className="desc">{description}</p> */}
          </div>
          <Slider {...settings}>
            {!!hallSlider &&
              hallSlider.map((sliderData, index) => (
                <a href={sliderData.onClickUrl} key={index} className='slides'>
                  <img className='img' src={imagePrefix + sliderData.imageSrc} alt={sliderData.alt} />
                </a>

              ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default FurnitureRCBHappyCustomerSlider;



