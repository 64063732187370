import React from 'react';
// import PropTypes from 'prop-types';
import connect from 'react-redux/src/connect/connect'
// import { createStructuredSelector } from 'reselect';
import compose from 'redux/src/compose'
import '../../../public/styles/checkout.scss';
import Pixels from '../../components/Primitives/pixels';
import { triggerReviewCartGTEvent } from '../../utils/gtm';
import { cartDetailAPI } from '../../../public/constants/constants';
import appCookie from '../../utils/cookie'
import  { CheckoutComponent } from '../../components/checkout/index'
import apiManager from '../../utils/apiManager';

export class CheckoutContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
          
    }
  }


  render() {
    return (
      <>
        {/* <ContentEspot espotName = { 'GI_PIXEL_CHECKOUT_BODY_START' } /> */}
        <div>
          <Pixels espotName= "GI_PIXEL_CHECKOUT_META"/>
          <CheckoutComponent />
        </div>
        {/* <ContentEspot espotName = { 'GI_PIXEL_CHECKOUT_BODY_END' } /> */}
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  removeProduct: id => dispatch(actionCreators.RemoveProduct(id)),
});

const withConnect = connect(
  mapDispatchToProps
);
  

  
export default compose(
  withConnect,
)(CheckoutContainer);
