/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Streetview from 'react-streetview';

import apiManager from '../../utils/apiManager';
import { storeById, mapKey } from '../../../public/constants/constants';

const streetViewOuterContainerStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
};

class StreetViewComponent extends Component {
  constructor() {
    super();
    this.state = {
      storeIdentifier: null,
      latitude: null,
      longitude: null,
      horizontalOrientation: 0,
      pitch: 0,
      zoom: 1,
      isLoading: true,
    };
  }

  fetchStoreData = storeIdentifier => {
    this.setState({ storeIdentifier });
    apiManager
      .get(`${storeById}?physicalStoreId=${storeIdentifier}`)
      .then(response => {
        const { data } = response.data;
        if (data && data.length > 0) {
          const streetViewParamsString = data[0].streetView;
          const streetViewParams = {
            latitude: null,
            longitude: null,
            horizontalOrientation: 0,
            pitch: 0,
            zoom: 1,
          };
          streetViewParamsString.split('|').forEach(keyValue => {
            const keyValuePair = keyValue.split('=');
            if (keyValuePair.length > 1) {
              streetViewParams[keyValuePair[0]] = keyValuePair[1];
            }
          });
          if (streetViewParams.latitude && streetViewParams.longitude) {
            this.setState({
              latitude: parseFloat(streetViewParams.latitude),
              longitude: parseFloat(streetViewParams.longitude),
              horizontalOrientation: parseInt(streetViewParams.horizontalOrientation),
              pitch: parseInt(streetViewParams.pitch),
              zoom: parseInt(streetViewParams.zoom),
              isLoading: false,
            });
          } else {
            this.setState({
              latitude: null,
              longitude: null,
              horizontalOrientation: 0,
              pitch: 0,
              zoom: 1,
              isLoading: false,
            });
          }
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
      });
  };

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(
      window.location.search.substring(1),
    );
    const storeIdentifier = urlSearchParams.get('store');
    this.fetchStoreData(storeIdentifier);
  }

  render() {
    const {
      isLoading,
      latitude,
      longitude,
      horizontalOrientation,
      pitch,
      zoom,
    } = this.state;
    return (
      <section className="street-view">
        {!isLoading && <div className="street-view-outer" style={streetViewOuterContainerStyle}>
          <Streetview
            apiKey={mapKey}
            streetViewPanoramaOptions={{
              position: {lat: latitude, lng: longitude},
              pov: {heading: horizontalOrientation, pitch},
              zoom,
              linksControl: false,
              fullscreenControl:false,
            }}
          />
        </div>}
      </section>
    );
  }
}

export default StreetViewComponent;
