import React from "react";
import ProductDetails from "./ProductDetails";

export default function ProductDetailsPanel() {
  const overview = [
    "This offer is a pilot to help customer understand the value of Microsoft Teams Rooms when set up correctly in their business.",
    "Rooms need to be installed before the offer expires on June 15, 2022.",
    "Godrej AV Solutions will not change a fee for this offer. However, depending on the type of installation, customers may incur additional costs, such as, display hardware (if not provided), input switching, or custom AV control devices, Additional room modifications, e.g., cabling etc.",
    "Offer is for standard small and medium meeting rooms at one location. Executive or board rooms are not included in this program.",
    "To qualify, customer business must have at least 300 seats of Microsoft 365 and no Teams Rooms devices already active. Final decision on deployment of Rapid Rooms is with Microsoft only.",
  ];
  const getStarted = [
    "For customers who do not have Teams Rooms hardware and need support acquiring hardware, we have a funded hardware and services offer;",
    "You can select from pre-configured room kits for small and medium rooms.",
    "Teams Rooms on Windows or Teams Rooms on Android are available.",
    "Room kits must be from a single manufacturer.",
    "When we conduct initial workshops with customer, customer may want things that are outside of this offer. For example, additional displays or additional locations. If so, these will be quoted to customer for approval before we commence the deployment.",
  ];
  const custoReq = [
    "2 x Meeting Spaces in India with:",
    [
      "Power & data at a table.",
      "Power & data at the front of room.",
      "Existing large format display",
    ],
    "Customer must provide the Teams Rooms license (either Microsoft Teams Rooms Standard or Microsoft Teams Rooms Premium SKUs, paid and/or trial licenses, can be used).",
    "Customer must participate in assessment workshops and provide access to O365 tenant and physical locations.",
    "An executive sponsor and intent to expand deployment in the six months following the 2-room deployment.",
  ];

  return (
    <div className="table-container">
      <div>
       
          
        <ProductDetails title="What we Offer?" list={overview} />
          
         
        <ProductDetails title="Getting Started" list={getStarted} />
         
        <ProductDetails title="Customer Requirements" list={custoReq} />
      
      </div>
    </div>
  );
}
