import React from "react";
import "./RapidRooms.scss";
import {imagePrefix} from '../../../public/constants/constants';
const path = "/B2C/EspotImages/Images/rcb/RapidRooms/";
export default function IntroRR() {
  return (
    <div className="intro-container">
      <a> <img src={`${imagePrefix + path  }introRR.jpg`} ></img>
        <div className="intro-blur"></div>
        <div className="intro-content">
          <h1>Bring The Power of Microsoft Teams to Your Meeting Spaces</h1>
        </div>
      </a>
    </div>
  );
}
