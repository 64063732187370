import React from 'react';
import { withRouter } from 'react-router-dom';
import { imagePrefix } from '../../../public/constants/constants';
import {FREE } from '../../constants/app/cartConstants';
import {SHIPPING_CHARGES } from '../../constants/app/cartConstants';
import {OFF } from '../../constants/app/cartConstants';
import {QUANTITY } from '../../constants/app/cartConstants';
import { createSEOPdpURL, formatPrice} from '../../utils/utilityManager';

class MinicartItem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isFreeItem: this.props.dataPro.freeGift,
    };
  }
  

  minicartPrice() {
    const offerPrice = parseFloat(this.props.dataPro.offerPrice);
    const actualPrice = parseFloat(this.props.dataPro.actualPrice);
    const qty = parseInt(this.props.dataPro.quantity)
    let crossPriceItem;
    if (offerPrice*qty < actualPrice || Math.round(parseInt(this.props.dataPro.totalAdjustment) + parseInt(offerPrice)*parseInt(qty)) < actualPrice) {
      crossPriceItem =  <div className='actual-price-container'>{formatPrice(Math.round(parseInt(this.props.dataPro.totalAdjustment) + parseInt(offerPrice)*parseInt(qty))) < 10 ? '' : <><div className='actual-price-text'>MRP:</div><span className="priceno-discount">₹{formatPrice(actualPrice)}</span></> }</div>;
    }
    let boldStr = '';
    if (this.props.dataPro.discount !== '') {
      // boldStr = <span className="bold-price">{this.props.dataPro.discount}% {OFF}</span>
    }
    return (
      <>
      <div className='price-offers-container'>
        <div className='price-offers-text'>Offer price:</div>
        <p className="price">
          {parseInt(this.props.dataPro.totalAdjustment)==0?
          <span className="discount-price">₹{offerPrice*qty}</span>
          :
          <span className="discount-price"><span className="price-discount">₹{formatPrice(Math.round(parseInt(this.props.dataPro.totalAdjustment) + parseInt(offerPrice)*parseInt(qty)))}</span></span>
          }
         
          {boldStr}{' '}
       </p>
      </div>
      {crossPriceItem}{' '}
      </>
    );
  }

  shippingCharegeHandler() {
    const sCharge = Math.round(this.props.dataPro.shippingCharges);

    return (
      <>
        <span className="shipping-charge">{SHIPPING_CHARGES}<br /><span className="textval">{sCharge !== 0 ? sCharge : FREE}</span></span>
      </>
    );
  }

  onNavigationLink = () => {
    this.props.closeDropdownPro();
  }

  render() {

    let discountAccessoriesItem = null;
    let boldStr = '';
    if (this.props.dataPro.discount !== '') {
      // boldStr = <span className="bold">{this.props.dataPro.discount}% {OFF}</span>
    }
    discountAccessoriesItem = (
       <span className="free-accessories">  {this.props.dataPro.promotionData !== '' ? this.props.dataPro.promotionData : null}</span>
    );
    

    const productname = String(this.props.dataPro.productName).toLowerCase();
    const routePath = createSEOPdpURL(this.props.dataPro.productName, this.props.dataPro.shortDescription, this.props.dataPro.partNumber);
    return (
      <>
      
        <div className="minicartList">
          <a className="link" href={routePath} onClick={this.onNavigationLink}>
            <div className="imgBox">
              <img alt={this.props.dataPro.productName} src={this.props.dataPro.thumbnail !== '' ? `${imagePrefix}${this.props.dataPro.thumbnail}` : require('../../../public/images/plpAssests/placeholder-image.png')} className="imgfullwidth" />
            </div>
            <div className="product-text">
              <p className="heading">{this.props.dataPro.productName}</p>
              <p className="description">
              ({this.props.dataPro.shortDescription})
              </p>
              {this.state.isFreeItem ? (<p className="price"><span className="discount-price">{FREE}</span></p>) : (this.minicartPrice())}
              {this.state.isFreeItem ? null : discountAccessoriesItem}
              <p className="quantity-shipping clearfix">
                <span className="quantity">
                  {QUANTITY}
                  <br />
                  <span className="textval">{this.props.dataPro.quantity}</span>
                </span>
                {this.shippingCharegeHandler()}
              </p>
            </div>
          </a>
        </div>
      </>
    );
  }
}

export default withRouter(MinicartItem);
