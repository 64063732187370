import React from "react";

import '../../../public/styles/customerLeads/GI_Promises_Banner.scss';
import  '../../../public/styles/static-pages/GI_Promises_Banner_All.scss';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {espotAPI,imagePrefix} from '../../../public/constants/constants';
import apiManager from '../../utils/apiManager';

class OurPromisesBannerData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      espotName: 'GI_Homepage_Our_Promises',
      title1:'',
      title2:'',
      title3:'',
      icon1:'',
      firstPromise:'',
      firstPromiseInfo1:'',
      firstPromiseInfo2:'',
      firstPromiseInfo3:'',
      firstPromiseInfo4:'',
      icon2:'',
      secondPromise:'',
      secondPromiseInfo1:'',
      secondPromiseInfo2:'',
      secondPromiseInfo3:'',
      secondPromiseInfo4:'',
      icon3:'',
      thirdPromise:'',
      thirdPromiseInfo1:'',
      thirdPromiseInfo2:'',
      thirdPromiseInfo3:'',
      titleClassName:'',
    };
  }
    
  getOurPromicesBannerData() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        const {data} = response || {}
            
        this.setState({
          title1: data && data.data.title1,
          title2: data && data.data.title2,
          title3: data && data.data.title3,
          icon1: data && data.data.icon1,
          firstPromise: data && data.data.firstPromise,
          firstPromiseInfo1: data && data.data.firstPromiseInfo1,
          firstPromiseInfo2: data && data.data.firstPromiseInfo2,
          firstPromiseInfo3: data && data.data.firstPromiseInfo3,
          firstPromiseInfo4: data && data.data.firstPromiseInfo4,
          icon2: data && data.data.icon2,
          secondPromise: data && data.data.secondPromise,
          secondPromiseInfo1: data && data.data.secondPromiseInfo1,
          secondPromiseInfo2: data && data.data.secondPromiseInfo2,
          secondPromiseInfo3: data && data.data.secondPromiseInfo3,
          secondPromiseInfo4: data && data.data.secondPromiseInfo4,
          icon3: data && data.data.icon3,
          thirdPromise: data && data.data.thirdPromise,
          thirdPromiseInfo1: data && data.data.thirdPromiseInfo1,
          thirdPromiseInfo2: data && data.data.thirdPromiseInfo2,
          thirdPromiseInfo3: data && data.data.thirdPromiseInfo3,
        });     
      })
      .catch(error => {
        this.setState({
          error,
          // isLoading: false,
        });
      });
          
  }
    
  componentDidMount() {
    this.getOurPromicesBannerData();
  }

      
  render(){
    {this.props.titleClassName!=''  ?
      this.state.titleClassName=`title ${this.props.titleClassName}`
      :
      this.state.titleClassName='title'
    } 
    return (

      <>
        <div className={this.props.mainClassName}>
          <div className="ck-title">
            <div className="title">{this.state.title1}</div>
            <div className="title"><b>{this.state.title2}</b></div>
            <div className="title">{this.state.title3}</div>
          </div>

          <div className={`${this.props.mainClassName}-div1`}>
            <LazyLoadImage class="gi-promise-img" src={imagePrefix + this.state.icon1} effect="blur" alt="img1" />
            <div className="ck1-title">

              <div className={this.state.titleClassName}>{this.state.firstPromise}</div>
              <div className="info">{this.state.firstPromiseInfo1}</div>
              <div className="info">{this.state.firstPromiseInfo2}</div>
              <div className="info">{this.state.firstPromiseInfo3}</div>
              <div className="info">{this.state.firstPromiseInfo4}</div>
            </div>
          </div>
          <div className={`${this.props.mainClassName}-div2`}>
            <LazyLoadImage class="gi-promise-img" src={imagePrefix + this.state.icon2} effect="blur" alt="img2" />
            <div className="ck2-title">

              <div className={this.state.titleClassName}>{this.state.secondPromise}</div>
              <div className="info">{this.state.secondPromiseInfo1}</div>
              <div className="info">{this.state.secondPromiseInfo2}</div>
              <div className="info">{this.state.secondPromiseInfo3}</div>
              <div className="info">{this.state.secondPromiseInfo4}</div>
            </div>
          </div>
          <div className={`${this.props.mainClassName}-div3`}>
            <LazyLoadImage class="gi-promise-img" src={imagePrefix + this.state.icon3} alt="img3" /> 
            <div className="ck3-title">

              <div className={this.state.titleClassName}>{this.state.thirdPromise}</div>
              <div className="info">{this.state.thirdPromiseInfo1}</div>
              <div className="info">{this.state.thirdPromiseInfo2}</div>
              <div className="info">{this.state.thirdPromiseInfo3}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


export default OurPromisesBannerData;

