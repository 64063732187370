import React from 'react';
import '../../../public/styles/checkout.scss';
import axios from 'axios';
import {
  storeId,
  minEMIAPI,
  espotAPI,
  imagePrefix,
} from '../../../public/constants/constants';
import appCookie from '../../utils/cookie';
import {isMobile, formatPrice} from '../../utils/utilityManager';
import {
  CART_TOTAL,
  ORDER_SUMMARY,
  PRODUCT_DISCOUNT,
  ORDER_DISCOUNT,
  ADDITIONAL_DISCOUNT,
  SHIPPING,
  TOTAL,
  EXCHANGE_DISCOUNT,
} from '../../constants/app/checkoutConstants';
import apiManager from '../../utils/apiManager';

export class OrderSummaryComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      minEMI: false,
      isPayBtnDisabled: false,
      discount_color: '',
      espotName: 'GI_CheckoutJourney',
      noCostEMIDiscount: this.props.noCostEMIDiscount,
      strikethroughText:'',
    }
  }

    callEMI = () => {
      if(`${parseInt(this.props.orderData.netAmount)+parseInt(this.props.orderData.noCostEMIDiscount)+parseInt(this.props.orderData.bankOfferDiscount)}`!='NaN' && `${parseInt(this.props.orderData.netAmount)+parseInt(this.props.orderData.noCostEMIDiscount)+parseInt(this.props.orderData.bankOfferDiscount)}`!=0 && `${parseInt(this.props.orderData.netAmount)+parseInt(this.props.orderData.noCostEMIDiscount)+parseInt(this.props.orderData.bankOfferDiscount)}`!='0'){
      const token = appCookie.get('accessToken');
      axios.get(`${minEMIAPI}/${parseInt(this.props.orderData.netAmount)+parseInt(this.props.orderData.noCostEMIDiscount)+parseInt(this.props.orderData.bankOfferDiscount)}`, {
        headers: { store_id: storeId, access_token: token },
      }).then((res) => {
        this.setState({
          minEMI: res.data.data.minEMIValue,
        })
      }).catch((err) => {
      })
      }
    }

    componentDidMount() {
      this.getEspotData();
    }

    getEspotData() {
      apiManager
        .get(espotAPI + this.state.espotName)
        .then(response => {
          const {data} = response || {};
          console.log(`data = ${JSON.stringify(data.data.congratstext_01)}`);
  
          this.setState({
            discount_color: data && data.data.discount_color,
            strikethroughText: data && data.data.strikethroughText,
          });
        })
        .catch(error => {
          console.log("no data.");
  
          this.setState({
  
          });
        });
  
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.isCheckSumAPIFailPro) {
        this.setState({
          isPayBtnDisabled: false,
        })
      }

    }

    onPayClick() {
      this.setState({
        isPayBtnDisabled: true,
      })
      this.props.initialBdpayment()
    }

    render() {
      if(this.state.minEMI == false) {
        this.callEMI()
      }
      this.state.noCostEMIDiscount=this.props.noCostEMIDiscount;
      return (
        <>
          {!isMobile() ? (<div className='col-md-4 col-sm-12 col-xs-12 orderSummary'>
            <div className='summary-border-div'>
              <div className='summaryHeading'><h4 className='headingOrder'>{ORDER_SUMMARY}</h4></div>
              <div className='listRow clearfix'>
                <div className='lefttext-box'>{CART_TOTAL}</div>
                <div className='righttext-box'>&#8377;{formatPrice(Math.round(this.props.orderData.totalAmount))}</div>
              </div>

              {!!this.props.orderData.productDiscount &&
              <div className='listRow clearfix discount-text'>
                <div className='lefttext-box' style={{color: this.state.discount_color}}>{PRODUCT_DISCOUNT}</div>
                <div className='righttext-box' style={{color: this.state.discount_color}}>- &#8377;{formatPrice(Math.round(this.props.orderData.productDiscount))}</div>
              </div>
              } 
              
              {!!this.props.orderData.orderDiscount &&
                <div className='listRow clearfix discount-text'>
                  <div className='lefttext-box' style={{color: this.state.discount_color}}>{ORDER_DISCOUNT}</div>
                  <div className='righttext-box' style={{color: this.state.discount_color}}>- &#8377;{formatPrice(Math.round(this.props.orderData.orderDiscount))}</div>
                </div>
              }

              {!!this.props.orderData.exchangeDiscount &&
                <div className='listRow clearfix discount-text'>
                <div className='lefttext-box' style={{color: this.state.discount_color}}>{EXCHANGE_DISCOUNT}</div>
                <div className='righttext-box' style={{color: this.state.discount_color}}>- &#8377;{formatPrice(Math.round(this.props.orderData.exchangeDiscount))}</div>
              </div>
              }

              {!!this.props.orderData.additionalDiscount && 
                <div className='listRow clearfix discount-text'>
                  <div className='lefttext-box' style={{color: this.state.discount_color}}>{ADDITIONAL_DISCOUNT}</div>
                  <div className='righttext-box' style={{color: this.state.discount_color}}>- &#8377;{formatPrice(Math.round(this.props.orderData.additionalDiscount))}</div>
                </div>
              }
              
              {this.props.orderData.noCostEMIDiscount !='' && this.props.orderData.noCostEMIDiscount !=0 && this.props.orderData.noCostEMIDiscount !='0' && 
                <div className='listRow clearfix discount-text'>
                  <div className='lefttext-box' style={{color: this.state.discount_color}}>No Cost EMI Discount</div>
                  <div className='righttext-box' style={{color: this.state.discount_color}}>- &#8377;{formatPrice(Math.round(this.props.orderData.noCostEMIDiscount))}</div>
                </div>
              }
              {this.props.orderData.bankOfferDiscount !='' && this.props.orderData.bankOfferDiscount !=0 && this.props.orderData.bankOfferDiscount !='0' && 
                <div className='listRow clearfix discount-text'>
                  <div className='lefttext-box' style={{color: this.state.discount_color}}>Bank Offer Discount</div>
                  <div className='righttext-box' style={{color: this.state.discount_color}}>- &#8377;{formatPrice(Math.round(this.props.orderData.bankOfferDiscount))}</div>
                </div>
              }
              <div className='listRow clearfix'>
                <div className='lefttext-box'>{SHIPPING}</div>
                <div className='righttext-box'>{this.props.orderData.shippingCharges == 0 ? 'Free'  : this.props.orderData.shippingCharges}
                  {this.props.orderData.shippingCharges == 0 && this.state.strikethroughText && this.state.strikethroughText!='' && '  '}
                  <span className='strikethrough'>{this.props.orderData.shippingCharges == 0 && this.state.strikethroughText && this.state.strikethroughText!='' && this.state.strikethroughText}</span>
                  </div>
                
                
                {/* <span className='strikethrough'>{this.props.orderData.shippingCharges == 0 && this.state.strikethroughText && this.state.strikethroughText!='' && this.state.strikethroughText}</span> */}
                {/* <span className='strikethrough'>{this.props.orderData.shippingCharges == 0 && this.state.strikethroughText && this.state.strikethroughText!='' && this.state.strikethroughText}</span> */}
              </div>
            </div>
            {/* <div className='freeshipping'></div> */}
            {/* <div className='startEmi clearfix'>
                <div className='emi-icon'><img src={EMI} alt='EMI'/></div>
                <div className='emitext'> {STARTING_FROM} &#8377;{Math.round(this.state.minEMI)} a month</div>
                <EmiInfo price={parseInt(this.props.orderData.netAmount)+parseInt(this.props.orderData.noCostEMIDiscount)}/>
              </div> */}
            {/* checkoutSection={this.state.checkoutSection}
                  checkoutSectionImg={this.state.checkoutSectionImg} */}
            <div className='nc-emi-info-with-disclaimer-container clearfix'>
              <div className='emi-icon'><img src={imagePrefix + this.props.checkoutSectionImg} alt='EMI'/></div>
              <div className='nc-emi-info-and-disclaimer-section'>
                {this.props.checkoutSection && this.props.checkoutSection.map((data,index) => <div className='nc-emi-info-and-disclaimer'>
                  <span className='nc-emi-heading'>{data.title}</span>
                  <span className='nc-emi-info'>{data.info}</span>
                </div>)
                  
                }

              </div>
              
            </div>
            <div className='totalBox clearfix'>
              <div className='totaltext'><span className='label-text'>{TOTAL}</span><br/>
                {/* <div className='savetext'><span className='save-label'>{YOU_SAVED}</span> <span className='saving-amount'>&#8377;{formatPrice(this.props.orderData.saving)}</span></div> */}
              </div>
              {/* <div className='totalAmount'>&#8377;{formatPrice(this.props.noCostEMIDiscount == ''?this.props.orderData.netAmount:this.props.orderData.netAmount-this.props.noCostEMIDiscount)}</div> */}
              <div className='totalAmount'>&#8377;{formatPrice(Math.round(this.props.orderData.netAmount))}</div>
            </div>              
              
            {/* <div className="payBtn">
                <button className={`btn-block btn-blackbg ${this.props.pay ? '' : 'disableddiv'}`} disabled={this.state.isPayBtnDisabled} onClick={this.onPayClick.bind(this)}>{PAY} &#8377;{formatPrice(this.props.orderData.netAmount)}</button>
              </div> */}
            {/* <div className="SecureCheckout">
                {SECURE_CHECKOUT}
              </div> */}
               
          </div>): ''}

          {isMobile() && this.props.currentStep==3 ? 
            <div className="col-md-8 checkout_wrapper">
              <div className='nc-emi-info-with-disclaimer-container clearfix'>
                <div className='emi-icon'><img src={imagePrefix + this.props.checkoutSectionImg} alt='EMI'/></div>
                <div className='nc-emi-info-and-disclaimer-section'>
                  {this.props.checkoutSection.map((data,index) => <div className='nc-emi-info-and-disclaimer'>
                    <span className='nc-emi-heading'>{data.title}</span>
                    <span className='nc-emi-info'>{data.info}</span>
                  </div>)
                
                  }

                </div>
            
              </div>
            </div>
            : ''
          }
          {/* {this.props.checkoutStep == 3 && isMobile() ? (
              <div className='checkout-btn-floater'>
              <div className='total-amount'><div className='net-amount-box'>&#8377;{formatPrice(this.props.orderData.netAmount)} <span className='total-amount-text'>{TOTAL_AMOUNT}</span></div></div>
              <div className='proceed-btn'><button className={`btn-block btn-blackbg ${this.props.pay ? '' : 'disableddiv'}`} disabled={this.state.isPayBtnDisabled} onClick={this.onPayClick.bind(this)}>{PAY}</button></div>
              </div>
            ) : ''} */}
        </>
      )
    }
}
