/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
// import * as $ from 'jquery';

import '../../../public/styles/customerLeads/kitchenRCBLeads.scss';
import ContentEspot from '../Primitives/staticContent';
import { espotAPI } from '../../../public/constants/constants';
import apiManager from '../../utils/apiManager';
import { isMobile } from '../../utils/utilityManager';
import FurnitureRCBLeadsForm from './furnitureRCBLeadsForm';

class FurnitureRCBLeads extends Component {
  constructor() {
    super();
    this.state = {
      banners: [],
      // pageName="kitchen-rcb",
    };
  }

  fetchBannerData = () => {
    apiManager
      .get(`${espotAPI}GI_Customer_Leads_Banner`)
      .then(response => {
        const { data } = response;
        if (
          data &&
          data.data &&
          data.data.banners &&
          data.data.banners.length > 0
        ) {
          this.setState({
            banners: data.data.banners,
          });
        }
      })
      .catch(error => {});
  };

  componentDidMount() {
    this.fetchBannerData();
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  }

  render() {
    const { banners } = this.state;
    const bannerCarouselSettings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <>
        <div id="consultForm">

          {!isMobile() ? <ContentEspot espotName="GI_FURNITURE_RCB_HERO_BANNER" />
            : <ContentEspot espotName="GI_FURNITURE_RCB_HERO_BANNER_MOBILE" />
          }
          <div className="split-right right">
            <div className="centered">
              <div className="form-container">
                <h1 className="heading">Request A callback</h1>
              </div>
              <FurnitureRCBLeadsForm id="kitchenRCBForm" pageName="Furniture-RCB"/>
            </div>
          </div>
        </div>
        
      </>
    );
  }
}

export default FurnitureRCBLeads;
