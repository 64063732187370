import React, { Component } from 'react';

import Button from 'react-bootstrap/lib/Button'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import Label from 'react-bootstrap/lib/Label'
import Col from 'react-bootstrap/lib/Col'
import FormControl from 'react-bootstrap/lib/FormControl'

import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { Link } from 'react-router-dom';
import remove from 'lodash/remove';
import {
  espotAPI,
  PinToCityAPI,
  customerRCBLeadsAPI,
} from '../../../public/constants/constants';
import {
  validateEmptyObject,
  validateFullName,
  regexName,
  regexMobileNo,
  regexEmail,
} from '../../utils/validationManager';
import {
  FIELDREQ_MSG,
  NAME_VALIDATION_MSG,
  INVALID_MOBILE_NUMBER,
  INVALID_EMAIL_ADDRESS,
  INVALID_PINCODE_FORMAT,
  NOT_VALID_PINCODE,
} from '../../constants/app/primitivesConstants';
import apiManager from '../../utils/apiManager';
import { triggerFormSubmissionGTEvent } from "../../utils/gtm";
import {
  REQUEST_CALLBACK_FORM_TYPE,
  REQUEST_CALLBACK_FORM_POSITION,
} from "../../constants/app/gtmConstants";
import {
  gtagHmeKitchenBtnClickTrackerTrigger,

} from '../../utils/campaigns';

const PINCODE_REGEX = /^\d{6}$/;
const animatedComponents = makeAnimated();
const Option = props => (
  <components.Option {...props}>
    <input type="checkbox" checked={props.isSelected} />
    <span />
    <label>{props.label}</label>
  </components.Option>
);

class FurnitureRCBLeadsForm extends Component {
  constructor() {
    super();
    this.state = {
      name: null,
      email1: null,
      phone1: null,
      city: null,
      state: null,
      pincode: null,
      requirementsOptions: [],
      requirements: [],
      nameErrMsg: null,
      emailErrMsg: null,
      phoneErrMsg: null,
      pincodeErrMsg: null,
      successMsg: null,
      errorMsg: null,
	  requirementsErrMsg: null,
      contactWhatsapp: false,
      contactMe: 'No',
	  fbPixelTriggered: false,
	  submitDisabled: false,
    };
  }

  toggleWhatsapp = e =>{
    this.setState({contactWhatsapp : e.target.checked});
    // if({contactWhatsapp }){
    //   contactMe = 'Yes';
    // }
    
  }

  fieldChangeHandler = e => {
    const { pincode } = this.state;
    if (
      e.target.name === 'pincode' &&
      e.target.value &&
      PINCODE_REGEX.test(e.target.value) &&
      pincode !== e.target.value
    ) {
      this.fetchCityFromPin(e.target.value);
    }
    this.setState({
      [e.target.name]: e.target.value !== '' ? e.target.value : null,
    });
  };

  keyPressHandler = e => {
    if (e.key === 'Enter') 
      this.formSubmitHandler();
    if(window.location.pathname=='/home-furniture-enquiries'){
      this.gtag_report_conversion();
    }
  };

  requirementsChangeHandler = (value, actionMeta) => {
    const { requirements } = this.state;
    const newRequirements = requirements.slice();
    if (actionMeta.action === 'select-option') {
      newRequirements.push(actionMeta.option);
    } else if (
      actionMeta.action === 'remove-value' ||
      actionMeta.action === 'pop-value'
    ) {
       remove(
        newRequirements,
        requirement => requirement.value === actionMeta.removedValue.value,
      );
    } else if (actionMeta.action === 'deselect-option') {
       remove(
        newRequirements,
        requirement => requirement.value === actionMeta.option.value,
      );
    } else if (actionMeta.action === 'clear') {
       remove(newRequirements, () => true);
    }
    this.setState({
      requirements: newRequirements,
    });
  };

  formValidationHandler = () => {
    this.setState({
      nameErrMsg: null,
      emailErrMsg: null,
      phoneErrMsg: null,
      pincodeErrMsg: null,
	  requirementsErrMsg: null,
      successMsg: null,
      errorMsg: null,
    });
    let isValid = true;
    const { name, email1, phone1, pincode, city, requirements } = this.state;
    if (!validateEmptyObject(name)) {
      this.setState({ nameErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!validateFullName(name) || !regexName.test(name)) {
      this.setState({ nameErrMsg: NAME_VALIDATION_MSG });
      isValid = false;
    }
    if (!validateEmptyObject(email1)) {
      this.setState({ emailErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!regexEmail.test(email1)) {
      this.setState({ emailErrMsg: INVALID_EMAIL_ADDRESS });
      isValid = false;
    }
    if (!validateEmptyObject(phone1)) {
      this.setState({ phoneErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!regexMobileNo.test(phone1)) {
      this.setState({ phoneErrMsg: INVALID_MOBILE_NUMBER });
      isValid = false;
    }
    if (!validateEmptyObject(pincode)) {
      this.setState({ pincodeErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!PINCODE_REGEX.test(pincode) || !validateEmptyObject(city)) {
      this.setState({ pincodeErrMsg: INVALID_PINCODE_FORMAT });
      isValid = false;
    }
    if (!requirements || requirements.length === 0) {
      this.setState({
        requirementsErrMsg: ATLEAST_ONE_REQUIREMENT,
      });
      isValid = false;
    }
    return isValid;
  };

  formSubmitHandler = () => {
    triggerFormSubmissionGTEvent(
      REQUEST_CALLBACK_FORM_TYPE,
      REQUEST_CALLBACK_FORM_POSITION[this.props.sourcePage || 'default'],
    );
    if (!this.formValidationHandler()) return;

    this.setState({
      submitDisabled: true,
    });
    setTimeout(() => {
      this.setState({
        submitDisabled:false,
      })
    }, 5000);
    const {
      name,
      phone1,
      email1,
      pincode,
      city,
      state,
      requirements,
      contactWhatsapp,
      pageName,
    } = this.state;
    let requirementsStr = requirements.map(rqrmnt => rqrmnt.value).join('||');
    requirementsStr = requirementsStr.replace("&", "and");
    const urlSearchParams = new URLSearchParams(
      window.location.search.substring(1),
    );
    const data = {
      name,
      phone1,
      email1,
      pincode,
      city,
      state,
      contactWhatsapp,
	  requirements: requirementsStr,
      pageName: this.props.pageName,
      utmPlacement: urlSearchParams.get('utm_placement'),
      utmMedia: urlSearchParams.get('utm_medium'),
      utmKeyword: urlSearchParams.get('utm_keyword'),
      utmAddgroup: urlSearchParams.get('utm_adgroup'),
      utmCampaign: urlSearchParams.get('utm_campaign'),
      utmSource: urlSearchParams.get('utm_source'),
    };
    apiManager
      .post(customerRCBLeadsAPI, data)
      .then(response => {
        const resData = response.data.data;
        if (resData.customerQuerySaved === true) {
          this.setState({
            name: '',
            phone1: '',
            email1: '',
            pincode: '',
            city: '',
            state: '',
            requirements: [],
            successMsg:
              'Thank You! Your nearest store representative will reach out to you shortly.',
          });
        } else {
          this.setState({
            errorMsg: 'Unable to save your data. Sorry for inconvenience.',
          });
        }
      })
      .catch(() => {
        this.setState({
          errorMsg: 'Unable to save your data. Sorry for inconvenience.',
        });
      });
  };

  fetchCityFromPin = pincode => {
    if (!pincode) return;
    apiManager
      .get(PinToCityAPI + pincode)
      .then(response => {
        const { data } = response;
        if (data.data && data.data.city) {
          this.setState({
            pincodeErrMsg: null,
            city: data.data.city,
            state: data.data.state,
          });
        } else {
          this.setState({ pincodeErrMsg: INVALID_PINCODE_FORMAT,city: '',
            state: '' });
        }
      })
      .catch(error => {
        this.setState({ pincodeErrMsg: INVALID_PINCODE_FORMAT,city: '',
            state: '' });
      });
  };

  fetchRequirementsOptions = () => {
    apiManager
      .get(`${espotAPI}GI_Customer_Leads_Rqrmnts`)
      .then(response => {
        const { data } = response;
        if (data && data.data) {
          this.setState({
            requirementsOptions: data.data.requirements || [],
          });
        }
      })
      .catch(error => {});
  };

  toggleHandler = () => {
    this.setState({ successMsg: null });
  };

  componentDidMount() {
    this.fetchRequirementsOptions();
  }

  gtag_report_conversion(url) {
    const callback = function () {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-10869903464');
    gtag('event', 'conversion', 
      {
        'send_to': 'AW-10869903464/lffGCKek4KsDEOiglr8o',
        'event_callback': callback,
      });
    gtag('event', 'conversion', {
      'send_to': 'AW-10869903464/UpBiCM_34asDEOiglr8o',
      'event_callback': callback,
    });
  }
  
  render() {
    gtagHmeKitchenBtnClickTrackerTrigger();
	 try {
      if (!this.state.fbPixelTriggered && fbq) {
        fbq('init', '971812680435038');
        fbq('track', 'PageView');
        this.setState({ fbPixelTriggered: true });
      }
    } catch (error) {
    }
    const {
      name,
      email1,
      phone1,
      city,
      pincode,
      requirements,
      nameErrMsg,
      emailErrMsg,
      phoneErrMsg,
      pincodeErrMsg,
	  requirementsErrMsg,
      successMsg,
      errorMsg,
      requirementsOptions,
      contactWhatsapp,
    } = this.state;
    return (
      <>
        <Col xs={12}>
          <FormGroup>
            <Label>Name *</Label>
            <FormControl
              type="text"
              name="name"
              className="form-control"
              placeholder="Enter Full Name"
              value={name}
              onChange={this.fieldChangeHandler}
              onKeyPress={this.fieldChangeHandler}
            />
            <p className="error-msg">{nameErrMsg}</p>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label>Email *</Label>
            <FormControl
              type="text"
              name="email1"
              className="form-control"
              placeholder="Enter Email"
              value={email1}
              onChange={this.fieldChangeHandler}
              onKeyPress={this.fieldChangeHandler}
            />
            <p className="error-msg">{emailErrMsg}</p>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label>Mobile *</Label>
            <FormControl
              type="text"
              name="phone1"
              className="form-control"
              placeholder="Enter Mobile Number"
              value={phone1}
              onChange={this.fieldChangeHandler}
              onKeyPress={this.fieldChangeHandler}
            />
            <p className="error-msg">{phoneErrMsg}</p>
          </FormGroup>
        </Col>
        <div className="wa-check">
          <Col xs={12} class="enquiry_campaign_whatsapp">
            <FormGroup>
              <label className="wa-checks"><input type="checkbox" checked={contactWhatsapp} onChange={this.toggleWhatsapp}/>
              You may contact me via Email, Whatsapp, SMS.</label>
            </FormGroup>
          </Col>
        </div>
        <Col xs={6}>
          <FormGroup>
            <Label>Pincode *</Label>
            <FormControl
              type="text"
              name="pincode"
              className="form-control"
              placeholder="Enter Pincode"
              value={pincode}
              onChange={this.fieldChangeHandler}
              onKeyPress={this.fieldChangeHandler}
            />
            <p className="error-msg">{pincodeErrMsg}</p>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label>City *</Label>
            <FormControl
              type="text"
              disabled
              name="city"
              className="form-control"
              placeholder="City"
              value={city}
            />
            <p className="error-msg" />
          </FormGroup>
        </Col>
        
        <Col xs={12}>
          <FormGroup className="requirements-select">
            <Label>Requirements *</Label>
            <Select
              components={Object.assign({}, animatedComponents, {
                Option,
              })}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isMulti
              value={requirements}
              options={requirementsOptions}
              placeholder="Select"
              onChange={this.requirementsChangeHandler}
              classNamePrefix="leads-rqrmnts"
            />
            <p className="error-msg">{requirementsErrMsg}</p>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <h6 className="message">
              {errorMsg && <span className="failed">{errorMsg}</span>}
            </h6>
            <Button
              className="submit-btn btn-block btn-bg"
              onClick={this.formSubmitHandler}
			  disabled={this.state.submitDisabled}
          id = {this.props.pageName ==='Furniture-RCB' ? 'rcb-submit':'bac-kitchen'}
            >
              {this.props.pageName ==='Furniture-RCB' ? 'Submit':'BOOK A CONSULTATION'}
              
            </Button>
          </FormGroup>
        </Col>
           
        <Col xs={12}>
          <h6 className="disclaimer">
            By submitting this form, you agree to the{' '}
            <Link to="/privacy-policy">privacy policy</Link> &{' '}
            <Link to="/termsconditions">terms and conditions</Link>
          </h6>
        </Col>
        {/* {successMsg ? window.location.pathname=`${prefixRoute}/testpage`:null} */}
        {successMsg &&
         window.location.pathname == '/home-furniture-enquiries-north' ? 
          window.location.pathname = '/thank-you-north' : null
         ||
		 successMsg &&
         window.location.pathname == '/home-furniture-enquiries' ? 
            window.location.pathname = '/thank-you-furniture' : null
		 ||
         successMsg &&
         window.location.pathname == '/home-furniture-enquiries-northwest' ? 
              window.location.pathname = '/thank-you-northwest' : null
         ||
         successMsg &&
         window.location.pathname == '/home-furniture-enquiries-northeast' ? 
                window.location.pathname = '/thank-you-northeast' : null
         ||
         successMsg &&
         window.location.pathname == '/home-furniture-enquiries-southatk' ? 
                  window.location.pathname = '/thank-you-southatk' : null
         ||
         successMsg &&
         window.location.pathname == '/home-furniture-enquiries-southtk' ? 
                    window.location.pathname = '/thank-you-southtk' : null
         ||
         successMsg &&
         window.location.pathname == '/home-furniture-enquiries-east' ? 
                      window.location.pathname = '/thank-you-east' : null
         ||
         successMsg &&
         window.location.pathname == '/home-furniture-enquiries-west' ? 
                        window.location.pathname = '/thank-you-west' : null
         ||
         successMsg &&
         window.location.pathname == '/home-furniture-enquiries-central' ? 
                          window.location.pathname = '/thank-you-central' : null
         ||
		 successMsg &&
         window.location.pathname == '/modular-kitchen-enquiries' ? 
                            window.location.pathname = '/thank-you-modular' : null
		 ||
         successMsg &&
         window.location.pathname == '/modular-kitchen-enquiries-north' ? 
                              window.location.pathname = '/thank-you-northm' : null
         ||
         successMsg &&
         window.location.pathname == '/modular-kitchen-enquiries-south' ? 
                                window.location.pathname = '/thank-you-southm' : null
         ||
         successMsg &&
         window.location.pathname == '/modular-kitchen-enquiries-west' ? 
                                  window.location.pathname = '/thank-you-westm' : null
         ||
         successMsg &&
         window.location.pathname == '/modular-kitchen-enquiries-east' ? 
                                    window.location.pathname = '/thank-you-eastm' : null
        }
        {/* {
          successMsg ? window.location.pathname = '/thank-you' : null
        } */}
      </>
    );
  }
}

export default FurnitureRCBLeadsForm;
