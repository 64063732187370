import React from 'react';
import JsonLd from './JsonLd';

class ListingFaqSchema extends React.Component{


  render(){
    const data = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
    }
    const entity = []
    this.props.faqs.map((qa,key) =>{
      const entityJson = {
        "@type": "Question",
        "name": `${qa.question}`,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `${qa.answer}`,
        },

      }
      entity.push(entityJson)
    })
    data.mainEntity = entity;
    return(
      <>
        <JsonLd data={data} />
      </>
    )
  }
}

export default ListingFaqSchema;
