import React from "react";
import "../../../public/styles/headerContainer/logo.scss";
import logo from './GILogoDesktop.svg';

const Logo = () => (
  <img
    src={logo}
    alt="Godrej Interio"
    style={{minHeight : '2vh', minWidth:'2vh'}}
  />
);

export default Logo;
