import React from "react";
import "../FranchiseEnquiryComp/formPanel.scss";

import FormImg from "../FranchiseEnquiryComp/FormImg";
import FormHeader from "../FranchiseEnquiryComp/FormHeader";
import FranchiseForm from "../FranchiseEnquiryComp/FranchiseForm/FranchiseForm";

import {espotAPI} from '../../../public/constants/constants';
import apiManager from '../../utils/apiManager';
import FurnitureRCBHeader from "../KitchensComp/FurnitureRCBHeader";


class FranchiseEnquiry extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      espotName: 'GI_FRANCHISE_ENQUIRY',
      imagePath1: '',
      imagePath2: '',
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      text5: '',
    };
  }

  getFranchiseEnquireData() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        const {data} = response || {}
        this.setState({
          imagePath1: data && data.data.imagePath1,
          imagePath2: data && data.data.imagePath2,              
          text1:data && data.data.text1,
          text2:data && data.data.text2,
          text3: data && data.data.text3,
          text4: data && data.data.text4,
          text5: data && data.data.text5,
        });
            
            
      })
      .catch(error => {
        this.setState({
          error,
          // isLoading: false,
        });
      });
          
  }
    
  componentDidMount() {
    this.getFranchiseEnquireData();
  }

      
  render(){ 
    return (
      <div className="FranchiseEnquiry">
        <FurnitureRCBHeader />
        <div className="panel-container">
          <div className="img-container">
            <FormImg
              imagePath1={this.state.imagePath1} imagePath2={this.state.imagePath2} text1={this.state.text1} 
              text2={this.state.text2} text3={this.state.text3} text4={this.state.text4} text5={this.state.text5}/>
          </div>

          <div className="form-container">
            <FormHeader
              text1={this.state.text1} text2={this.state.text2} text3={this.state.text3} 
              text4={this.state.text4} text5={this.state.text5} />
            <FranchiseForm />
          </div>
        </div>
      </div>
    );
  }
}


export default FranchiseEnquiry;

