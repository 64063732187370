import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import apiManager from '../../utils/apiManager';
import appCookie from '../../utils/cookie';
import { isMobile } from '../../utils/utilityManager';
// import GoogleButton from 'react-google-button'
import {
    facebookAppId,
    generateOTPAPI,
    googleClientId,
    imagePrefix,
    socialLoginAPI,
    validateOTPAPI,
} from '../../../public/constants/constants';
import {
    accessTokenCookie,
} from '../../../public/constants/constants';
// import Google from '../../../public/images/google.png';
import '../../../public/styles/login/otplogin.scss';
import { FIELDREQ_MSG, JOIN_US, OTP_USER_NOT_EXIST, VALID_EMAILNUMBER_MSG } from '../../constants/app/primitivesConstants';
// import { useGoogleLogin } from '@react-oauth/google';
import LoadingIndicator from '../../utils/loadingIndicator';
import { regexEmail, regexMobileNo, regexMobileNoOTP, validateEmptyObject } from '../../utils/validationManager';
import axios from 'axios';

const WelcomeBack = (props) => {
    const [show, setShow] = useState(true);
    const [message, setMessage] = useState(null);
    const [otp, setOtp] = useState(['', '', '', '']);
    const [isOTPRequested, setIsOTPRequested] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const windowModelRef = useRef(null);
    const modalRef = useRef(null);
    const [isOutSide, setIsOutSide] = useState(false);
    const [user, setUser] = useState([]);
    const otpRefs = useRef([]);
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isIncorrectOTP, setIsIncorrectOTP] = useState(false)
    const [isOTPRequestClicked, setIsOTPRequestClicked] = useState(false)
    const [isresend,setIsresend] = useState(false)
    const isotpdisabled = appCookie.get('loginotpdisableUntil')
    const [isotpexceeded,setIsotpexceeded] = useState(false)

    // const shouldDisableButton = () => {
    //   if (isotpdisabled) {
    //     const now = new Date().getTime();
    //     const istimedout = now < parseInt(isotpdisabled, 10);
    //     istimedout && setErrorMessage("Please retry after 5 mins, since you have exceeded the current limit of 3 OTP requests.")
    //     return istimedout
    //   }
    //   appCookie.delete("loginotpdisableUntil")
    //   return false;
    // };

    // useEffect(()=>{
    //   const isdisabled = shouldDisableButton()
    //   setIsotpexceeded(isdisabled)
    // },[isotpdisabled])

    useEffect(
        () => {
            if (user && user.access_token) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        // setProfile(res.data);
                        if (res.status === 200 && res.data) {
                            setLoading(true)
                            const data = {
                                "isSocialLogin": true,
                                "lastName": res.data.family_name,
                                "firstName": res.data.given_name,
                                "authorizationProvider": "google",
                                "id": res.data.email,
                                "accessToken": user.access_token,
                                "email": res.data.email,
                                "storeId": "10151"
                            }

                            const iv = "1234567887654321";
                            const skey = 'cBnfZX3atf4PQRggFAGQjzCrZ9XeFgAa';
                            import('crypto').then(crypto => {
                                const cipher = crypto.createCipheriv('aes-256-cbc', skey, iv);
                                const encodedText = Buffer.from(JSON.stringify(data));
                                let encryptedPassword = cipher.update(encodedText, 'utf8', 'base64');
                                encryptedPassword += cipher.final('base64')
                                const preSeal = encryptedPassword + skey;
                                const tempData = { epwd: encryptedPassword }

                                apiManager
                                    .post(socialLoginAPI, tempData)
                                    .then(response => {
                                        setLoading(false)
                                        if (response.status === 200) {
                                            appCookie.set('isLoggedIn', true, 365 * 24 * 60 * 60 * 1000);
                                            appCookie.set(accessTokenCookie, response.data.data.access_token, 365 * 24 * 60 * 60 * 1000);
                                            appCookie.set('userID', response.data.data.userID, 365 * 24 * 60 * 60 * 1000);
                                            window.location.reload();
                                        }
                                    })
                                    .catch(error => {
                                        setLoading(false)
                                    });
                            })
                        }
                    })
                    .catch((err) => console.log(err));
            }
        },
        [user]
    );

    useEffect(() => {
        if (otp.every(field => field !== "")) {
            setLoading(true)
            const otpValue = otp.join("")
            const data = {
                "logonOTP": otpValue,
                "logonId": inputValue,
            }
            const body = {
                user_id: inputValue,
                otp: otpValue,
                otpLogin: "true"
            }
            apiManager.post(validateOTPAPI, body)
                .then((res) => {
                    if (res.status === 200 && res.data.status === "success") {
                        const iv = "1234567887654321";
                        const skey = 'cBnfZX3atf4PQRggFAGQjzCrZ9XeFgAa';
                        import('crypto').then(crypto => {
                            const cipher = crypto.createCipheriv('aes-256-cbc', skey, iv);
                            const encodedText = Buffer.from(JSON.stringify(data));
                            let encryptedPassword = cipher.update(encodedText, 'utf8', 'base64');
                            encryptedPassword += cipher.final('base64')
                            const preSeal = encryptedPassword + skey;
                            const tempData = { epwd: encryptedPassword }

                            apiManager
                                .post(socialLoginAPI, tempData)
                                .then(response => {
                                    setLoading(false)
                                    if (response.status === 200) {
                                        appCookie.set('isLoggedIn', true, 365 * 24 * 60 * 60 * 1000);
                                        appCookie.set(accessTokenCookie, response.data.data.access_token, 365 * 24 * 60 * 60 * 1000);
                                        appCookie.set('userID', response.data.data.userID, 365 * 24 * 60 * 60 * 1000);
                                        window.location.reload();
                                    }
                                })
                                .catch((res) => {
                                    const response = res.response
                                    setLoading(false)
                                    if (response.status === 400) {
                                        if (response.data.error && response.data.error.error_key === "user_does_not_exist") {
                                            otpRefs.current[0].focus();
                                            setOtp(["", "", "", ""])
                                            setErrorMessage(OTP_USER_NOT_EXIST.replace('{type}', inputValue.includes('@') ? 'Email' : 'Phone number'))
                                            setIsOTPRequested(false)
                                            setIsIncorrectOTP(false)
                                        }
                                        if (response.data.error && response.data.error.error_key === "otp_incorrect") {
                                            otpRefs.current[0].focus();
                                            setOtp(["", "", "", ""])
                                            setIsIncorrectOTP(true)
                                        }
                                    }
                                    else{
                                        otpRefs.current[0].focus();
                                        setOtp(["", "", "", ""])
                                        setIsOTPRequested(false)
                                        setIsIncorrectOTP(false)
                                        setErrorMessage('Error in Log in, Please try again after sometime.')
                                        setLoading(false)
                                    }
                                });
                        })
                    }
                })
                .catch((response) => {
                    const res = response.response
                    if(res.status === 400 && res.data.error.error_key === "otp_incorrect_limit_exceed") {
                        let duration = appCookie.get('otplimit')
                        const disableUntil = new Date(new Date().getTime() + parseInt(duration));
                        appCookie.set('loginotpdisableUntil', disableUntil.getTime(), { expires: parseInt(duration) / (1000 * 60 * 60 * 24) });
                        setIsIncorrectOTP(true)
                        setIsOTPRequested(false)
                        setLoading(false)
                        setErrorMessage(res.data.error.error_message)
                        otpRefs.current[0].focus();
                        setOtp(["", "", "", ""])
                    }
                    else {
                        setIsIncorrectOTP(true)
                        otpRefs.current[0].focus();
                        setOtp(["", "", "", ""])
                        setLoading(false)
                    }
                })
        }
    }, [otp])

    const handleClose = () => {
        props.resetCallbackPro();
        let closePopup = new Event("closePopUpWishlist",{ bubbles: true,});
        closePopup.simulated = true;
        document.dispatchEvent(closePopup);
        setShow(false);
        setMessage(null);
    };

    const clickedOnRegister = () => {
        props.callbackPro(false);
        setShow(false);
        setMessage(null);

        if (isMobile()) {
            let registerPopupEvent = new Event('registerPopupEventMobile', {
                bubbles: true,
            });
            registerPopupEvent.simulated = true;
            document.dispatchEvent(registerPopupEvent);
        } else {
            let registerPopupEvent = new Event('registerPopupEvent', {
                bubbles: true,
            });
            registerPopupEvent.simulated = true;
            document.dispatchEvent(registerPopupEvent);
        }
    };

    const trackMouseUp = (event) => {
        const winWidth = window.innerWidth / 2;
        const comWidth = modalRef.current.offsetWidth / 2;

        const winHeight = window.innerHeight / 2;
        const comHeight = modalRef.current.offsetHeight / 2;

        if (
            (event.clientX < winWidth - comWidth && isOutSide) ||
            (event.clientX > winWidth + comWidth && isOutSide) ||
            (event.clientY < winHeight - comHeight && isOutSide) ||
            (event.clientY > winHeight + comHeight && isOutSide)
        ) {
            props.resetCallbackPro();
            let closePopup = new Event("closePopUpWishlist",{ bubbles: true,});
            closePopup.simulated = true;
            document.dispatchEvent(closePopup);
            setShow(false);
            setMessage(null);
        }
    };

    const trackMouseDown = (event) => {
        setIsOutSide(false);
        const winWidth = window.innerWidth / 2;
        const comWidth = modalRef.current.offsetWidth / 2;

        const winHeight = window.innerHeight / 2;
        const comHeight = modalRef.current.offsetHeight / 2;

        if (
            event.clientX < winWidth - comWidth ||
            event.clientX > winWidth + comWidth ||
            event.clientY < winHeight - comHeight ||
            event.clientY > winHeight + comHeight
        ) {
            setIsOutSide(true);
        }

        if (isMobile()) {
            setIsOutSide(false);
        }
    };

    const handleSendOtp = (isresend) => {
        setIsresend(false)
        setIsOTPRequestClicked(true)
        setOtp(["", "", "", ""])
        const input = String(inputValue);
        const firstChar = Number(inputValue.charAt(0));
        setIsIncorrectOTP(false)
        setErrorMessage('')
        if (!validateEmptyObject(inputValue)) {
            setErrorMessage(FIELDREQ_MSG)
            setIsOTPRequestClicked(false)
            return
        } else if (!input.includes('@') && Number.isInteger(firstChar)) {
            if ((!regexMobileNo.test(inputValue)) || ((inputValue.length) < 10) || ((inputValue.length) > 10) || !regexMobileNoOTP.test(inputValue)) {
                setErrorMessage(VALID_EMAILNUMBER_MSG)
                setIsOTPRequestClicked(false)
                return
            }
        } else if (!regexEmail.test(inputValue)) {
            setErrorMessage(VALID_EMAILNUMBER_MSG)
            setIsOTPRequestClicked(false)
            return
        }
        isresend && setLoading(true)
        const reqBody = {
            user_id: inputValue,
            resend: isresend ? "true" : "false",
            otpLogin: "true"
        }
        apiManager.post(generateOTPAPI, reqBody)
            .then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    isresend && setIsresend(true)
                    setIsOTPRequested(true);
                    setIsIncorrectOTP(false)
                    setIsOTPRequestClicked(false)
                }

            })
            .catch(error => {
                const response = error.response
                if (response.status === 400) {
                    setIsOTPRequested(false)
                    setIsIncorrectOTP(false)
                    setLoading(false)
                    setIsOTPRequestClicked(false)
                    if (response.data.error.error_key === "user_does_not_exist") {
                        setErrorMessage(<>{inputValue.includes('@') ? "Email" : "Mobile"} entered is not registered with us. <a style={{ cursor: "pointer" }} onClick={clickedOnRegister}>Click here to register</a></>)
                    }
                    else if(response.data.error.error_key === "ERROR_RESEND_OTP_COUNT") {
                        let duration = appCookie.get('otplimit')
                        const disableUntil = new Date(new Date().getTime() + parseInt(duration));
                        appCookie.set('loginotpdisableUntil', disableUntil.getTime(), { expires: parseInt(duration) / (1000 * 60 * 60 * 24) });
                        setErrorMessage(response.data.error.error_message)
                    }
                    else {
                        setErrorMessage(response.data.error.error_message)
                    }
                }
                else{
                    setLoading(false)
                    setIsOTPRequested(false)
                    setIsIncorrectOTP(false)
                    setIsOTPRequestClicked(false)
                    setErrorMessage("Error in Sending OTP. Please try again in some time")
                }
            })
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setErrorMessage('')
        setIsOTPRequested(false)
        setIsIncorrectOTP(false)
    };

    const handleOtpChange = (index, value) => {


        // Allow only single-digit numbers (0-9) and empty string
        if (value === '' || /^[0-9]$/.test(value)) {
            // setValue(inputValue);

            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            setIsIncorrectOTP(false)
            setIsresend(false)
            if (value !== '' && index < otpRefs.current.length - 1) {
                otpRefs.current[index + 1].focus();
            }
        }
        else {
            otpRefs.current[index].value = ""
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'ArrowLeft' && index > 0) {
            otpRefs.current[index - 1].focus();
            e.preventDefault();
        } else if (e.key === 'ArrowRight' && index < otp.length - 1) {
            otpRefs.current[index + 1].focus();
            e.preventDefault();
        } else if (e.key === 'Backspace' && !otp[index] && index > 0) {
            otpRefs.current[index - 1].focus();
        } else if (e.key === 'Delete' && !otp[index] && index < otp.length - 1) {
            otpRefs.current[index + 1].focus();
        }
    };

    // const handleGoogleLogin = useGoogleLogin({
    //   onSuccess: (codeResponse) => setUser(codeResponse),
    //   onError: (error) => console.log('Login Failed:', error)
    // });

    let displayMessage = null;
    if (message) {
        displayMessage = <p>{message}</p>;
    }
    const renderContent = <div className={`${!isMobile() ? "login-otp-modal-content" : ""}`} ref={modalRef}>
        {loading && <div className='loading-otp-form'><LoadingIndicator /></div>}
        {displayMessage}
        {/* {isMobile() && !props.isOTPCheckout && (
      <div className='joinUs mobile-login-banner'>
        {props.couponData && props.couponData.mobile && props.couponData.mobile[0] && props.couponData.mobile[0] !== '' ? (
          <img src={imagePrefix + props.couponData.mobile[0]} alt='Coupon' />
        ) : (
          <h3 className='joinus-heading'>{JOIN_US}</h3>
        )}
      </div>
    )} */}
        <Modal.Header closeButton className={`${props.isOTPCheckout ? 'modal-header-checkout' : ""}`}>
            <Modal.Title>Login</Modal.Title>
            <p className='registerHere'>
                <span className='labelText'>Don't have an account? </span>
                <Button className='registerNow' onClick={clickedOnRegister}>
                    Click here to sign up
                </Button>
            </p>
        </Modal.Header>
        {!props.isOTPCheckout && <hr className='modal-header-divider' />}
        <div className={`login-container ${props.isOTPCheckout ? 'login-container-checkout' : ""} ${isMobile() ? 'login-container-mobile' : ''}`}>
            {!props.isOTPCheckout && <div className={`socialLogin ${isMobile() ? 'socialLogin-mobile' : ''}`}>
                {/* <button className='btn-white' onClick={() => handleGoogleLogin()}>
          <span className='icon-img'>
            <img className='icon' src={Google} alt='Google' />
            Click here to sign up
          </span>
        </button> */}
                {/* <GoogleButton
          onClick={() => { handleGoogleLogin()}}
        /> */}
                {/* <button className='btn-white' onClick={() => { }}>
          <span className='icon-img'>
            <img className='icon' src={Apple} alt='Apple' />
          </span>
        </button> */}
                {/* <FacebookLogin
          appId={facebookAppId}
          render={renderProps => ( */}
                {/* <button
              className="btn-white btn-fb"
              onClick={()=>{}}
            >
              <span className="icon-img">
                <img className="icon" src={Facebook} alt="Facebook" />
              </span>
            </button> */}
                {/* )} */}
                {/* // autoLoad
          fields="name,email,picture"
          cssClass="btn-white"
          onClick={(res) => { console.log("facebook res", res) }}
          callback={(e) => { console.log("facebook### callback", e) }}
          disableMobileRedirect
        /> */}


                {/* <button className='btn-white' onClick={() => { }}>
          <span className='icon-img'>
            <img className='icon' src={Microsoft} alt='Microsoft' />
          </span>
        </button> */}
            </div>}
            {/* <p className='divider'>Or</p> */}
            <div className='input-field-div'>
                <input
                    type='text'
                    placeholder='Please enter your mobile number/email'
                    value={inputValue}
                    onChange={handleInputChange}
                    disabled={isOTPRequested}
                />
            </div>
            {errorMessage && errorMessage !== "" && <p className='input-field-error'>{errorMessage}</p>}
            {!isOTPRequested && (
                <div className={`requestotp-container ${errorMessage && errorMessage !== "" ? "requestotp-error-msg-container" : ""}`}>
                    <button
                        className={`request-otp-btn ${isotpexceeded || isOTPRequestClicked ? "disable-otp-btn" : ""}`}
                        onClick={() => handleSendOtp(false)}
                        disabled={isotpexceeded || isOTPRequestClicked}
                    >
                        {isOTPRequestClicked ? 'Sending...' : 'Request OTP'}
                    </button>
                    <p>
                        After requesting for it, you will receive an OTP (One time password) to your
                        given email id / mobile number to verify your details.
                    </p>
                </div>
            )}
            {isOTPRequested && (
                <>
                    <div className={`enter-otp-text`}>{"Enter OTP below"}</div>
                    <div className='otp-inputs'>
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                type='text'
                                maxLength='1'
                                value={digit}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                ref={(el) => (otpRefs.current[index] = el)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                inputmode="numeric"
                            />
                        ))}
                    </div>
                    {isIncorrectOTP && <div className='enter-otp-text input-field-error'>OTP Incorrect</div>}
                    {isresend && <div className={`enter-otp-text ${isresend ? "enter-otp-text-resend" : ""}`}>OTP has been resent</div>}
                    <p className={`info ${(isIncorrectOTP || isresend) ? "error-info" : ""}`}>
                        To continue please complete the verification step by typing the OTP received on {inputValue.includes('@') ? `your email inbox: ${inputValue}` : `SMS on your phone number: +91-${inputValue}`}
                    </p>
                    <div className='otp-recieve'>
                        {!isIncorrectOTP ? <><p><strong>Didn't receive OTP?</strong></p>
                            <p className='registerHere'>
                                <span>Please check your junk folder or </span>
                                <span className='resentotp-span' onClick={() => handleSendOtp(true)}><strong>Resend OTP</strong></span>
                            </p></> : <>
                            <p className='registerHere registerHere-incorrect'>
                                <span>OTP unverified please</span>
                                <span className='resentotp-span' onClick={() => handleSendOtp(true)}><strong> Resend new OTP </strong></span>  <span> or check your spam email</span>
                            </p>
                        </>}

                    </div>
                </>
            )}
        </div>
    </div>
    return (
        <div id='abc' onMouseUp={trackMouseUp} onMouseDown={trackMouseDown}>
            {
                props.isOTPCheckout ? <div className={`welcomeBack otp-modal otp-checkout ${isMobile() ? 'welcomeBack-mobile login-mobile-otp' : ''}`}>{renderContent}</div> :
                    <Modal
                        style={{ background: 'rgba(0, 0, 0, 0.5)', zIndex: '2147483648' }}
                        ref={windowModelRef}
                        className={`welcomeBack otp-modal ${isMobile() ? 'welcomeBack-mobile login-mobile-otp' : ''}`}
                        size='lg'
                        animation={false}
                        show={show}
                        onHide={handleClose}
                        backdrop={false}
                    >{renderContent} </Modal>
            }
        </div>
    );
};

export default WelcomeBack;
