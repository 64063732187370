import React from 'react';
import {imagePrefix,addToCart} from '../../../public/constants/constants';
import appCookie from '../../utils/cookie';

class kalista extends React.Component {

  componentDidMount = () => {
    const token = appCookie.get('accessToken');
    localStorage.setItem('accessToken' , token);
    localStorage.setItem('addToCartAPI' , addToCart);  
  }

   render(){
      return (
        <iframe title="unity-frame" 
        src={imagePrefix + "/B2C/productconfigurator/Kalista/index.html"}
        // src="https://localhost:5002/imagestore/B2Ctemp/Kalista/index.html" 
        style={{height: "100vh", width: "100%"}} />
      );
   }
}
export default kalista;
