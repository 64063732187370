/**
 *
 * KitchenRCB
 *
 */
import React from 'react';

// import CustomerLeadsForm from;
import ContentEspot from "../Primitives/staticContent";
// import WhatGoes from '../../components/KitchensComp/WhatGoes';
import FurnitureRCBLeads from "../CustomerLeads/furnitureRCBLeads";
import '../../../public/styles/customerLeads/kitchenRCBLeads.scss';
import FurnitureRCBEveryRoom from "./furnitureRCBSlider";
// import FurnitureRCBHappyCustomerSlider from './components/KitchensComp/FurnitureRCBHappyCustomerSlider';
import FurnitureRCBHappyCustomerSliders from "./FurnitureRCBHappyCustomerSlider";
import FurnitureRCBHeader from "./FurnitureRCBHeader";
import { espotAPI,imagePrefix } from '../../../public/constants/constants';
import apiManager from '../../utils/apiManager';
import { isMobile } from '../../utils/utilityManager';

class FurnitureRCB extends React.Component{

  constructor() {
    super();
    this.state = {
      // unavailableItmCat: null
      RTBBannerData:[],
    };
  }

      fetchRTBBannerData=()=>{
        apiManager
          .get(`${espotAPI  }GI_Homepage_RTBBanner`)
          .then(response => {
            this.setState({
              RTBBannerData: response.data.data,
            });
          })
          .catch(error => { });
      }
    
      componentDidMount() {
        this.fetchRTBBannerData();

      }

      render(){
        return(
          <>
            <div className="home-furniture-page">
              <FurnitureRCBHeader />
              <FurnitureRCBLeads />
              <ContentEspot espotName="GI_Furniture_RCB_GIF" />
              <FurnitureRCBEveryRoom/>
              <ContentEspot espotName="GI_Furniture_RCB_TalkTo" />
              <div className='RTBBannerForGIPromise'>
                {!!this.state.RTBBannerData && this.state.RTBBannerData.map((banner,index)=>
                  <>
                    <img src={imagePrefix + (isMobile()? banner.mobImg: banner.img )} ></img>
                  </>
                )}
              </div>
              {/* <OurPromisesBannerData  mainClassName='godrej-interio-promise' titleClassName=''/> */}
              <ContentEspot espotName="GI_Furniturepage_RCB_Benefits" />
              <FurnitureRCBHappyCustomerSliders/>
              <ContentEspot espotName="GI_Furniture_RCB_Redesign" />
              <ContentEspot espotName="GI_RCB_Footer" />
            </div>
          </>
        );
      }
}
export default FurnitureRCB;
