/*
 * my wish list AND newsletter Constants
 * 
 * 
 * 
 * 
 
 * 
 * 
 */

export const WISHLIST_EMPTY = 'Your Wishlist is empty';
export const ITEMS_WISHLIST = 'Start adding items to your wishlist';
export const PRODUCT_REMOVED = 'Product removed from Wishlist';
export const MAILING_LIST_YET = 'Have you joined our mailing list yet?';
export const RECIEVE_UPDATES = 'Be the first to receive updates on offers, new arrivals and more.';
export const JOINED_MAILING_LIST = ' Have you joined our mailing list yet?';
export const EMAIL_ADD = 'Email Address';
export const SUBMIT = 'SUBMIT';





















 



