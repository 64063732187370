import React from "react";
import "./InfoCards.scss";

export default function InfoCards(props) {
  return (
    <div className="card-body ">
      <div className="card-overflow">
        <a href={props.href}>
          <img alt="" className="card-img" src={props.img}></img>
        </a>
      </div>
      <h2 className="card-title">{props.title} </h2>
    </div>
  );
}
