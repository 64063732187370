import React from "react";
import "./Form.scss";
import FormDataNew from "./FormDataNew";

export default function Form() {
  return (
    <div className="form-container">
      <h1 className="form-header"> Get in Touch </h1>
      <p className="form-info">
        Fill out below forms to know more details on this offer & understand,
        how can we help you in improving collaborating with us.
      </p>
      <FormDataNew />
      <div className="bottom-gray"></div>
    </div>
  );
}
