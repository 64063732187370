/*
 * my account Constants
 * 
 * 
 * 
 * 
 
 * 
 * 
 */

export const PASSWORD = "Password";
export const REQUIRED_FIELD = 'This is a required field';
// export const REQUIRED_FIELD = "This field is required";
export const ENTER_PASSWORD = "Please enter the Password";
export const INVALID_PASSWORD =
  "Invalid Password. Password should not be more than 25 characters";
export const PASSWORD_SHOUBLE_BE =
  "Password should be within 6-20 characters only. Special characters are not permitted";
export const SIX_CHAR_PASS =
  "Invalid Password. Password should have min 6 characters and atleast 1 number";
export const HELLO_GUEST =
  "Hello Guest! Please enter your Order Number to track";
export const ORDER_NO = "Order Number";
export const GO_BACK = "Go back";
export const VALID_NAME =
  "Please enter a valid Name. It should not exceed 64 characters";
export const ADD_NEW_ADD = "ADD NEW ADDRESS";
export const VALID_MOBILE_NO = "Please enter valid mobile number.";
export const ENTER_EMAIL_ID = "Please enter valid Email ID.";
export const VALID_ADD = 'Please enter valid Address(Please check special characters).';
export const VALID_CITY = "Please enter valid City/District.";
export const VALID_PIN = "Please enter valid Pincode.";
export const VALID_STATE = "Please enter valid State.";
export const DEFAULT_ADDRESS = "Make this default address";
export const DELETE = "DELETE";
export const EDIT = "EDIT";
export const SET_DEFAULT = "SET AS DEFAULT";
export const DEF_ADD = "Default Address";
export const SURE_DELETE_YOUR_ADD =
  "Are you sure you want delete this address?";
export const CANCEL = "CANCEL";
export const NAME_VALIDATION =
  "Please enter a valid Name. It should not exceed 64 characters";
export const NUMBER_VALIDATION = "Please enter valid mobile number.";
export const EMAIL_VALIDATION = "Please enter valid Email ID.";
export const SERVICE_REQUEST = "Request Service";
export const ACCOUNT_NUMBER_VALIDATION = "Please enter a valid Account number.";
export const ACCOUNT_NUMBER_CONFIRM_VALIDATION =
  "Please check Account number does not match!";
export const IFSC_VALIDATION = "Please enter a valid IFSC code.";

export const EMPTY_GSTIN = 'Please enter valid GSTIN Number';
export const INVALID_GSTIN = 'Invalid GST number.';
