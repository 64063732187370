/*
 * plp Constants
 * 
 * 
 * 
 * 
 
 * 
 * 
 */

export const CANCEL = ' Cancel';
export const APPLY = ' Apply';
export const CLEAR_ALL = ' Clear All';
export const FILTERS = ' Filters';
export const SORT = ' Sort';
export const ALREADY_ADDED = 'Product already added in Compare tray. Please add another product';
export const PRODUCT_RESTRICTION = 'You can add upto 3 products. Please remove a product to add another';
export const SAME_CAT = 'Please select same category products';
export const DIFF_CAT = 'Selected product is from different category';


 
 







