import React from 'react';
import apiManager from '../../utils/apiManager';
import WidgetList from '../../components/HomePageStatic/widgetList';
import {
  homePageLayoutAPI, homepageLayoutMobileAPI,
} from '../../../public/constants/constants';
import {is, isMobile} from '../../utils/utilityManager';
import appCookie from '../../utils/cookie';
import LoadingIndicator from '../../utils/loadingIndicator';
import Pixels from '../../components/Primitives/pixels';
import GSchemas from '../../components/GSchemas';
import Slider from '../../components/Primitives/slider';
import GI_Icon_Android from '../../../public/images/GI_Icon_Android.svg';
import TopFurnCategories from '../../components/TopFurniturecategories/TopFurnCategories';

export class HomapegeLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homepageLayout: null,
      isLoading: false,
      error: null,
      ipData: null,
      cookiePolicy: appCookie.get('isCookiePolicy'),
      canLoad: false,
    };
  }

  getPageLayout() {
		
    const APILayout = isMobile() ? homepageLayoutMobileAPI : homePageLayoutAPI;
    apiManager
      .get(APILayout)
      .then(response => {
        const { data } = response || {};
        const layout = data && data.data;
        this.setState({
          homepageLayout: is(layout, 'Array') && layout,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.getPageLayout();
  }
	

  /* 	hideCookiePopup() {
			appCookie.set('isCookiePolicy', false, 365 * 24 * 60 * 60 * 1000);
			this.setState({
				cookiePolicy: false
			})
		} */

  render() {
    const { homepageLayout } = this.state;
    return (
      <>
        <div className="block__install" id="BlockInstall">
          <div className="inner">
            <div className="close" id="BlockInstallClose">✖
            </div>
            <div className="logo">
              <img src={GI_Icon_Android} />
            </div>
            <div className="name">
              <span className="title">Install Godrej Interio</span>
              <span className="description">Add Godrej Interio to your Home Screen</span>
            </div>
            <div className="cta">
              <button id="BlockInstallButton" className="btn btn-outline">Install</button>
            </div>
          </div>
        </div>
        <GSchemas schemas={[{ type: 'home' }]} />
        {/* <ContentEspot espotName={'GI_PIXEL_BODY_START'} /> */}
        <div className='home'>
          <Pixels espotName="GI_PIXEL_HOME_META" />
          {homepageLayout ?<div onLoad={()=>{this.setState({canLoad:true})}}> {isMobile()? <><TopFurnCategories /> <Slider /></>:<Slider />}  </div> : <></> }
					
          {
            !!homepageLayout && this.state.canLoad ? (
              homepageLayout.map((widget, i) => (
                <WidgetList
                  {...homepageLayout[i]}
                  key={`${homepageLayout[i].title}_widget_${i}`}
                  index={`${homepageLayout[i].title}_widget_${i}`}
                />
              ))
            ) : (
              <LoadingIndicator />
            )
          }
          {/* { this.state.cookiePolicy === 'true' &&
					<div className="cookiesPolicySticky">
						<div className="cookiesText">We use our own third party cookies to improve your experience and our services, and to
						analyse the use of our website. if you continue browsing, we take that to mean that you
						accept their use. 
						</div>
						<button className='accept_cookies' onClick={this.hideCookiePopup}>Accept</button>
					<a onClick={this.hideCookiePopup}><button className='cancelButton'><img src={CrossIcon} alt='crossImg'/></button></a></div>
				} */}
        </div>
        {/* <ContentEspot espotName = { 'GI_PIXEL_BODY_END' } /> */}
      </>
    );
  }
}

export default HomapegeLayout;
