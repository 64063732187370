import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {imagePrefix} from '../../../public/constants/constants';
import "./SliderNew.scss";


const path = "/B2C/EspotImages/Images/rcb/AvSolutions/sliderimgs/";

export default class SliderNew extends React.Component {
  render() {
    const settings = {
      dots: true,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 2,
    };
    return (
      <div className="slide-container">
        <h2> </h2>
        <Slider {...settings}>
          <div>
            <img alt="Slider Image 1" src={`${imagePrefix + path  }Surfacec Hub1.webp`} className="slider-img" />
          </div>
          <div>
            <img alt="Slider Image 2" src={`${imagePrefix + path  }Microsoft Teams Rooms 2.webp`} className="slider-img" />
          </div>
          <div>
            <img alt="Slider Image 3" src={`${imagePrefix + path  }Surface Hub2.webp`} className="slider-img" />
          </div>
        </Slider>
      </div>
      
    );
  }
}
