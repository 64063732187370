import React, { Component } from 'react';

import ProductItem from '../GlobalComponents/productItem/productItem';
import storage from '../../utils/localStorage';
import {
  imageSearchLocalStorageKey,
  imgSrchRsltLocalStorageKey,
} from '../../../public/constants/constants';

class ImageSearchListing extends Component {
  render() {
    const previewImage = storage.get(imageSearchLocalStorageKey);
    const productsList = storage.get(imgSrchRsltLocalStorageKey);
    if (!previewImage || !productsList) return <h1>No Result Found!!</h1>
    let searchedImagePreview = null;
    if (previewImage) {
      searchedImagePreview = (
        <div className="image-search-preview">
          <img src={previewImage} />
        </div>
      );
    }
    return (
      <section className="plpCategories image-search-listing">
        <div className="container">
          <div className="row">
            <div className="searchresult">
              <h3 className="headingTitleFlat">
                Results for {searchedImagePreview}
              </h3>
            </div>
          </div>
        </div>
        <div className="container2">
          <ul className="plp-products grid3">
            {productsList.map(product => (
              <ProductItem
                key={product.uniqueID}
                index={product.uniqueID}
                dataPro={product}
                // isInWishlist={wishlistArr.includes(product.uniqueID)}
                // addProduct={this.handleAddProduct}
                hideOverlayCompareBtn
                isfromWishlistPro={false}
                history={this.props.history}
                isSearchPathPro=""
                swatchList={[]}
                isShareWishlistPro={false}
                coloumnLayout={3}
                moveToCartPopUpPro={() => {}}
                // plpBreadcrumbPro={this.props.plpBreadcrumbPro}
              />
            ))}
          </ul>
        </div>
      </section>
    );
  }
}

export default ImageSearchListing;
