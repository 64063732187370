import React from "react";



class FormHeader extends React.Component {
// export default function FormHeader() {
  constructor(props) {
    super(props);
    this.state = {
      
    }
    
  }

  render(){
    return (
      <div className="form-header">
        <div className="border-header"></div>
        <div className="border-header-in"></div>
        <div className="border-header-outs"></div>
        <div className="head-text">
          <h1>
            {this.props.text1} <br /> {this.props.text2}
          </h1>
          <h5>
            {this.props.text3} <br /> {this.props.text4} <br /> {this.props.text5}
          </h5>
        </div>
      </div>
    );
  }
}


export default FormHeader;