import React from "react";
// Redux Imports
import { connect } from "react-redux";
import injectSaga from "../../utils/injectSaga";
import appCookie from "../../utils/cookie";
import injectReducer from "../../utils/injectReducer";
import reducer from "../../containers/PlpContainer/reducer";
import saga from "../../containers/PlpContainer/saga";
import "../../../public/styles/headerContainer/navbarNew.scss";
import { compose } from "redux";
import * as actionCreators from "../../containers/PlpContainer/actions";
import {
  createCategoryPlpURL,
  getReleventReduxState,
} from "../../utils/utilityManager";
import { NavForSEO } from "../Category/navigationConstants";
import { Link, withRouter } from "react-router-dom";
import { imagePrefix } from "../../../public/constants/constants";
class SubCategoriesArray extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subCatImg: null,
      menuHidden: false,
    };
    this.compLeft = {};
    this.setRef = this.setRef.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.isOpen != nextProps.isOpen;
  }

  setRef(ref) {
    this.ref = ref;
  }

  onLinkNavigation = () => {
    appCookie.set("compareProductTemp", "", 365 * 24 * 60 * 60 * 1000);
    this.props.plpReduxStateReset();
    this.props.onCloseMenu();
    // this.setState({menuHidden:false});
    // setTimeout(function() {
    //   this.setState({menuHidden:true})
    // }.bind(this), 500)
  };

  onMenuMouseOut(event) {
    //  if(event.relatedTarget.cl)
    if (
      !(
        event.relatedTarget.className == "subCatList" ||
        event.relatedTarget.className == "links" ||
        event.relatedTarget.className == "listItems" ||
        event.relatedTarget.className == "catLongList"
      )
    ) {
      this.props.onCloseMenu();
    }
  }

  render() {
    const { subCatImg } = this.state;
    let catClass =
      this.props.subCategoryArray.length > 6 ? "catLongList" : "catList";
    const catLassproduct =
      this.props.categoryNamePro.toLowerCase() == "products"
        ? " catList-products"
        : "";
    catClass += catLassproduct;
    
    return (
      // onMouseOut={(event)=>{this.onMenuMouseOut(event)}}
      <div
        className={this.props.isOpen ? "catNav" : "catNavToggle"}
        ref={this.props.reference}
      >
        {!!subCatImg && (
          <div className="subCatImage" style={this.compLeft}>
            <img
              src={`${imagePrefix}${subCatImg}`}
              className="subCatImg"
              alt="Sub Cat Img"
            />
          </div>
        )}
        <ul
          className={catClass}
         
        >
          {this.props.subCategoryArray.map((subCategoryData, index) => {
            let routePath;
            const subcatName = String(subCategoryData.categoryName).toLowerCase();

            if (
              this.props.categoryNamePro.toLowerCase().indexOf("rooms") > -1 ||
              subCategoryData.categoryIdentifier === "modular-wardrobes"
            ) {
              if (subCategoryData.categoryIdentifier === 'kitchen') {
                routePath = `/${subCategoryData.categoryIdentifier.toLowerCase()}s`;
              }
              else {
                routePath = `/online-furniture-${subCategoryData.categoryIdentifier.toLowerCase()}`;
              }
            } else if (this.props.categoryNamePro.indexOf("Inspiration") > -1) {
              routePath = "/inspiration";
            } else {
              routePath = createCategoryPlpURL(
                subCategoryData.categoryIdentifier
              );
            }
            return (
              <>
                {this.props.categoryNamePro.toLowerCase() == "products" ? (
                  <>
                    <div className="navColumn">
                      <li className="subCatList l2Item">
                        <b>
                          <Link
                            to={{
                              pathname: routePath,
                              state: { categoryId: subCategoryData.uniqueID },
                            }}
                            className="links"
                            onClick={this.onLinkNavigation}
                          >
                            {/* l2  */}
                            {subCategoryData.categoryName}
                          </Link>
                        </b>
                        <ul className="newnavlist">
                          {subCategoryData &&
                            !!subCategoryData.subCategoryArray &&
                            subCategoryData.subCategoryArray.map(
                              (l3category, index) => {
                               if(NavForSEO[`${l3category.categoryIdentifier}`]==undefined){
                                const routePathSub = createCategoryPlpURL(
                                  l3category.categoryIdentifier
                                );
                             
                                return (
                                  <li className="newnavlistitem">
                                   
                                    <Link
                                      onClick={this.onLinkNavigation}
                                      to={{
                                        pathname: routePathSub,
                                        state: {
                                          categoryId: l3category.uniqueID,
                                        },
                                      }}
                                      className="links links-new"
                                    >
                                      <b> {l3category.categoryName} </b>
                                    </Link>
                                    <ul>
                                      {l3category &&
                                        !!l3category.subCategoryArray &&
                                        l3category.subCategoryArray.map(
                                          (l4category, index) => (
                                            NavForSEO[`${l4category.categoryIdentifier}`]==undefined?
                                            <>
                                              <li className="newnavlistitem">
                                                <Link
                                                  onClick={
                                                    this.onLinkNavigation
                                                  }
                                                  to={{
                                                    pathname: createCategoryPlpURL(
                                                      l4category.categoryIdentifier
                                                    ),
                                                    state: {
                                                      categoryId:
                                                          l4category.uniqueID,
                                                    },
                                                  }}
                                                  className="links links-new"
                                                >
                                                  {l4category.categoryName}
                                                </Link>
                                              </li>
                                            </>:<></>
                                          )
                                        )}
                                    </ul>
                                  </li>
                                );
                               }
                              
                              }
                            )}
                        </ul>
                      </li>
                    </div>
                  </>
                ) : (
                  <>
                    {this.props.categoryNamePro.toLowerCase() == 'offers' ?
                      <><div className="navColumn">
                        <li className="subCatList l2Item" key={`subCat-${index}`}>
                          <Link
                            to={{
                              pathname: routePath,
                              state: { categoryId: subCategoryData.uniqueID },
                            }}
                            className="links"
                            onClick={this.onLinkNavigation}
                          >
                            {/* l2  */}
                            {subCategoryData.categoryName}
                          </Link>
                        </li>
                      </div>
                      {index+1 == this.props.subCategoryArray.length ? <div className="navColumn">
                        {/* <li className="subCatList l2Item" key={`subCat-${index}`}>
                    <a
                      href='http://godrejinterio.rewardzpromo.com/'
                      className="links"
                    >
                       Cashback for Customers*
                    </a>
                  </li> */}
                      </div> : <></>}
                      </>: <><div className="navColumn">
                        <li className="subCatList l2Item" key={`subCat-${index}`}>
                          <Link
                            to={{
                              pathname: routePath,
                              state: { categoryId: subCategoryData.uniqueID },
                            }}
                            className="links"
                            onClick={this.onLinkNavigation}
                          >
                            {/* l2  */}
                            {subCategoryData.categoryName}
                          </Link>
                        </li>
                      </div></>}
                    
                  </>
                )}{" "}
              </>
            );
          })}
        </ul>
      </div>
    );
  }
}

/* ----------------------------------------   REDUX HANDLERS   -------------------------------------  */
const mapDispatchToProps = dispatch => ({
  plpReduxStateReset: () => dispatch(actionCreators.resetPLPReduxState()),
});

const mapStateToProps = state => {
  const stateObj = getReleventReduxState(state, "plpContainer");
  return {};
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: "plpContainer", reducer });
const withSaga = injectSaga({ key: "plpContainer", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withRouter
)(SubCategoriesArray);
