import React from 'react';
import { Helmet } from "react-helmet";
import apiManager from '../../utils/apiManager';
import { espotAPI } from '../../../public/constants/constants';
import '../../../public/styles/content.scss';
import { imagePrefix } from '../../../public/constants/constants';

class Pixels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pixelData: null,
      isLoading: true,
      error: null,
      url:'',
      urlmaking:'',
      seoEspotNAme:'GI_PDP_SEO_DETAILS',
      productName:'',
      breadcrumbData:'',
      breadcrumbData1:'',
      pdpSEOData:'',
      GDPR:0,
      GDPR_PD:0,
      US_PRIVACY:'',
      GDPR_CONSENT_874:'',
    };
  }

  getSEOData() {
    apiManager
      .get(espotAPI + this.state.seoEspotNAme)
      .then(response => {
        const { data } = response || {};
        this.setState({
          pdpSEOData: data && data.data,
        });
        console.log('PDP SEO data found = '+JSON.stringify(data.data));
      })
      .catch(error => {
        console.log('PDP SEO data not found');
      });
  }
  getEspotData() {
    apiManager
      .get(espotAPI + this.props.espotName)
      .then(response => {
        const { data } = response || {};
        this.setState({
          pixelData: data && data.data.SEOTags,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.productName && this.state.productName=='') {
      this.setState({
        productName: nextProps.productName,
      });
      console.log('productName componentWillReceiveProps = '+nextProps.productName);
    }

    if (nextProps.breadcrumbData && this.state.breadcrumbData=='') {
      this.setState({
        breadcrumbData: nextProps.breadcrumbData,
        breadcrumbData1: nextProps.breadcrumbData && nextProps.breadcrumbData[1] ?nextProps.breadcrumbData[1]:nextProps.breadcrumbData && nextProps.breadcrumbData[0] ?nextProps.breadcrumbData[0]:'' ,
      });
      this.addPDPScript(nextProps.breadcrumbData);

      console.log('breadcrumbData componentWillReceiveProps = '+JSON.stringify(nextProps.breadcrumbData));
      console.log('breadcrumbData1 componentWillReceiveProps = '+JSON.stringify(nextProps.breadcrumbData && nextProps.breadcrumbData[1] ?nextProps.breadcrumbData[1]:nextProps.breadcrumbData && nextProps.breadcrumbData[0] ?nextProps.breadcrumbData[0]:''));
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(!this.props.isPdp || this.props.isPdp==false){
    
    if(this.props.espotName!==null && this.props.espotName!=='' && this.props.espotName!==undefined){
    if ((prevState.pixelData =='' || prevState.pixelData ==null || prevState.pixelData ==undefined) && 
    (this.state.pixelData !== '' && this.state.pixelData !==null && this.state.pixelData !==undefined)) {
      console.log("this.state.pixelData if = "+JSON.stringify(this.state.pixelData));

    // Create a new meta tag
    const newMetaDescription = document.createElement('meta');
    newMetaDescription.name = 'description';
    newMetaDescription.content = this.state.pixelData.description;

    // Prepend the new meta tag to the head section
    document.head.prepend(newMetaDescription);

    // Create a new title tag
    const newTitle = document.createElement('title');
    newTitle.innerText = this.state.pixelData.title;

    // Prepend the new title tag to the head section
    document.head.prepend(newTitle);
      // {!this.props.isPdp && <title>{pixelData.title ? pixelData.title : this.props.title}</title>}
    }
    }
    if(prevProps.description!==this.props.description && 
      prevProps.title!==this.props.title &&
      this.props.description!==null && this.props.description!==undefined && this.props.description!=='' && 
      this.props.title!==null && this.props.title!==null && this.props.title!==''
    ){
      console.log("this.props.found if = ");
    if(this.props.description && this.props.description!=='' && 
      this.props.description!==null && this.props.description!==undefined){
    // Create a new meta tag
    const newMetaDescription = document.createElement('meta');
    newMetaDescription.name = 'description';
    newMetaDescription.content = this.props.description;

    // Prepend the new meta tag to the head section
    document.head.prepend(newMetaDescription);
    }
    if(this.props.title && this.props.title!=='' && 
      this.props.title!==null && this.props.title!==undefined){
    // Create a new title tag
    const newTitle = document.createElement('title');
    newTitle.innerText = this.props.title;

    // Prepend the new title tag to the head section
    document.head.prepend(newTitle);
    }
    }
    if(this.props.espotName==null || this.props.espotName=='' || this.props.espotName==undefined){
      if ((prevState.pixelData =='' || prevState.pixelData ==null || prevState.pixelData ==undefined) && 
      (this.state.pixelData !== '' && this.state.pixelData !==null && this.state.pixelData !==undefined)) {
        console.log("rooms data found if = ");
        if(this.props.description && this.props.description!=='' && 
          this.props.description!==null && this.props.description!==undefined){
        // Create a new meta tag
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.name = 'description';
        newMetaDescription.content = this.props.description;
    
        // Prepend the new meta tag to the head section
        document.head.prepend(newMetaDescription);
        }
        if(this.props.title && this.props.title!=='' && 
          this.props.title!==null && this.props.title!==undefined){
        // Create a new title tag
        const newTitle = document.createElement('title');
        newTitle.innerText = this.props.title;
    
        // Prepend the new title tag to the head section
        document.head.prepend(newTitle);
        }
    
      }
    }
  }
  if(this.props.isPdp && this.props.isPdp == true){

    if ((prevState.pdpSEOData =='' || prevState.pdpSEOData ==null || prevState.pdpSEOData ==undefined) && 
    (this.state.pdpSEOData !== '' && this.state.pdpSEOData !==null && this.state.pdpSEOData !==undefined) &&
    (this.state.productName !== '' && this.state.productName !==null && this.state.productName !==undefined)
    
  ) {

    console.log("this.state.productName and pdpSEOData found = ");

    if(this.state.pdpSEOData && this.state.pdpSEOData.descriptionString1 && this.state.pdpSEOData.descriptionString2 &&
      this.state.pdpSEOData.descriptionString3 && this.state.pdpSEOData.descriptionString4 && this.state.productName && this.state.productName != ''){

        // Create a new meta tag
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = this.state.pdpSEOData.descriptionString1 + this.state.productName +
      (this.state.breadcrumbData1 && this.state.breadcrumbData1[1] && this.state.breadcrumbData1[1].label ? this.state.pdpSEOData.descriptionString2 : this.state.pdpSEOData.descriptionString3) +
      (this.state.breadcrumbData1 && this.state.breadcrumbData1[1] && this.state.breadcrumbData1[1].label && this.state.breadcrumbData1[1].label) +
      this.state.pdpSEOData.descriptionString4;

      // Prepend the new meta tag to the head section
      document.head.prepend(newMetaDescription);
        
    }



    if(this.state.pdpSEOData.titleString1 && this.state.pdpSEOData.titleString1!='' && this.state.pdpSEOData.titleString1!=null && 
      this.state.pdpSEOData.titleString2 && this.state.pdpSEOData.titleString2!=='' && this.state.pdpSEOData.titleString2!==null
    ){
    // Create a new title tag
    const newTitle = document.createElement('title');
    newTitle.innerText = this.state.pdpSEOData.titleString1 + this.state.productName + this.state.pdpSEOData.titleString2;

    // Prepend the new title tag to the head section
    document.head.prepend(newTitle);
    }

    }
    

  }

  }
  componentDidMount() {

    if (window.CMP) {
      const consentData = CMP.getConsentData();
      this.setState({
        GDPR: consentData.gdpr,
        GDPR_CONSENT_874: consentData.gdpr_consent,
        GDPR_PD: consentData.gdpr_pd,
        US_PRIVACY: consentData.us_privacy,
      });
    }
    // console.log('productName = '+this.props.productName);
    // console.log('breadcrumbData = '+JSON.stringify(this.props.breadcrumbData));
    this.getSEOData();
    if (this.props.espotName) {
      this.getEspotData();
    }
    else {
      this.setState({
        pixelData: {},
        isLoading: false,
      });
    }
  }

  addPDPScript(breadcrumbData2) {

    // alert('hii');
    // Remove existing script if it exists
    const existingScript = document.getElementById('DLFNPMMP1');
    if (existingScript) {
      existingScript.parentNode.removeChild(existingScript);
    }
    let breadcrumbData3 =breadcrumbData2 && breadcrumbData2[1] ?breadcrumbData2[1]:breadcrumbData2 && breadcrumbData2[0] ?breadcrumbData2[0]:'';

    const categoryLabelsString = breadcrumbData3.map(category => category.label).join('|');

    // Create and add new script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'DLFNPMMP1';
    script.async = true;
    script.innerHTML = `
      window.data_8240 = [];
      window.data_8240.push({
        'Currency': 'INR',
        'CustomerType': '0',
        'ProductId': '${this.props.partNumber}',
        'ProductCategoryList': '${categoryLabelsString}',
        'CouponCode': '',
        'gdpr': '${this.state.GDPR}',
        'gdpr_consent': '${this.state.GDPR_CONSENT_874}',
        'gdpr_pd': '${this.state.GDPR_PD}',
        'us_privacy': '${this.state.US_PRIVACY}',
        'PageType': 'product'
      });
      (function(d) {
        var s = d.createElement('script'); s.async = true; s.id='DLFNPMMP1'; s.type='text/javascript';
        s.src = 'https://cdn.preciso.net/p0n6t4c/t2g?8240';
        var a = d.getElementsByTagName('script')[0]; a.parentNode.insertBefore(s, a);
        if(typeof LB8240 !=='undefined'){LB8240.init(window.data_8240[0]);}
      }(document));
    `;
    document.head.appendChild(script);
  }

  render() {
    const { pixelData,GDPR, GDPR_CONSENT_874, GDPR_PD, US_PRIVACY } = this.state;
    this.state.url = window.location.href;
    this.state.urlmaking = this.state.url.split('?')[0];
    if (pixelData === null || pixelData === undefined) {
      return null;
    }
    const fullImagePath = `${imagePrefix}${this.props.image}`;
    const keywords = Array.isArray(pixelData.keywords) && pixelData.keywords && pixelData.keywords.length !== 0 ? pixelData.keywords : this.props.keywords;
    return (
      <Helmet>
        {window.location.pathname === '/' &&
        <script type="text/javascript">
            {`
              window.data_8240 = [];
              window.data_8240.push({
                'gdpr': '${GDPR}',
                'gdpr_consent': '${GDPR_CONSENT_874}',
                'gdpr_pd': '${GDPR_PD}',
                'us_privacy': '${US_PRIVACY}',
                'PageType': 'home'
              });
              (function(d) {
                var s = d.createElement('script'); s.async = true; s.id='DLFNPMMP1'; s.type='text/javascript';
                s.src = 'https://cdn.preciso.net/p0n6t4c/t2g?8240';
                var a = d.getElementsByTagName('script')[0]; a.parentNode.insertBefore(s, a);
                if(typeof LB8240 !=='undefined'){LB8240.init(window.data_8240[0]);}
              }(document));
            `}
          </script>
          }

          {/* {this.props.pdp && this.props.pdp==true && this.state.productName && this.state.productName != '' && 
          <script type="text/javascript">
            {`
              window.data_8240 = [];
              window.data_8240.push({
              'Currency': 'INR',
              'CustomerType': '0',
              'ProductId': '${this.props.partNumber}',
              'ProductCategoryList': '${this.state.breadcrumbData1 && this.state.breadcrumbData1[1] && this.state.breadcrumbData1[1].label && this.state.breadcrumbData1[1].label}',
              'CouponCode': '',
              'gdpr': '${GDPR}',
              'gdpr_consent': '${GDPR_CONSENT_874}',
              'gdpr_pd': '${GDPR_PD}',
              'us_privacy': '${US_PRIVACY}',
              'PageType': 'product'
              });
              (function(d) {
                  var s = d.createElement('script'); s.async = true;s.id='DLFNPMMP1';s.type='text/javascript';
                      s.src = 'https://cdn.preciso.net/p0n6t4c/t2g?8240';
                  var a = d.getElementsByTagName('script')[0]; a.parentNode.insertBefore(s, a);
                  if(typeof LB8240 !=='undefined'){LB8240.init(window.data_8240[0]);}
                }(document));
            `}
          </script>
          } */}

        {/* {!this.props.isPdp && <title>{pixelData.title ? pixelData.title : this.props.title}</title>} */}
        {/* {!this.props.isPdp && <meta name="description" content={pixelData.description ? pixelData.description : this.props.description} />} */}
        {!this.props.isPdp && <h1 className='heading-desc'>{pixelData.metaDescription}</h1>}
        {/* {this.props.isPdp && this.props.isPdp == true && this.state.pdpSEOData && this.state.pdpSEOData.titleString1 &&
          this.state.pdpSEOData.titleString2 && this.state.productName && this.state.productName != '' &&
          <title>{this.state.pdpSEOData.titleString1 + this.state.productName + this.state.pdpSEOData.titleString2}</title>
        } */}
        {/* {this.props.isPdp && this.props.isPdp == true && this.state.pdpSEOData && this.state.pdpSEOData.descriptionString1 && this.state.pdpSEOData.descriptionString2 &&
          this.state.pdpSEOData.descriptionString3 && this.state.pdpSEOData.descriptionString4 && this.state.productName && this.state.productName != '' &&
          <meta name="description"
            content={this.state.pdpSEOData.descriptionString1 + this.state.productName +
              (this.state.breadcrumbData1 && this.state.breadcrumbData1[1] && this.state.breadcrumbData1[1].label ? this.state.pdpSEOData.descriptionString2 : this.state.pdpSEOData.descriptionString3) +
              (this.state.breadcrumbData1 && this.state.breadcrumbData1[1] && this.state.breadcrumbData1[1].label && this.state.breadcrumbData1[1].label) +
              this.state.pdpSEOData.descriptionString4} />
        } */}
        {this.props.isPdp && this.props.isPdp == true && this.state.productName && this.state.productName != '' &&
          <h1 className='heading-desc'>{this.state.productName}</h1>}
        
        {!!keywords && (<meta
          name="keywords" content={
            keywords.map((keywordsData) => (
              keywordsData
            ))
          }></meta>)}
        <meta httpEquiv="X-UA-Compatible" content="IE=EmulateIE7"/>
        <link rel="canonical" href={this.state.urlmaking} />
        <meta name="twitter:card" content={pixelData.twitter_card ? pixelData.twitter_card : "summary_large_image"} />
        <meta name="twitter:site" content={pixelData.twitter_site ? pixelData.twitter_site : "@godrejinterio"} />
        <meta name="twitter:title" content={pixelData.title ? pixelData.title : this.props.title} />
        <meta name="twitter:description" content={pixelData.description ? pixelData.description : this.props.description} />
        <meta name="twitter:image" content={pixelData.twitter_image ? pixelData.twitter_image : fullImagePath} />
        <meta name="twitter:image:alt" content={pixelData.twitter_image_alt ? pixelData.twitter_image_alt : this.props.alt} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pixelData.title ? pixelData.title : this.props.title} />
        <meta property="og:description" content={pixelData.description ? pixelData.description : this.props.description} />
        <meta property="og:image" content={pixelData.og_image ? pixelData.og_image : fullImagePath} />
      </Helmet>
    );
  }
}

export default Pixels;
