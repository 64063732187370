/*
 * compare Constants
 * 
 * 
 * 
 * 
 
 * 
 * 
 */

export const WIDTH = 'Width';
export const HEIGHT = 'Height';
export const DEPTH = 'Depth';
export const DELIVERY = 'Delivery';
export const DIMENSIONS = 'Dimensions';
export const SPECIFICATIONS = 'Specifications';
export const PAYMENT = 'Payment';
export const REMOVE = 'Remove';
export const VIEW_PRODUCT = 'View Product';


