import React from "react";
import InfoCards from "./InfoCards";
import "./InfoCards.scss";
import {imagePrefix} from '../../../public/constants/constants';
const path = "/B2C/EspotImages/Images/rcb/AvSolutions/sliderimgs/";

export default function InfoCardsPanel() {
  return (
    <div className="panel-body ">
      <InfoCards
        title="Microsoft Surface Hub"
        img={`${imagePrefix+path}Microsoft Surface Hub.webp`}
        href="https://www.microsoft.com/en-in/surface/business/surface-hub-2/"
      />
      <InfoCards
        title="Microsoft Teams Rooms"
        img={`${imagePrefix+path}Microsoft Teams Rooms.webp`}
        href="https://www.microsoft.com/en-us/microsoft-teams/microsoft-teams-rooms"
      />
    </div>
  );
}
