import React from "react";

import Button from 'react-bootstrap/lib/Button'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import Label from 'react-bootstrap/lib/Label'
import Col from 'react-bootstrap/lib/Col'
import FormControl from 'react-bootstrap/lib/FormControl'

import "./formPanel.scss";
import {
  franchiseEnquiryLeadsAPI,PinToCityAPI,
} from '../../../../public/constants/constants';
import apiManager from '../../../utils/apiManager';
import {
  validateEmptyObject,
  validateFullName,
  regexName,
  regexMobileNo,
  regexEmail,
} from "../../../utils/validationManager";
import {
  FIELDREQ_MSG,
  NAME_VALIDATION_MSG,
  INVALID_MOBILE_NUMBER,
  INVALID_EMAIL_ADDRESS,
  INVALID_PINCODE_FORMAT,
  NOT_VALID_PINCODE,
} from "../../../constants/app/primitivesConstants";


// import Select, { components } from "react-select";


const PINCODE_REGEX = /^\d{6}$/;


class FranchiseForm extends React.Component {
  constructor() {
    super();
    this.state = {
      name: null,
      email1: null,
      phone1: null,
      city: null,
      state: null,
      pincode: null,
      qualification: null,
      business: null,
      propArea: null,
      qualificationErrMsg: null,
      businessErrMsg: null,
      propAreaErrMsg: null,
      requirementsOptions: [],
      requirements: [],
      nameErrMsg: null,
      emailErrMsg: null,
      dob: null,
      dobErrMsg: null,
      phoneErrMsg: null,

      pincodeErrMsg: null,
      successMsg: null,
      errorMsg: null,
      selfOwned: false,
      contactMe: "No",
	  submitDisabled:false,
    };
  }

  toggleSelfOwned = (e) => {
    this.setState({ selfOwned: e.target.checked });

  };

  fieldChangeHandler = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value !== "" ? e.target.value : null,
    });
    if (
      e.target.name === 'pincode' 
      &&
      e.target.value 
      &&
      PINCODE_REGEX.test(e.target.value) 
    ) {
      if(e.target.value.length == 6) {
      this.fetchCityFromPin(e.target.value);
      }
    }
  };

  fetchCityFromPin = pincode => {
    if (!pincode) return;
    if(pincode.length!=6){
      return;
    }else{
    apiManager
      .get(PinToCityAPI + pincode)
      .then(res => {
        if (res.data && res.data.data && res.data.data.city) {
          this.setState({
            pincodeErrMsg: null,
            city: res.data.data.city,
            state: res.data.data.state,
          });
        } else {
          this.setState({ pincodeErrMsg: INVALID_PINCODE_FORMAT });
        }
      })
      .catch(error => {
        console.log('franchise enquiry pincode error: ' + error);

        this.setState({ 
          pincodeErrMsg: INVALID_PINCODE_FORMAT,
          city: '',
          state: '',
         });
      });
    }
  };

  keyPressHandler = (e) => {
    if (e.key === "Enter") this.formSubmitHandler();
  };

  formValidationHandler = () => {
    this.setState({
      nameErrMsg: null,
      emailErrMsg: null,
      phoneErrMsg: null,
      //pincodeErrMsg: null,
      successMsg: null,
      dobErrMsg: null,
      qualificationErrMsg: null,
      businessErrMsg: null,

      propAreaErrMsg: null,

      errorMsg: null,
    });
    let isValid = true;
    const {
      name,
      email1,
      phone1,
      pincode,
      city,
      dob,
      qualification,
      business,
      propArea,
    } = this.state;

    if (!validateEmptyObject(name)) {
      this.setState({ nameErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!validateFullName(name) || !regexName.test(name)) {
      this.setState({ nameErrMsg: NAME_VALIDATION_MSG });
      isValid = false;
    }
    if (!validateEmptyObject(qualification)) {
      this.setState({ qualificationErrMsg: FIELDREQ_MSG });
      isValid = false;
    } 
    if (!validateEmptyObject(business)) {
      this.setState({ businessErrMsg: FIELDREQ_MSG });
      isValid = false;
    } 
	

    if (!validateEmptyObject(dob)) {
      this.setState({ dobErrMsg: FIELDREQ_MSG });
      isValid = false;
    }
    

    if (!validateEmptyObject(propArea)) {
      this.setState({ propAreaErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!(propArea >= 500)) {
      this.setState({ propAreaErrMsg: "Please enter valid area" });
      isValid = false;
    }

    if (!validateEmptyObject(email1)) {
      this.setState({ emailErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!regexEmail.test(email1)) {
      this.setState({ emailErrMsg: INVALID_EMAIL_ADDRESS });
      isValid = false;
    }
    if (!validateEmptyObject(phone1)) {
      this.setState({ phoneErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!regexMobileNo.test(phone1)) {
      this.setState({ phoneErrMsg: INVALID_MOBILE_NUMBER });
      isValid = false;
    }
    if (!validateEmptyObject(pincode)) {
      this.setState({ pincodeErrMsg: FIELDREQ_MSG });
      isValid = false;
    } 
    else if (!PINCODE_REGEX.test(pincode)) {
      this.setState({ pincodeErrMsg: INVALID_PINCODE_FORMAT });
      isValid = false;
    } 
    else if (!validateEmptyObject(city)) {
      this.setState({ pincodeErrMsg: INVALID_PINCODE_FORMAT });
      isValid = false;
    }
    if(this.state.pincodeErrMsg == INVALID_PINCODE_FORMAT){
      isValid = false;
    }
    return isValid;
  };

  

  
  formSubmitHandler = (e) => {
    if (!this.formValidationHandler()) return;
    
    this.setState({
      submitDisabled: true,
    });
    setTimeout(() => {
      this.setState({
        submitDisabled:false,
      })
    }, 5000);

    const {
      name,
      phone1,
      email1,
      pincode,
      city,
      state,
      qualification,
      business,
      propArea,
      dob,
      selfOwned,
    } = this.state;
    const urlSearchParams = new URLSearchParams(
      window.location.search.substring(1),
    );
  
    const data = {
      name,
      phone1,
      email1,
      pincode,
      city,
      state,
      qualification,
      business,
      propArea,
      dob,
      selfOwned,
    };
    apiManager
      .post(franchiseEnquiryLeadsAPI, data)
      .then(response => {
        const resData = response.data;
        if (resData.status === 'success') {
          this.setState({
            name: "",
            phone1: "",
            email1: "",
            pincode: "",
            city: "",
            state: "",
            qualification: "",
            business: "",
            propArea: "",
            dob: "",
            selfOwned: "",
            successMsg:
              'Thank You! Your nearest store representative will reach out to you shortly.',
          });
        } else {
          this.submittedFail();
        }
      })
      .catch(() => {
        this.submittedFail();
        
      });
  };
  

  submittedFail = () => {
    window.alert("Could not submit the data.");
    console.log("Could not submit the data");
  };

  toggleHandler = () => {
    this.setState({ successMsg: null });
  };

  // componentDidMount() {
  // }

  render() {
    const {
      name,
      email1,
      phone1,
      city,
      pincode,

      dob,
      qualification,

      // requirements,
      nameErrMsg,
      qualificationErrMsg,
      emailErrMsg,
      phoneErrMsg,
      pincodeErrMsg,

      dobErrMsg,
      business,
      propArea,
      propAreaErrMsg,
      businessErrMsg,
      successMsg,
      errorMsg,
      // requirementsOptions,
      selfOwned,
    } = this.state;
    

    return (
      <>
        {/* <Container >  */}
        <div className="form-data-body">
          <Col className=" input-full">
            <FormGroup>
              <Label className="d-block">Name *</Label>
              <FormControl
                type="text"
                name="name"
                className="form-control"
                placeholder="Enter Full Name"
                value={name}
                onChange={this.fieldChangeHandler}
                onKeyPress={this.fieldChangeHandler}
              />
              <div className="err-div">
                <p className="error-msg">{nameErrMsg}</p>
              </div>
            </FormGroup>
          </Col>
          <Col className="input-block input-left">
            <FormGroup>
              <Label className="d-block">Date of Birth *</Label>
              <FormControl
                type="date"
                name="dob"
                className="form-control"
                placeholder="dd-mm-yyyy"
                value={dob}
                onChange={this.fieldChangeHandler}
                onKeyPress={this.fieldChangeHandler}
              />
              <div className="err-div">
                <p className="error-msg">{dobErrMsg}</p>
              </div>
            </FormGroup>
          </Col>
          <Col className="input-block input-right">
            <FormGroup>
              <Label className="d-block">Qualification *</Label>
              <FormControl
                type="text"
                name="qualification"
                className="form-control"
                placeholder="Enter Qualification"
                value={qualification}
                onChange={this.fieldChangeHandler}
                onKeyPress={this.fieldChangeHandler}
              />
              <div className="err-div">
                <p className="error-msg">{qualificationErrMsg}</p>{" "}
              </div>
            </FormGroup>
          </Col>
          <Col className="input-block input-left">
            <FormGroup>
              <Label className="d-block">Business *</Label>
              <FormControl
                type="text"
                name="business"
                className="form-control"
                placeholder="Enter name of Business"
                value={business}
                onChange={this.fieldChangeHandler}
                onKeyPress={this.fieldChangeHandler}
              />
              <div className="err-div">
                <p className="error-msg">{businessErrMsg}</p>
              </div>
              <div className="socheck">
                <Col xs={12} class="">
                  <FormGroup>
                    <label className="so-checks check">
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={selfOwned}
                        onChange={this.toggleSelfOwned}
                      />
                      Self-owned?
                    </label>
                  </FormGroup>
                </Col>
              </div>
            </FormGroup>
          </Col>
          <Col className="input-block input-right">
            <FormGroup>
              <Label className="d-block">Property Area *</Label>
              <FormControl
                type="number"
                name="propArea"
                className="form-control"
                placeholder="Enter sq. ft. Area"
                value={propArea}
                onChange={this.fieldChangeHandler}
                onKeyPress={this.fieldChangeHandler}
              />

              <p className="propArea">
                Minimum space required <b>500-1000 sqft</b>
              </p>
              <div className="err-div">
                <p className="error-msg">{propAreaErrMsg}</p>
              </div>
            </FormGroup>
          </Col>
          <Col className="input-full margin-new">
            <FormGroup>
              <Label className="d-block">Email *</Label>
              <FormControl
                type="text"
                name="email1"
                className="form-control"
                placeholder="Enter Email"
                value={email1}
                onChange={this.fieldChangeHandler}
                onKeyPress={this.fieldChangeHandler}
              />
              <div className="err-div">
                <p className="error-msg">{emailErrMsg}</p>
              </div>
            </FormGroup>
          </Col>
          <Col className="input-full ">
            <FormGroup>
              <Label className="d-block">Mobile *</Label>
              <FormControl
                type="text"
                name="phone1"
                className="form-control"
                placeholder="Enter Mobile Number"
                value={phone1}
                onChange={this.fieldChangeHandler}
                onKeyPress={this.fieldChangeHandler}
              />
              <div className="err-div">
                <p className="error-msg">{phoneErrMsg}</p>
              </div>
            </FormGroup>
          </Col>

          <Col className="input-block input-left">
            <FormGroup>
              <Label className="d-block">Pincode *</Label>
              <FormControl
                type="text"
                name="pincode"
                className="form-control"
                placeholder="Enter Pincode"
                value={pincode}
                onChange={this.fieldChangeHandler}
                onKeyPress={this.fieldChangeHandler}
              />
              <div className="err-div">
                <p className="error-msg">{pincodeErrMsg}</p>{" "}
              </div>
            </FormGroup>
          </Col>
          <Col className="input-block input-right">
            <FormGroup>
              <Label className="d-block">City *</Label>
              <FormControl
                type="text"
                disabled
                name="city"
                className="form-control"
                placeholder="City"
                value={city}
              />
             
              <div className="err-div">
                <p className="error-msg" />
              </div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <h6 className="message">
                {errorMsg && <span className="error-msg">{errorMsg}</span>}
              </h6>
              <Button
                className="submit-btn btn-block btn-bg form-button"
                onClick={this.formSubmitHandler}
                disabled={this.state.submitDisabled}
              >
                Submit
              </Button>
            </FormGroup>
          </Col>

          {successMsg ? (window.location.pathname = `/thank-you`) : null}
          <h4 className="tandc">
            By submitting this form, you agree to the{" "}
            <a
              href="https://www.godrejinterio.com/privacy-policy"
              target="_blank"
            >
              privacy policy
            </a>{" "}
            &{" "}
            <a
              href="https://www.godrejinterio.com/termsconditions"
              target="_blank"
            >
              T&C
            </a>
          </h4>
        </div>
        {successMsg ? window.location.pathname=`/thank-you`:null}
      </>
    );
  }
}

export default FranchiseForm;
