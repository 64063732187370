import React from "react";
import Header from "../GodrejAVSolutionsComp/Header";
import IntroRR from "../RapidRoomsComponent/IntroRR";
import OffersRR from "../RapidRoomsComponent/OffersRR";
import ProductDetailsPanel from "../RapidRoomsComponent/ProductDetailsPanel";
import RoomCardsPannel from "../RapidRoomsComponent/RoomCardsPannel";
import Footer from "../GodrejAVSolutionsComp/Footer";
import FormNew from "../RapidRoomsComponent/FormNew";


export default function RapidRooms() {
  return (
    <div className="Rapid-Rooms-page">
     
      <Header />
      <IntroRR />
      <OffersRR />
      <ProductDetailsPanel />
      <RoomCardsPannel /> 
      <FormNew />
      <Footer />
      
    </div>
  );
}
