import React from "react";
import "./Form.scss";
import FormData from "./FormData";

export default function Form() {
  return (
    <div className="form-body">
      <h1 className="form-header"> Let's Talk </h1>
      <p className="form-info">
        To know more about Microsoft Teams Rooms and Surface Hub and to arrange
        free demo, please fill below form for us to get in touch with you.{" "}
      </p>
      <FormData />
      <div className="bottom-gray"></div>
    </div>
  );
}
