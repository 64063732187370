/**
 *
 * FurnitureRCBHeader
 *
 */
import React from 'react';
import ContentEspot from '../Primitives/staticContent';
import '../../../public/styles/customerLeads/kitchenRCBLeads.scss';


class FurnitureRCBHeader extends React.Component{

  constructor() {
    super();
    this.state = {
      // unavailableItmCat: null
    };
  }

  render(){
    return(
      <>
        <ContentEspot espotName="GI_FURNITURE_RCB_HEADER" />
      </>
    );
  }
}
export default FurnitureRCBHeader;