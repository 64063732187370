
import "./error.scss";
import React from 'react'
import {espotAPI,imagePrefix} from '../../../public/constants/constants';
import apiManager from '../../utils/apiManager';
import { Helmet } from "react-helmet";

class notfound extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      espotName: 'GI_ERROR_404_IMG',
      imagePath: '',
      errorCode: '',
      errorHead: '',
      text1: '',
      text2: '',
      text3:'',
      text4:'',
      backButtonText:'',
      backButtonHref:'',
      homeButtonText:'',
      homeButtonHref:'',
      error: null,
    };
  }

  geterrorEsoptData() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        const {data} = response || {}
        this.setState({
          imagePath: data && data.data.imagePath,
          errorCode:data && data.data.errorCode,
          errorHead:data && data.data.errorHead,
          text1:data && data.data.text1,
          text2:data && data.data.text2,
          text3: data && data.data.text3,
          text4: data && data.data.text4,
          backButtonText: data && data.data.backButtonText,
          backButtonHref:data && data.data.backButtonHref,
          homeButtonText:data && data.data.homeButtonText,
          homeButtonHref:data && data.data.homeButtonHref,
        });
      })
      .catch(error => {
        this.setState({
          error,
        });
      });
      
  }

  componentDidMount() {

    this.geterrorEsoptData();
  }


  render() {
    const { imagePath, errorCode, errorHead, text1, text2, 
      text3,text4, backButtonText, backButtonHref, homeButtonText, homeButtonHref } = this.state;
    return (
      <>
      <Helmet>
      <meta name="prerender-status-code" content="404" />
       <meta name="robots" content="noindex"></meta>
    </Helmet>
      <div className="ErrorContainer">
        <div className="container">
          <div className="image-container">
            <img src={imagePrefix+imagePath}></img>
          </div>
          <div className="text-container">
            <h2 className="error-code">
              <b>{errorCode}</b>
            </h2>
            <h1 className="error-head">
              <i>{errorHead}</i>
            </h1>
            <p className="oops">
              <b>
                {text1} 
              </b>
            </p>
            <p className="oops">
              <b>
                {text2}
              </b>
            </p>
            <div className="text">
              <p>
                {text3}<br></br>{text4}
              </p>
            </div>
            <div className="buttons-container">
              <button
                onClick={() => {
                  history.back();
                }}
              >
                {backButtonText}
              </button>
              <button
                onClick={() => {
                  window.location.href = homeButtonHref;
                }}
              >
                {homeButtonText}
              </button>
            </div>
          </div>
        </div>
      </div></>
    );
  }
}
  

export default notfound;
