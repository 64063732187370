import React from "react";
import "./Header.scss";
import {imagePrefix} from '../../../public/constants/constants';
const logo = "/B2C/EspotImages/Images/rcb/AvSolutions/avsolnLogo.png";
const msLogo = "/B2C/EspotImages/Images/rcb/AvSolutions/mslogo.svg";

export default function Header() {
  return (
    <div className="headBody">
      <div className="left">
        <a href="https://www.godrejinterio.com/business/audio-visual-solutions">
          <img src={imagePrefix + logo} alt="GI Logo" ></img></a>
      </div>
      <div className="right">
        <a href="https://www.microsoft.com/en-in">
          <img src={imagePrefix + msLogo} alt="MS Logo"></img></a>
      </div>
    </div>
    
  );
}
