import React from 'react';
import { Link } from 'react-router-dom';
import apiManager from '../../../utils/apiManager';
import { getCookie, isMobile } from '../../../utils/utilityManager';
import '../../../../public/styles/headerContainer/headerL1.scss';
import {headerStaticData } from '../../../../public/constants/constants';
import DealerModal  from '../../StaticPages/DealerModal';

class HeaderL1 extends React.Component {
  state = {
    layer1Data: [],
    isLoading: true,
    errors: null,
    getDefaultPincode: '',
    show:true,
  };

  getHeaderLayer1() {
    apiManager
      .get(headerStaticData)
      .then(response => {
        const {data} = response || {};
        this.setState({
          layer1Data: data && data.data,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  dealermodal(){
    this.setState({
      show:true,
    })
  }

  
  handleClose(){
    console.log("inside handleClose");

    this.setState({ show: false });
  }

  componentDidMount() {
    this.getHeaderLayer1();
    this.setState({
      getDefaultPincode: getCookie('pincode'),
      show: false,
    });
  }

  componentWillReceiveProps() {
    this.setState({
      getDefaultPincode: getCookie('pincode'),
    });
  }

  render() {
    // console.log("headerData:"+JSON.stringify(layer1Data))
    const { isLoading, layer1Data, getDefaultPincode } = this.state;
    return (
      <div className='header1NavLinks'>
        <ul className="nav nav-tabs d-none d-lg-flex" role="tablist">
          <li className="nav-item">
            {layer1Data.personal && 
              <Link className="nav-link personal" to={layer1Data.personal.action} title={layer1Data.personal.title}>
                {layer1Data.personal.title}</Link>
            }
          </li>
          <li className="nav-item">
            {layer1Data.business && 
              <Link className="nav-link" to={layer1Data.business.action} title={layer1Data.business.title}>
                {layer1Data.business.title}</Link>
            }
          </li>
        </ul>
        <div className="header-link">

        {layer1Data.services ?
            layer1Data.services.action.includes('www')? <a className='link timed-link' target="_blank" href={ layer1Data.services.action}>{layer1Data.services.title}</a>:<Link className='link' to={ layer1Data.services.action}>
              {layer1Data.services.title}
            </Link> : <></>
          }

          {layer1Data.blogs ?
            layer1Data.blogs.action.includes('www')? <a className='link timed-link' target="_blank" href={ layer1Data.blogs.action}>{layer1Data.blogs.title}</a>:<Link className='link' to={ layer1Data.blogs.action}>
              {layer1Data.blogs.title}
            </Link> : <></>
          }

          {layer1Data.additionalheader ?
            layer1Data.additionalheader.action.includes('www')? <a className='link timed-link' target="_blank" href={ layer1Data.additionalheader.action}>{layer1Data.additionalheader.title}</a>:<Link className='link' to={ layer1Data.additionalheader.action}>
              {layer1Data.additionalheader.title}
            </Link> : <></>
          }

          {layer1Data.dealer && layer1Data.dealer.title && layer1Data.dealer.action && layer1Data.dealer.action != '' ?
            <a className='action'>
              <button className="action-dealer" >
                <a href={layer1Data.dealer.action}>{layer1Data.dealer.title}</a></button>
            </a>
            :
            layer1Data.dealer && layer1Data.dealer.title ?
              <a className='action'>
                <button className="action-dealer" onClick={this.dealermodal.bind(this)}>
                  <a>{layer1Data.dealer.title}</a></button>
              </a>
              : ''
          }
          {
            this.state.show == true  && !isMobile() ? <DealerModal show={this.state.show} handleModelClose = {this.handleClose.bind(this)}  /> :<></>
          }

         {layer1Data.locate_stores && 
            layer1Data.locate_stores.action.includes("https")?
            <a className='action' target="_blank" href={ layer1Data.locate_stores.action}>{layer1Data.locate_stores.title}</a>
            : ''
          }

          {getCookie('isLoggedIn') === 'true' ? (
            layer1Data.track_order && 
              <Link className="action" to={{ pathname: layer1Data.track_order.my_account_action, state:{from:layer1Data.track_order.track_order_myorder}}}>
                {layer1Data.track_order.title}</Link>   
          ):(
            layer1Data.track_order && 
              <Link className="action" to={layer1Data.track_order.action}>
                {layer1Data.track_order.title}</Link>
          )}

          {layer1Data.support && (
            <Link className="action" to={layer1Data.support.action}>
              {layer1Data.support.title}</Link>
          )}
        </div>
      </div>
    );
  }
}

export default HeaderL1;
