import React from 'react';
const ExchangeLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 39" {...props}>
    <title>Exchange</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-4-8h55v55H-4z"/>
      <g stroke="#000">
        <path d="M41.792 24.905a18.688 18.688 0 0 1-3.111 5.938c-6.358 8.163-18.13 9.625-26.292 3.267a18.684 18.684 0 0 1-7.172-13.392M5.913 14.073A18.67 18.67 0 0 1 9.12 7.817c6.358-8.163 18.13-9.626 26.292-3.269a18.695 18.695 0 0 1 7.195 13.746"/>
        <path d="M1.038 26.135l4.168-5.431 5.303 4.254M46.87 12.988l-4.269 5.352-5.22-4.354M17.377 28.692h-2.47a.57.57 0 0 1-.571-.57V17.286a.57.57 0 0 1 .57-.57h2.47a.57.57 0 0 1 .571.57v10.836a.57.57 0 0 1-.57.57zM33.156 28.692h-2.47a.57.57 0 0 1-.571-.57V17.286a.57.57 0 0 1 .57-.57h2.47a.57.57 0 0 1 .571.57v10.836a.57.57 0 0 1-.57.57z"/>
        <path d="M30.114 21.848v-10.74a1.15 1.15 0 0 0-1.158-1.141h-9.849c-.64 0-1.16.511-1.16 1.141v10.74M17.947 25.463h12.167v-3.614H17.947zM17.947 28.669h12.167v-3.206H17.947zM16.5 28.692h15.021"/>
      </g>
    </g>
  </svg>

)

export default ExchangeLogo;