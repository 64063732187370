import React from "react";
import "./RapidRooms.scss";

export default function OffersRR() {
  return (
    <div className="offers-container">
      <h1 className="offers-header">Microsoft Teams RapidRooms</h1>
      <p className="offers-info-head">
        <b>For a limited time</b>, Microsoft and Godrej AV Solutions are
        offering qualifying customers installation and equipment for two
        Microsoft Teams Rooms at <b>NO COST!</b>
      </p>
      <p className="offers-info">
        Customers are turning to Microsoft Teams and Microsoft Teams Rooms for
        their meeting and collaboration solution across the new hybrid
        workplace. To help our customers get going quickly, Microsoft and Godrej
        AV Solutions are offering qualifying customers, on-site installation
        services for up to two Teams Rooms, at no Charge*.
      </p>
    </div>
  );
}
