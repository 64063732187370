import { secureHttp, host1, port3 } from '../../../public/constants/constants';
/* Registration With Mobile Num Constant */
export const registerWithMobileNum = 'registerWithMobileNum';
/* Registration With Email Constant */
export const registerWithEmail = 'registerWithEmail';
/* Generate OTP Constant */
export const generateOtp = 'generateOtp';
/* Resend OTP Constant */
export const resendOtp = 'resendOtp';
/* OTP Confirmed Constant */
export const otpConfirmed = 'otpConfirmed';
/* joinus logo url */
export const LogoUrl = `${secureHttp}://${host1}:${port3}/wcsstore/GodrejInterioSAS/images/godrejInterio/logo-white.svg`;
/* Registration With Email Header Text */
export const registerWithEmailText = 'Register With Us';
/* Registration With Mobile Number Header Text */
export const registerWithMobileNumText = 'Register With Us';