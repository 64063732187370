import React from 'react';
const DeleteLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" {...props}>
    <title>Delete</title>
    <g fill="none" fillRule="evenodd" opacity=".499">
      <path d="M-4-3h24v24H-4z"/>
      <path fill="#000" fillRule="nonzero" d="M12 2.5h4v1.2h-1.5v13.8h-13V3.7H0V2.5h4v-2h8v2zm-1 0v-1H5v1h6zm2.5 1.2h-11v12.8h11V3.7zM10.842 5h1.2v10h-1.2V5zM7.42 5h1.2v10h-1.2V5zM4 5h1.2v10H4V5z"/>
    </g>
  </svg>
    
)

export default DeleteLogo;
