/*
 * checkoutConstants
 * 
 * 
 * 
 * 
 
 * 
 * 
 */

export const USE_GSTIN = 'Use GSTIN for Business Purchase (Optional)';
export const PAYMENT_NOT_COMPLETED = 'Your payment could not be completed';
export const OOPS = 'Oops!';
export const CANCEL = 'CANCEL';
export const TRY_AGAIN = 'TRY AGAIN';
export const CHECKOUT = 'Checkout';
export const CART_TOTAL = 'Cart Total';
export const ORDER_SUMMARY = 'Order Summary';
export const PRODUCT_DISCOUNT = 'Product Discount';
export const ORDER_DISCOUNT = 'Order Discount';
export const EXCHANGE_DISCOUNT ='Exchange Value';
export const ADDITIONAL_DISCOUNT = 'Additional Discount';
export const SHIPPING = 'Shipping';
export const STARTING_FROM = 'Starting from';
export const KNOW_MORE = 'KNOW MORE';
export const TOTAL = 'Total';
export const TOTAL_AMOUNT = 'Total Amount';
export const SECURE_CHECKOUT = ' Secure Checkout';
export const YOU_SAVED = 'You saved';
export const PAY = ' PAY';
export const PLEASE_WAIT = 'Please Wait, You are being redirected...';
export const ARE_YOU_SURE = 'Are you sure you want to change your pincode?';
export const CHANGING_YOUR_PIN_CODE = 'Changing your pincode will take you back to the cart and show updated information on product availability, delivery charges and expected delivery date.';
export const PROCEED = 'PROCEED';
export const SUBMIT = 'SUBMIT';
export const DOES_NOT_EXIST = 'This account does not exist. Enter a valid mobile number or email address to proceed or';
export const CREATE = 'Create';
export const GI_ACCOUNT = 'a new GI account';
export const MOBILE_EMAIL = 'Mobile Number/ Email Address';
export const DELIVERY_AND_PAYMENT_ADD = 'Your delivery and payment details will be linked to the Credentials you provide below.';
export const I_HAVE_PASSWORD = 'I have a password';
export const PROCEED_TO_CHECKOUT = 'PROCEED TO CHECKOUT';
export const PROCEED_TO_PAY = 'PROCEED TO PAY';
export const CHOOSE_THIS_PLAN = 'CHOOSE THIS PLAN';
export const DELIVER_TO = 'Deliver To';
export const ADD_A_DELIVERY_ADD = 'Add your delivery address';
export const PAYMENT_OPTION = 'Payment Options';
export const CHOOSE_A_PAYMENT_METHOD = 'Choose a payment method';
export const ONE_OR_MORE_ITEM = 'One or more item in you cart is Out Of Stock.';
export const SAVE_YOUR_ADDRESS = 'Please save you address first';
export const REQUIRED_FIELD = 'This is a required field';
export const ENTER_VALID_STATE = 'Please enter valid State.';
export const VALID_CITY_DISTRICT = 'Please enter valid City/District.';
export const VALID_ADDRESS = 'Please enter valid Address(Please check special characters).';
export const VALID_PINCODE = 'Please enter valid Pincode.';
export const VALID_EMAIL = 'Please enter valid Email ID.';
export const PAY_BY = 'Pay by';
export const CHANGE = 'CHANGE';
export const SHIP_TO = 'Ship to';
export const CREDIT_CARD = 'Credit Card';
export const DEBIT_CARD = 'Debit Card';
export const EMI = 'EMI';
export const WALLETS = 'Wallets';
export const NET_BANKING = 'Net Banking';
export const WALLETS_MAPPING = {
  Mobikwik: 'MOBIKWIK',
  Paytm: 'PAYTM',
  PhonePe: 'PHONEPE',
  'Oxigen Wallet': 'OXIGEN_WALLET',
  'Pay World Money': 'PAY_WORLD_MONEY',
  'ICC Cash Card': 'ICC_CASH_CARD',
  EbixCash: 'EBIXCASH',
  'Money On Mobile': 'MONEY_ON_MOBILE',
  'OLA Money': 'OLA_MONEY',
  'The Mobile Wallet': 'THE_MOBILE_WALLET',
  'Yes Bank Wallet': 'YES_BANK_WALLET',
  'DCB Cippy': 'DCB_CIPPY',
  'Amazon Pay': 'AMAZON_PAY',
};
export const ADDRESS_TYPE = {
  shipping: 'S',
  billing: 'B',
  shippingAndBilling: 'SB',
  S: 'Shipping',
  B: 'Billing',
  SB: 'Shipping & Billing',
};
