/*
 *
 * HeaderContainer actions
 *
 */

import { COUPON_DATA, DEFAULT_ACTION, IS_OTP_ENABLED, MINICART_DATA } from '../../constants/headerContainer/constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updatetMinicartData(data) {
  return {
    type: MINICART_DATA,
    data
  }
}

export function updatetIsOTPData(data) {
  return {
    type: IS_OTP_ENABLED,
    data
  }
}

export function updateCouponData(data) {
  return {
    type: COUPON_DATA,
    data
  }
}