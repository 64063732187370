import React, { Suspense } from "react";

import Content from "../Primitives/content";
import { getCookie } from "../../utils/utilityManager";
const Slider = React.lazy(() => import("../Primitives/slider"));
const BestSeller = React.lazy(() => import("../BestSelling/bestSelling"));
const NewArrival = React.lazy(() => import("../NewArrival/newArrival"));
const ReadMore = React.lazy(() => import("../GlobalComponents/readMore"));
const Recommendation = React.lazy(() => import("../Recommendation/reco"));
const TrackOrder = React.lazy(() => import("../TrackOrder/trackOrder"));
const ExStore = React.lazy(() => import("../ExStore/exStore"));
const GodrejSolution = React.lazy(() => import("../Primitives/godrej-solution"));
const TopFurnCategories = React.lazy(() => import("../TopFurniturecategories/TopFurnCategories"));
const Collections = React.lazy(() => import("../CollectionsHomepage/Collections"));
const CollectionsHeader = React.lazy(() => import("../CollectionsHomepage/CollectionsHeader"));
const DeliveryLocations = React.lazy(()=> import('../HomepageNew/DeliveryLocations'));
const BlogsBanner = React.lazy(()=> import('../HomepageNew/BlogsBanner'));
const SmallBanner = React.lazy(()=> import('../HomepageNew/SmallBanner'));
const FullBanner = React.lazy(()=> import('../HomepageNew/FullBanner'));
const BestDeals = React.lazy(()=>import('../HomepageNew/BestDeals'))
const PremiumProducts = React.lazy(()=> import('../HomepageNew/PremiumProducts'));
const TopBestSellers = React.lazy(()=> import('../HomepageNew/TopBestSellers'));

export default function WidgetList({ componentType, ...rest }) {
  switch (componentType) {
    case "hero_banner":
      return<><Suspense fallback={<></>}>
        <Slider {...rest} /></Suspense>
      </> ;
    case "delivery_locations":
      return <><Suspense fallback={<></>}><DeliveryLocations/>
      </Suspense></>
    case "top_fun_categories":
      return <><Suspense fallback={<></>}>
        <TopFurnCategories {...rest} />
      </Suspense></>;
    case "topBestSellers":
      return <>
        <Suspense fallback={<></>}>
          <TopBestSellers {...rest}/>
        </Suspense>
      </>;
    case "deals_content":
      return <><Suspense fallback={<></>}>
        <BestDeals {...rest}/>
      </Suspense></>;
    case "mini_track_order":
      if (getCookie("isLoggedIn") === "true") {
        return (
          <div>
            <Suspense fallback={<></>}>
              <TrackOrder {...rest} />
            </Suspense>
          </div>
        );
      } return null;
    case 'premium_products':
      return <><Suspense fallback={<></>}>
        <PremiumProducts {...rest} />
      </Suspense></>;

    case 'recently_viewed':
      return <> <Suspense fallback={<></>}>
        <BestSeller panelName="recentlyViewed" {...rest} />
      </Suspense></>
    case "best_selling":
      return (
        <>
          <Suspense fallback={<></>}>
            <BestSeller panelName="bestSeller" {...rest} />
          </Suspense>
        </>
      );
    case "new_arrival":
      return <><Suspense fallback={<></>}>
        <NewArrival {...rest} />
      </Suspense></> ;
    case "product_category":
      return  <><Suspense fallback={<></>}>
        <NewArrival componentType = "product_category" {...rest} />
      </Suspense></>;
    case "content":
      return <><Suspense fallback={<></>}>
        <Content {...rest} />
      </Suspense></> ;
    case "experience_store":
      return (
        <>
          <Suspense fallback={<></>}> <ExStore {...rest} /></Suspense>
         
        </>
      );
    case "recommendation":
      return <><Suspense fallback={<></>}>
        <Recommendation {...rest} />
      </Suspense></> ;
    case "section_header":
      return <><Suspense fallback={<></>}>
        <CollectionsHeader {...rest} />
      </Suspense></> ;
    case "collection":
      return <><Suspense fallback={<></>}>
        <Collections {...rest} />
      
      </Suspense></>;
    case "read_more":
      return <><Suspense fallback={<></>}>
         
        <ReadMore />
      </Suspense></> ;
    case "godrej_solution":
      return <><Suspense fallback={<></>}>
        <GodrejSolution {...rest} />
      </Suspense></> ;
    case "BlogHomepage":
      return <><Suspense fallback={<></>}> <BlogsBanner {...rest} /></Suspense></>
    case "smallBanner":
      return <><Suspense fallback={<></>}><SmallBanner {...rest}/></Suspense> </>
    case "Fullbanner":
      return <><Suspense fallback={<></>}> <FullBanner {...rest}/></Suspense> </>
    default:
      return <></>;
  }
}
