import { initializeApp, getApp, getApps } from 'firebase/app';
import { getMessaging, getToken, onBackgroundMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB0aHNgnXqMzT_zSIL_77gih2trQRcZMOo",
  authDomain: "godrejinterio-prod.firebaseapp.com",
  projectId: "godrejinterio-prod",
  storageBucket: "godrejinterio-prod.appspot.com",
  messagingSenderId: "574172475594",
  appId: "1:574172475594:web:2a4a19e23eedfa53e9c433",
  measurementId: "G-K3JQBK7WYP"
};

// eslint-disable-next-line no-undef
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Retrieve firebase messaging
// eslint-disable-next-line no-undef
const messaging= getMessaging(app);
// eslint-disable-next-line no-undef
// onBackgroundMessage(function (payload) {
//   console.log("Received background message ", payload);
//   // dataHandler();
//   // const notificationTitle = payload.notification.title;
//   // const notificationOptions = {
//   //   body: payload.notification.body,
//   //   // icon: "./logo192.png",
//   // };

//   // // eslint-disable-next-line no-restricted-globals
//   // return self.registration.showNotification(
//   //   notificationTitle,
//   //   notificationOptions
//   // );
// });
